import React, { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper/modules'
import TopTenStudents from '../../models/topTenStudents.model'
import { useNavigate } from 'react-router-dom'
import Path from '../../routes/Path'
import ErrorMessage from '../Items/ErrorMessage'

import 'swiper/css'
import 'swiper/css/autoplay'

interface TopStudentListProps {
  topTenStudents: TopTenStudents[]
}

const TopStudentList: React.FC<TopStudentListProps> = ({ topTenStudents }) => {
  const navigate = useNavigate()
  const [activeIndex, setActiveIndex] = useState(0)

  const handleSlideChange = (swiper: any) => {
    setActiveIndex(swiper.realIndex)
  }

  return (
    <div className="w-full grid grid-cols-1 justify-center mt-8">
      <div className="col-span-1 bg-primary-6 py-8 content-center">
        <p className="text-h2 text-white text-center">
          Our top students this month
        </p>

        <div className="my-12">
          {topTenStudents.length !== 0 ? (
            <Swiper
              className="top-list-swiper"
              spaceBetween={2}
              slidesPerView={2}
              centeredSlides={true}
              modules={[Autoplay]}
              autoplay={{ delay: 2000, disableOnInteraction: false }}
              loop={false}
              speed={1000}
              onSlideChange={handleSlideChange}
              breakpoints={{
                768: {
                  slidesPerView: 3,
                },
                1024: {
                  slidesPerView: 4,
                },
                1440: {
                  slidesPerView: 5,
                },
                1560: {
                  slidesPerView: 7,
                },
              }}
              style={{ height: '240px' }}
            >
              {topTenStudents.map((student, index) => (
                <SwiperSlide
                  key={index}
                  className="flex justify-center items-center"
                >
                  {index === activeIndex ? (
                    <div className="bg-white rounded-xl shadow-lg p-4 text-center">
                      <img
                        src={student.image}
                        alt={student.name}
                        className="w-[90px] h-[90px] mx-auto rounded-full"
                      />
                      <p className="text-bodyr text-neutrals-1 text-bold mt-2">
                        {student.name}
                      </p>
                      <div className="grid grid-cols-2 text-footnote text-neutrals-2">
                        <p className="col-span-1">
                          Reading: {student.scoreReadingSection}
                        </p>
                        <p className="col-span-1">
                          Listening: {student.scoreListeningSection}
                        </p>
                        <p className="col-span-1">
                          Writing: {student.scoreWritingSection}
                        </p>
                        <p className="col-span-1">
                          Speaking: {student.scoreSpeakingSection}
                        </p>
                      </div>
                      <p className="text-bodyb text-neutrals-1 text-bold mt-2">
                        Overall: {student.overallTotal}
                      </p>
                    </div>
                  ) : (
                    <img
                      src={student.image}
                      alt={student.name}
                      className="w-[90px] h-[90px] mx-auto rounded-full"
                    />
                  )}
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            <div className="h-[4rem] flex justify-center items-center">
              <ErrorMessage message="There aren't any top students this month" />
            </div>
          )}
        </div>
        <div className="w-full text-center">
          <button
            onClick={() => navigate(Path.weeklyPrediction.path)}
            className="bg-info text-bodyr text-white rounded-xl px-4 py-2 hover:scale-105"
          >
            Start Learning Now
          </button>
        </div>
      </div>
    </div>
  )
}

export default TopStudentList
