import React, { useState, useEffect } from 'react'

// filepath: /c:/pte-master/pte-master-web/src/pages/WeeklyPrediction/RealExamResultModal.tsx
interface RealExamResultModalProps {
  isOpen: boolean
  onClose: () => void
  image: string
}

const RealExamResultModal: React.FC<RealExamResultModalProps> = ({
  isOpen,
  onClose,
  image,
}) => {
  const [isValidImage, setIsValidImage] = useState(true)

  useEffect(() => {
    setIsValidImage(true) // Reset the state when the modal opens
  }, [image])

  const handleImageError = () => {
    setIsValidImage(false)
  }

  if (!isOpen) return null

  const handleBackgroundClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose()
    }
  }

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
      onClick={handleBackgroundClick}
    >
      <div className="bg-white p-4 rounded-lg max-w-lg w-full relative">
        <button
          className="absolute top-1 right-2 text-gray-600 hover:text-gray-800 text-3xl font-bold p-2 focus:outline-none"
          onClick={onClose}
        >
          &times;
        </button>
        <div className="flex flex-col justify-center items-center h-full mt-8">
          {isValidImage ? (
            <img
              src={image}
              alt="Admin Upload"
              className="max-w-full max-h-full object-contain"
              onError={handleImageError}
            />
          ) : (
            <p className="text-red-500">Failed to load image</p>
          )}
        </div>
      </div>
    </div>
  )
}

export default RealExamResultModal
