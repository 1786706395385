import React from 'react'

interface RadioGroupProps {
  question: string
  options: { label: string; value: string }[]
  name: string
}

const RadioGroup: React.FC<RadioGroupProps> = ({ question, options, name }) => {
  return (
    <div className="flex flex-col">
      <label className="mb-[20px] text-bodyr text-neutrals-2">{question}</label>
      <div className="flex gap-8">
        {options.map((option, index) => (
          <label key={index} className="flex items-center">
            <input
              type="radio"
              name={name}
              value={option.value}
              className="mr-2"
            />
            {option.label}
          </label>
        ))}
      </div>
    </div>
  )
}

export default RadioGroup
