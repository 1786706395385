import React from 'react'
import { ReactComponent as ShopTickIcon } from '../../images/ShopPage/shop-tick-icon.svg'
import { ReactComponent as ShopArrowRightIcon } from '../../images/ShopPage/shop-right-arrow-icon.svg'
import { useNavigate } from 'react-router-dom'
import Path from '../../routes/Path'

interface PlanBundleSectionProps {
  tokenPackage: any
}

const PlanBundleSection: React.FC<PlanBundleSectionProps> = ({
  tokenPackage,
}) => {
  const navigate = useNavigate()
  const handlePlanDetailRedirect = (planId: string) => {
    navigate(Path.planPages.detail.path.replace(':planId', String(planId)))
  }
  return (
    <div className="practice-mocktest-bundle-section lg:w-full md:w-1/2 w-full flex flex-wrap lg:gap-y-[2rem] gap-y-[2rem] lg:gap-x-[1.6rem]">
      {tokenPackage.packages.map((pack: any, index: number) => {
        const isSecond = index === tokenPackage.packages.length - 3
        return (
          <div
            key={pack.tkPkgId}
            className={`lg:w-[20.375rem] w-full border border-2 ${isSecond ? 'bg-primary-6 border-[#0000000F]' : 'border-[#0000000F]'} rounded-3xl p-6`}
          >
            <div className="title flex justify-between items-center">
              <p className="text-bodyr font-landingPrimary text-white px-[7px] rounded-[7px] bg-info relative bottom-[6px]">
                {isSecond ? 'Popular' : 'Basic'}
              </p>
            </div>
            <div className="title flex justify-between items-center mt-[1rem]">
              <p
                className={`text-h3r ${isSecond ? 'text-white' : ''} font-landingPrimary`}
              >
                {pack.tkPkgName}
              </p>
            </div>
            <div className="price mt-[1rem]">
              <p
                className={`text-h1 ${isSecond ? 'text-white' : ''} font-landingPrimary flex items-center gap-x-4`}
              >
                AUD {pack.tkPkgPrice}
              </p>
            </div>
            <hr
              className={`my-[0.4rem] border border-1 ${isSecond ? 'border-[#FFFFFF4D]' : 'border-[#0000000F]'}`}
            />
            <div
              className={`feature-details ${isSecond ? 'text-white' : ''} font-landingPrimary my-[1rem]`}
            >
              <div className="flex items-center gap-x-2 w-full my-[0.4rem]">
                <ShopTickIcon fill={isSecond ? 'white' : 'black'} />
                <p className="text-bodyr">
                  {pack.tkPkgActiveTime} hours of Unlimited Scoring
                </p>
              </div>
              <div className="flex items-center gap-x-2 w-full my-[0.4rem]">
                <ShopTickIcon fill={isSecond ? 'white' : 'black'} />
                <p className="text-bodyr">
                  {pack.tkPkgActiveTime} hours of Unlimited Scoring
                </p>
              </div>
              <div className="flex items-center gap-x-2 w-full my-[0.4rem]">
                <ShopTickIcon fill={isSecond ? 'white' : 'black'} />
                <p className="text-bodyr">
                  {pack.tkPkgActiveTime} hours of Unlimited Scoring
                </p>
              </div>
              <div className="flex gap-x-2 w-full my-[0.4rem]">
                <div
                  className={`text-bodyb ${isSecond ? 'text-white' : ''}`}
                  dangerouslySetInnerHTML={{ __html: pack.description }}
                />
              </div>
            </div>
            <hr
              className={`my-[0.4rem] border border-1 ${isSecond ? 'border-[#FFFFFF4D]' : 'border-[#0000000F]'}`}
            />
            <div className="purchase w-full mt-[1rem] flex flex-col gap-y-2">
              <button
                className={
                  'w-full px-[15px] py-[6.4px] border hover:border-transparent text-h5r rounded-lg flex justify-center items-center gap-x-4 ' +
                  (isSecond
                    ? 'text-white bg-info border-info hover:bg-[#1890FF]'
                    : 'border-[#000000D9] hover:bg-info hover:text-white')
                }
                onClick={() => handlePlanDetailRedirect(pack.tkPkgId)}
              >
                <ShopArrowRightIcon
                  fill={isSecond ? 'white' : 'currentColor'}
                />
                Buy Now
              </button>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default PlanBundleSection
