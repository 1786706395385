import axiosInstance from '../configs/axiosConfig'

export function getTestHistoryMock(userId: number) {
  const data = {
    student_id: userId,
  }

  return axiosInstance.post(`/test-history/mock-test`, data)
}

export function getTestHistorySectionl(userId: number, type: number) {
  const data = {
    user_id: userId,
    type: type,
  }

  return axiosInstance.post(`/test-history/sectional-test`, data)
}

export function getReportHistoryDetails(
  userId: number,
  testId: number,
  buyId: number,
) {
  const data = {
    student_id: userId,
    test_id: testId,
    buy_id: buyId,
  }

  return axiosInstance.post(`/test-history/report-history-detail`, data)
}

export function progressTrackerSectionalSpeaking(
  userId: number,
  buyId: number,
) {
  const data = {
    userId: userId,
    buyId: buyId,
  }

  return axiosInstance.post(
    `/sectional/progress-single-tracker-sectional`,
    data,
  )
}

export function progressTrackerSectionalWriting(userId: number, buyId: number) {
  const data = {
    userId: userId,
    buyId: buyId,
  }

  return axiosInstance.post(
    `/sectional/progress-single-tracker-writing-sectional`,
    data,
  )
}

export function progressTrackerSectionalReading(userId: number, buyId: number) {
  const data = {
    userId: userId,
    buyId: buyId,
  }

  return axiosInstance.post(
    `/sectional/progress-single-tracker-reading-sectional`,
    data,
  )
}

export function progressTrackerSectionalListening(
  userId: number,
  buyId: number,
) {
  const data = {
    userId: userId,
    buyId: buyId,
  }

  return axiosInstance.post(
    `/sectional/progress-single-tracker-listening-sectional`,
    data,
  )
}

// History detail Mock Test
export function historyDetailData(
  userId: number,
  buyId: number,
  testId: number,
  tab: string,
  latestResultData: string,
) {
  const data = {
    user_id: userId,
    buy_id: buyId,
    test_id: testId,
    tab: tab,
    latest_result_date: latestResultData,
  }

  return axiosInstance.post(`/test-history/history-details`, data)
}
