import Storage from '../../models/storage.model'
import { ReactComponent as DownArrowIcon } from '../../images/LastMinuteStudy/lmt-down-arrow-icon.svg'
import { ReactComponent as FilePDFIcon } from '../../images/LastMinuteStudy/lmt-file-pdf-icon.svg'
import { ReactComponent as ViewIcon } from '../../images/LastMinuteStudy/lmt-view-icon.svg'

const LastMinuteStudyCard: React.FC<{ storage: Storage }> = ({ storage }) => {
  const handleOpenViewPDF = (pdfUrl: string) => {
    window.open(pdfUrl, '_blank')
  }

  return (
    <div className="last-minute-study-section__list_card rounded-lg bg-[#FAFAFA] font-landingPrimary lg:w-[17.438rem] w-full lg:min-h-[15.688rem] flex flex-col justify-between px-[16px] py-[12px] gap-2 shadow-lmt-card">
      <div className="last-minute-study-section__list_card__pdf-icon">
        <FilePDFIcon />
      </div>
      <div className="last-minute-study-section__list_card__info flex flex-col gap-2">
        <p className="text-h4m line-clamp-2">{storage.storageName}</p>
        <p className="text-bodyr text-neutrals-2 line-clamp-2">
          {storage.storageShortDesc}
        </p>
      </div>
      <div className="last-minute-study-section__list_card__action flex flex-col gap-2">
        <a
          href={storage.documentDownUrl}
          className="h-[2rem] rounded-[7px] w-full text-bodyr text-white bg-info hover:bg-[#1890FF] flex justify-center items-center gap-x-2"
        >
          <DownArrowIcon fill="white" />
          Download Test
        </a>
        <button
          onClick={() => handleOpenViewPDF(storage.documentShowUrl)}
          className="h-[2rem] rounded-[7px] w-full text-bodyr text-neutrals-2 hover:bg-gray-200 flex justify-center items-center gap-x-2"
        >
          <ViewIcon />
          View Details
        </button>
      </div>
    </div>
  )
}

export default LastMinuteStudyCard
