import { ReactComponent as DurationIcon } from '../../images/Purchase/purchase-duration-icon.svg'
import { ReactComponent as QuestionIcon } from '../../images/Purchase/purchase-question-icon.svg'
import { ReactComponent as PriceIcon } from '../../images/Purchase/purchase-price-icon.svg'
import { ReactComponent as UnlockIcon } from '../../images/Purchase/purchase-unlock-icon.svg'
import PurchaseBanner from '../../images/Purchase/purchase-banner.png'
import { calculateMonthDifference } from '../../utils/utils'
import { activeToken } from '../../services/purchase.services'
import ErrorMessage from '../Items/ErrorMessage'
import PurchasedBundle from '../../models/purchasedBundle.model'
import UserTokenPack from '../../models/userTokenPack.model'
import UserTokenPackMock from '../../models/userTokenPackMock.model'
import { toastError, toastSuccess } from '../../utils/utils'

interface YourPurchaseSectionProps {
  bundle: PurchasedBundle[]
  userTokenPacks: UserTokenPack[]
  userTokenPackMocks: UserTokenPackMock[]
}

const YourPurchaseSection: React.FC<YourPurchaseSectionProps> = ({
  bundle,
  userTokenPacks,
  userTokenPackMocks,
}) => {
  const handleActiveToken = async (
    userId: number,
    time: number,
    id: number,
  ) => {
    try {
      const response = await activeToken(userId, time, id)
      if (response.data.success) {
        toastSuccess(response.data.message)
      } else {
        toastError(response.data.message)
      }
    } catch (error) {
      console.error(error)
      toastError('Something went wrong!')
    }
  }

  const displayActiveButton = (token: UserTokenPack) => {
    if (token.activestatus === 0) {
      return (
        <button
          onClick={() =>
            handleActiveToken(token.userid, token.timecount / 3600, token.id)
          }
          className="w-full h-[2.5rem] bg-info hover:bg-[#1890FF] text-bodyr text-white rounded-lg flex items-center justify-center gap-3"
        >
          Click to activate
        </button>
      )
    } else {
      return (
        <button
          className="w-full h-[2.5rem] bg-success cursor-not-allowed text-bodyr text-white rounded-lg flex items-center justify-center gap-3"
          disabled
        >
          Activated
        </button>
      )
    }
  }

  //Filter all credit tokens that: activestatus = 1 and tokentype = 0
  const filteredCreditTokens: UserTokenPack[] = userTokenPacks.filter(
    (token) => token.tokentype === 0,
  )
  //Filter all credit tokens that: activestatus = 1 and tokentype = 1
  const filteredTimeTokens: UserTokenPack[] = userTokenPacks.filter(
    (token) => token.tokentype === 1,
  )

  return (
    <div className="purchase-section__your-purchase font-landingPrimary w-full">
      <div className="your-purchase__bundle w-full mb-[2rem]">
        <div className="your-purchase__bundle__title text-h2 mb-[1rem]">
          Bundle
        </div>
        {bundle.length === 0 ? (
          <ErrorMessage message="No items available..." />
        ) : (
          <div className="your-purchase__bundle__items flex flex-wrap gap-6">
            {bundle.map((item: PurchasedBundle) => (
              <div
                key={item.id}
                className="your-purchase__bundle-item lg:w-[18.75rem] w-full lg:min-h-[23.5rem] h-full pb-[1rem] rounded-lg shadow-purchase-card"
              >
                <div className="your-purchase__bundle-item__image w-full h-[14.375rem] bg-[#D9D9D9] rounded-t-lg relative">
                  <img
                    src={PurchaseBanner}
                    alt="Purchase banner"
                    className="object-fill w-full h-full rounded-t-lg"
                  />
                </div>
                <div className="your-purchase__bundle-item__title mt-[1rem] mb-[0.6rem] px-2">
                  <p className="text-h4m">{item.title}</p>
                </div>
                <div className="your-purchase__bundle-item__info flex flex-col gap-2">
                  <div className="your-purchase__bundle-item__info__price flex gap-2 px-2 items-center">
                    <PriceIcon />
                    <p className="text-bodyr text-neutrals-2">${item.price}</p>
                  </div>
                  <div className="your-purchase__bundle-item__info__duration flex gap-2 px-2 items-center">
                    <DurationIcon />
                    <p className="text-bodyr text-neutrals-2">
                      {item.purchasedate}
                    </p>
                  </div>
                  <div className="your-purchase__bundle-item__info__name flex gap-2 px-2 items-center">
                    <QuestionIcon />
                    <p className="text-bodyr text-neutrals-2">Test Bundle</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="your-purchase__mockCredit w-full mb-[2rem]">
        <div className="your-purchase__mockCredit__title text-h2 mb-[1rem]">
          Mock Test
        </div>
        {userTokenPackMocks.length === 0 ? (
          <ErrorMessage message="No items available..." />
        ) : (
          <div className="your-purchase__mockCredit__items flex flex-wrap gap-6">
            {userTokenPackMocks.map((mock: UserTokenPackMock) => (
              <div
                key={mock.id}
                className="your-purchase__mock-test-item lg:w-[18.75rem] w-full lg:min-h-[23.5rem] h-full rounded-lg shadow-purchase-card"
              >
                <div className="your-purchase__mock-test-item__image w-full h-[14.375rem] bg-[#D9D9D9] rounded-t-lg relative">
                  <img
                    src={PurchaseBanner}
                    alt="Purchase banner"
                    className="object-fill w-full h-full rounded-t-lg"
                  />
                </div>
                <div className="your-purchase__mock-test-item__title mt-[1rem] mb-[0.6rem] px-2">
                  <p className="text-h4m">{mock.tokenpackname}</p>
                </div>
                <div className="your-purchase__mock-test-item__info flex flex-col gap-2">
                  <div className="your-purchase__mock-test__info__duration flex gap-2 px-2 items-center">
                    <DurationIcon />
                    <p className="text-bodyr text-neutrals-2">{mock.buyDate}</p>
                  </div>
                  <div className="your-purchase__mock-test__info__name flex gap-2 px-2 items-center">
                    <QuestionIcon />
                    <p className="text-bodyr text-neutrals-2">Mock bundle</p>
                  </div>
                </div>
                <div className="your-purchase__mock-test__info__unlock w-full px-2 my-[1rem]">
                  <button className="w-full h-[2.5rem] bg-info hover:bg-[#1890FF] text-bodyr text-white rounded-lg flex items-center justify-center gap-3">
                    <UnlockIcon />
                    Unlock Mock Test
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="your-purchase__token w-full">
        <div className="your-purchase__token__title text-h2 mb-[1rem]">
          Token
        </div>
        {userTokenPacks.length === 0 ? (
          <ErrorMessage message="No items available..." />
        ) : (
          <div className="your-purchase__token__items flex flex-wrap gap-6">
            {filteredCreditTokens.map((item) => (
              <div
                key={item.id}
                className="your-purchase__credit-token__item lg:w-[18.75rem] w-full lg:min-h-[23.5rem] h-full pb-[1rem] rounded-lg shadow-purchase-card"
              >
                <div className="credit-token__item__image w-full h-[14.375rem] bg-[#D9D9D9] rounded-t-lg relative">
                  <img
                    src={PurchaseBanner}
                    alt="Purchase banner"
                    className="object-fill w-full h-full rounded-t-lg"
                  />
                </div>
                <div className="credit-token__item__title mt-[1rem] mb-[0.6rem] px-2">
                  <p className="text-h4m">{item.tokenpackname}</p>
                </div>
                <div className="credit-token__item__info flex flex-col gap-2">
                  <div className="credit-token__item__info__token flex gap-2 px-2 items-center">
                    <PriceIcon />
                    <p className="text-bodyr text-neutrals-2">
                      {item.tokencount} Tokens
                    </p>
                  </div>
                  <div className="credit-token__item__info__duration flex gap-2 px-2 items-center">
                    <DurationIcon />
                    <p className="text-bodyr text-neutrals-2">{item.buyDate}</p>
                  </div>
                  <div className="credit-token__item__info__name flex gap-2 px-2 items-center">
                    <QuestionIcon />
                    <p className="text-bodyr text-neutrals-2">Credit token</p>
                  </div>
                </div>
              </div>
            ))}
            {filteredTimeTokens.map((item) => (
              <div
                key={item.id}
                className="your-purchase__time-token__item lg:w-[18.75rem] w-full lg:min-h-[23.5rem] h-full rounded-lg shadow-purchase-card"
              >
                <div className="time-token__item__image w-full h-[14.375rem] bg-[#D9D9D9] rounded-t-lg relative">
                  <img
                    src={PurchaseBanner}
                    alt="Purchase banner"
                    className="object-fill w-full h-full rounded-t-lg"
                  />
                </div>
                <div className="time-token__item__title mt-[1rem] mb-[0.6rem] px-2">
                  <p className="text-h4m">{item.tokenpackname}</p>
                </div>
                <div className="time-token__item__info flex flex-col gap-2">
                  <div className="time-token__item__info__duration flex gap-2 px-2 items-center">
                    <DurationIcon />
                    <p className="text-bodyr text-neutrals-2">
                      {item.starttime &&
                        item.endtime &&
                        calculateMonthDifference(
                          item.starttime,
                          item.endtime,
                        )}{' '}
                      Month(s)
                    </p>
                  </div>
                  <div className="time-token__item__info__name flex gap-2 px-2 items-center">
                    <QuestionIcon />
                    <p className="text-bodyr text-neutrals-2">Time token</p>
                  </div>
                </div>
                <div className="time-token__item__info__activate w-full px-2 my-[1rem]">
                  {displayActiveButton(item)}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default YourPurchaseSection
