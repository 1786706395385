import { useState, useEffect, useRef } from 'react'
import { ReactComponent as ArrowRightIcon } from '../../../images/SectionalTest/st-arrow-right-icon.svg'
import AudioSectional from '../../../components/SectionalTest/AudioSectional'
import { useNavigate } from 'react-router-dom'
import { ExtendedQuestion } from '../../../models/question.model'
import ErrorMessage from '../../../components/Items/ErrorMessage'
import LoadingMessage from '../../../components/Items/LoadingMessage'
import { useAuth } from '../../../providers/AuthProvider'
import {
  addResultListeningMock,
  doTest1Update,
} from '../../../services/mockTest.services'
import { handleExceptionError, navigateToMockTest } from '../../../utils/utils'

const HighlightIncorrectWordsMock = () => {
  const navigate = useNavigate()

  const [timer, setTimer] = useState({ minutes: 40, seconds: 0 })
  const [question, setQuestion] = useState<ExtendedQuestion | undefined>(
    undefined,
  )
  const [questionNumber, setQuestionNumber] = useState(0)
  const [totalQuestion, setTotalQuestion] = useState(0)
  const [errorMsg, setErrorMsg] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [highlightedWords, setHighlightedWords] = useState<number[]>([])
  const [wordsArray, setWordsArray] = useState<string[]>([])
  const [buyId, setBuyId] = useState<number | undefined>(undefined)
  const { userProfile } = useAuth()

  // Ref to store the elapsed time in seconds
  const elapsedTimeRef = useRef(0)
  const audioRef = useRef<{ resetAudio: () => void }>(null)
  const questionSectionRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setTimeout(() => {
      const resultData = localStorage.getItem('resultMockPTE')
      const buyIdStorage = localStorage.getItem('buyIdPTEMock')
      if (buyIdStorage) {
        setBuyId(Number(buyIdStorage))
      }

      if (resultData) {
        const parsedResultData = JSON.parse(resultData)
        setQuestion(parsedResultData.mockqestion[0])
        setQuestionNumber(parsedResultData.row)
        setTotalQuestion(Number(parsedResultData.allcount))

        const words = parsedResultData.mockqestion[0].description
          ? parsedResultData.mockqestion[0].description.trim().split(' ')
          : [] // Split the paragraph into words
        setWordsArray(words)
      } else {
        setErrorMsg('Error while getting the question data!!!')
      }
      setIsLoading(false)
    }, 1000)
  }, [])

  const formattedTime = `${timer.minutes.toString().padStart(2, '0')}:${timer.seconds.toString().padStart(2, '0')}`

  // Function to calculate the total elapsed time in seconds
  const calculateElapsedTime = () => {
    return elapsedTimeRef.current
  }

  // Function to start the timer countdown
  useEffect(() => {
    let interval: NodeJS.Timeout | undefined

    if (timer.minutes === 0 && timer.seconds === 0) {
      // Timer has reached 00:00, stop the timer
      clearInterval(interval)
    } else {
      interval = setInterval(() => {
        elapsedTimeRef.current += 1

        if (timer.seconds === 0) {
          setTimer({ minutes: timer.minutes - 1, seconds: 59 })
        } else {
          setTimer({ ...timer, seconds: timer.seconds - 1 })
        }
      }, 1000)
    }

    // Cleanup function to clear the interval when component unmounts or timer stops
    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [timer])

  const handleWordClick = (index: number) => {
    setHighlightedWords((prevWords) =>
      prevWords.includes(index)
        ? prevWords.filter((w) => w !== index)
        : [...prevWords, index],
    )
  }

  const renderParagraph = (text: string) => {
    return text.split(' ').map((word, index) => {
      const isHighlighted = highlightedWords.includes(index)
      return (
        <>
          <span
            key={index}
            onClick={() => handleWordClick(index)}
            style={{
              backgroundColor: isHighlighted ? '#FFA39E' : 'transparent',
              color: isHighlighted ? 'black' : '',
              cursor: 'pointer',
            }}
          >
            {word}
          </span>
          <span> </span>
        </>
      )
    })
  }

  const resetState = () => {
    setIsSubmitted(false)
    setHighlightedWords([])
    elapsedTimeRef.current = 0

    if (audioRef.current) {
      audioRef.current.resetAudio()
    }

    setTimer({ minutes: 40, seconds: 0 })

    if (questionSectionRef.current) {
      questionSectionRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      })
    }
  }

  const handleSubmitAnswer = async () => {
    setIsSubmitted(true)
    const elapsedTime = calculateElapsedTime()

    if (
      question &&
      highlightedWords.length !== 0 &&
      userProfile &&
      userProfile.userId &&
      buyId
    ) {
      const highlightedWordsArray = highlightedWords.map(
        (index) => wordsArray[--index],
      ) // Get the words based on their index

      const userAnswer = highlightedWordsArray.join('<#>')
      const userAnswerStr = wordsArray
        .map((word, index) =>
          highlightedWords.includes(++index)
            ? `<span class='text-red-600 bg-red-200'>${word}</span>`
            : word,
        ) // Enclose highlighted words in []
        .join(' ')
      try {
        const addResultResponse = await addResultListeningMock(
          question.description,
          userProfile.userId,
          question.testId,
          question.id,
          question.categoryId,
          userAnswer,
          userAnswerStr,
          0,
        )

        if (addResultResponse.data.success) {
          const getQuestionResponse = await doTest1Update(
            buyId,
            question.testId,
            userProfile.userId,
            questionNumber,
            4,
            String(elapsedTime),
            String(elapsedTime),
            totalQuestion,
            'Skip',
          )

          if (getQuestionResponse.data.success) {
            localStorage.setItem(
              'resultMockPTE',
              JSON.stringify(getQuestionResponse.data.result),
            )

            if (getQuestionResponse.data.category_id === '23') {
              setQuestion(getQuestionResponse.data.result.mockqestion[0])
              setQuestionNumber(getQuestionResponse.data.result.row)
              setTotalQuestion(Number(getQuestionResponse.data.result.allcount))
              const words = getQuestionResponse.data.result.mockqestion[0]
                .description
                ? getQuestionResponse.data.result.mockqestion[0].description
                    .trim()
                    .split(' ')
                : [] // Split the paragraph into words
              setWordsArray(words)
              resetState()
            } else {
              navigateToMockTest(navigate, getQuestionResponse.data.category_id)
            }
          } else {
            console.error(getQuestionResponse.data.message)
          }
        } else {
          console.error(addResultResponse.data.message)
        }
      } catch (error) {
        handleExceptionError(error)
      }
    } else {
      console.error(
        'Userprofile or question or highligted words or buyId not found!',
      )
    }
  }

  const handleSkip = async () => {
    setIsSubmitted(true)
    const elapsedTime = calculateElapsedTime()

    if (question && userProfile && userProfile.userId && buyId) {
      try {
        const getQuestionResponse = await doTest1Update(
          buyId,
          question.testId,
          userProfile.userId,
          questionNumber,
          4,
          String(elapsedTime),
          String(elapsedTime),
          totalQuestion,
          'Skip',
        )

        if (getQuestionResponse.data.success) {
          localStorage.setItem(
            'resultMockPTE',
            JSON.stringify(getQuestionResponse.data.result),
          )

          if (getQuestionResponse.data.category_id === '23') {
            setQuestion(getQuestionResponse.data.result.mockqestion[0])
            setQuestionNumber(getQuestionResponse.data.result.row)
            setTotalQuestion(Number(getQuestionResponse.data.result.allcount))
            const words = getQuestionResponse.data.result.mockqestion[0]
              .description
              ? getQuestionResponse.data.result.mockqestion[0].description
                  .trim()
                  .split(' ')
              : [] // Split the paragraph into words
            setWordsArray(words)
            resetState()
          } else {
            navigateToMockTest(navigate, getQuestionResponse.data.category_id)
          }
        } else {
          console.error(getQuestionResponse.data.message)
        }
      } catch (error) {
        handleExceptionError(error)
      }
    } else {
      console.error('Userprofile or question or buyId not found!')
    }
  }

  return (
    <div className="min-h-[80vh]">
      {isLoading ? (
        <LoadingMessage message="Loading question..." />
      ) : errorMsg ? (
        <ErrorMessage message={errorMsg} />
      ) : (
        <div
          className="st-hiw w-full pb-4 shadow-question-card mx-auto font-landingPrimary"
          ref={questionSectionRef}
        >
          <div className="st-hiw__header flex justify-between gap-2 p-4 mb-4 bg-info rounded-lg text-white text-h5r">
            <p className="st-hiw__header__title">
              Listening Section - Highlight Incorrect Words (#
              {question?.id})
            </p>
            <p className="st-hiw__header__number">
              ({questionNumber - 44} Out of 18)
            </p>
          </div>
          <div className="st-sst__timer w-[6.25rem] h-[3.5rem] bg-primary-2 mb-6 rounded-lg flex justify-center items-center mx-auto">
            <p className="text-h2 text-white">{formattedTime}</p>
          </div>
          <div className="st-hiw__instruction text-h4m px-4 mb-4">
            {question?.shortTitle}
          </div>
          <AudioSectional
            ref={audioRef}
            audioUrl={question && question.audio ? question.audio : ''}
          />
          <div className="st-hiw__paragrapgh mx-auto mb-6 px-4 w-full">
            <p className="text-h5r text-neutrals-2">
              {question && question.description
                ? renderParagraph(question.description.trim())
                : ''}
            </p>
          </div>
          <div className="w-full flex justify-center gap-4">
            <button
              data-testid="save-and-next"
              className={`flex text-bodyr text-white items-center justify-centers gap-2 py-2 px-6 rounded-lg 
                ${highlightedWords.length === 0 || isSubmitted ? 'bg-gray-400 cursor-not-allowed' : 'bg-success'}`}
              onClick={handleSubmitAnswer}
              disabled={highlightedWords.length === 0 || isSubmitted}
            >
              Save and Next
              <ArrowRightIcon />
            </button>
            <button
              data-testid="skip"
              className={`flex items-center py-2 px-6 rounded-lg 
                ${isSubmitted ? 'bg-gray-400 cursor-not-allowed' : 'bg-info cursor-pointer'}`}
              disabled={isSubmitted}
              onClick={handleSkip}
            >
              <p className="text-bodyr text-white">Skip</p>
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default HighlightIncorrectWordsMock
