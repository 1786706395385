import React from 'react'
import SkillCTASection from '../../components/LandingPage/AcademicSkill/SkillCTASection'
import AutomateScoringHeader from '../../components/LandingPage/AutomateScoring/AutomateScoringHeader'
import AutomateScoringTestFormat from '../../components/LandingPage/AutomateScoring/AutomateScoringTestFormat'
import Path from '../../routes/Path'
import { Link } from 'react-router-dom'

const AutomateScoringPage: React.FC = () => {
  const skillTestTabs = [
    {
      id: 'tools-to-prepare',
      label: 'PTE Academic Self-Study Tools',
      content: (
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
          {[
            {
              title: 'Online PTE Practice Tutorials',
              description:
                'We find the aspirants are always looking for the best study and practice material for the PTE academic preparation, PTE Master can help you improve Reading, Writing, Listening, and Speaking Modules with our PTE Online Training Tutorials.',
            },
            {
              title: 'Real Test Environment',
              description:
                'PTE Master provides you PTE with sophisticated practice platform where you can experience a real test environment & all systems are equipped with automated machine score test and AI evaluation.',
            },
            {
              title: 'Free PTE Practice Mock Test',
              description:
                'Experience the real pattern of computer-based Pearson English language test by using our AI scored practice & mock testing system for instant and accurate results.',
            },
            {
              title: 'Pearson AI Scoring Tool',
              description:
                'Our system is one of the best PTE tools that will help you provide accurate marking that aligns with Pearsons scoring standards.',
            },
          ].map((item, index) => (
            <div key={index} className="bg-[#F5F5F5] p-4 rounded-lg">
              <p className="text-h4m">{item.title}</p>
              <ul className="list-none space-y-2 mt-2">
                <li className="flex items-start">
                  <p className="text-bodyr text-[#595959]">
                    {item.description}
                  </p>
                </li>
              </ul>
            </div>
          ))}
        </div>
      ),
    },
    {
      id: 'test-format',
      label: 'Test Format',
      content: (
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
          {[
            {
              title: 'PTE Academic Speaking (30 - 35 minutes)',
              description: [
                '1. Personal introduction',
                '2. Read aloud',
                '3. Repeat sentence',
                '4. Describe image',
                '5. Re-tell lecture',
                '6. Answer short question',
                '7. Summarize written text Essay',
              ],
              link: Path.landing.academicSpeaking.path,
            },
            {
              title: 'PTE Academic Reading (32 - 40 minutes)',
              description: [
                'Reading & writing:',
                '1. Fill in the blanks',
                '2. Multiple-choice',
                '3. Choose multiple answers',
                '4. Re-order paragraphs',
                'Reading:',
                '1. Fill in the blanks',
                '2. Multiple-choice',
                '3. Choose single answer',
              ],
              link: Path.landing.academicReading.path,
            },
            {
              title: 'PTE Academic Writing (40 - 60 minutes)',
              description: ['1. Summarize Written Text', '2. Write essay'],
              link: Path.landing.academicWriting.path,
            },
            {
              title: 'PTE Academic Listening (45 - 57 minutes)',
              description: [
                '1. Summarize spoken text',
                '2. Multiple-choice, choose multiple answers',
                '3. Fill in the blanks',
                '4. Highlight correct summary',
                '5. Select missing word',
                '6. Highlight incorrect words',
                '7. Write from dictation',
              ],
              link: Path.landing.academicListening.path,
            },
          ].map((item, index) => (
            <div
              key={index}
              className="flex flex-col justify-between group bg-white border border-gray-300 shadow-sm p-6 rounded-lg hover:shadow-lg hover:bg-gradient-to-b hover:from-[#2054E9] hover:to-[#2C62FF] hover:text-[#FFFFFF]"
            >
              <div>
                <p className="text-h4m transition-all duration-300">
                  {item.title}
                </p>
                <ul className="list-none space-y-2 mt-2">
                  {item.description.map((point, idx) => (
                    <li
                      key={idx}
                      className="text-gray-700 text-sm transition-all duration-300 group-hover:text-[#FFFFFF]"
                    >
                      {point}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="flex justify-end">
                <Link to={item.link}>
                  <button className="mt-6 px-6 py-2 bg-blue-600 text-white font-medium rounded-full hover:bg-blue-700 group-hover:bg-[#FFFFFF] flex items-center space-x-2 transition-all duration-300">
                    <span className="text-bodyr transition-all duration-300 group-hover:text-[#2054E9]">
                      Read More
                    </span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 transition-all duration-300 group-hover:text-[#2054E9]"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M9 5l7 7-7 7"
                      />
                    </svg>
                  </button>
                </Link>
              </div>
            </div>
          ))}
        </div>
      ),
    },
  ]

  return (
    <div className="w-full bg-white">
      {/* Header Section */}
      <AutomateScoringHeader />

      {/* Test Format Section */}
      <AutomateScoringTestFormat tabs={skillTestTabs} />

      {/* Call-to-Action Section */}
      <SkillCTASection />
    </div>
  )
}

export default AutomateScoringPage
