import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import ReportProgressOverallSectionalSection from './ReportProgressOverallSectionalSection'
import { useAuth } from '../../../providers/AuthProvider'
import { progressTrackerSectionalReading } from '../../../services/testHistory.services'
import LoadingMessage from '../../Items/LoadingMessage'

interface HistorySectionalReading {
  audio_length: string
  auto_analytic: string
  buy_id: number
  context: string
  context1: string
  date: string // ISO format date string
  dynamic_total: number
  finalkeywords: string
  fluency: string
  form: string
  grammar: string
  id: number
  listening_section: string
  marks: string
  option: Option[] // Assuming an Option interface exists
  orignaldescriptionofq: string
  pronunciation: string
  question_audio: string
  question_desc: string
  question_id: number
  question_orignal_desc: string
  question_type: number
  reading_section: string
  section: string
  speakinig_section: string
  spelling: string
  test_id: number
  totalScore: string
  useanswer: string
  user_id: number
  word_count: string
  writing_section: string
}

// Define the Option interface
interface Option {
  id: number
  options: string
  correct: number
  sequence: number
}

const ReportHistoryReadingSectionalSection = () => {
  let { buyId } = useParams()
  const { userProfile } = useAuth()
  const [isLoading, setIsLoading] = useState(true)
  const [readingScoreOverall, setReadingScoreOverall] = useState(0)
  const [testHistoryReading, setTestHistoryReading] = useState<
    HistorySectionalReading[]
  >([])

  useEffect(() => {
    try {
      const fetchProgressTrackerSectionalReading = async () => {
        if (userProfile && userProfile.userId && !isNaN(Number(buyId))) {
          try {
            const response = await progressTrackerSectionalReading(
              userProfile.userId,
              Number(buyId),
            )
            if (response.data.success) {
              let totalScore =
                response.data.total_score > 0
                  ? Math.round((response.data.total_score * 90) / 61)
                  : 0
              const remainigMarks = 90 - totalScore

              if (remainigMarks > 10) {
                totalScore = totalScore + 10
              } else {
                totalScore = totalScore + remainigMarks
              }

              totalScore = totalScore > 90 ? 90 : Math.round(totalScore)
              setReadingScoreOverall(totalScore)
              setTestHistoryReading(response.data.result)
            }
          } catch (error) {
            console.error(error)
          } finally {
            setTimeout(() => {
              setIsLoading(false)
            }, 2000)
          }
        } else {
          console.error(
            'User profile is missing or invalid or buy id is invalid!',
          )
        }
      }

      fetchProgressTrackerSectionalReading()
    } catch (error) {
      console.error(error)
    }
  }, [userProfile, buyId])

  const overallScores = [
    {
      id: 1,
      skill: 'Overall Reading Score',
      score: readingScoreOverall,
    },
  ]

  // Function to create markup for dangerouslySetInnerHTML
  const createMarkup = (html: string) => {
    return { __html: html }
  }

  return (
    <div className="report-history-reading-sectional-section">
      <p className="text-h1 font-landingPrimary mb-[0.4rem]">Report History</p>
      {isLoading ? (
        <div className="mt-[3rem]">
          <LoadingMessage message="Loading report history sectional reading..." />
        </div>
      ) : (
        <>
          <ReportProgressOverallSectionalSection
            overallScoreInfo={overallScores}
          />
          <div className="history-details-reading w-full h-full mt-[1rem] p-2 overflow-x-auto font-landingPrimary">
            <div className="min-w-full inline-block align-middle overflow-hidden">
              <table className="min-w-full table-fixed">
                <thead>
                  <tr className="text-bodyr text-neutrals-2 h-[3.75rem]">
                    <th className="w-[10rem] p-2" align="left">
                      Test
                    </th>
                    <th className="w-[20rem] p-2" align="left">
                      Answer
                    </th>
                    <th className="w-[20rem] p-2" align="left">
                      Your Answer
                    </th>
                    <th className="w-[5.2rem] p-2" align="left">
                      Grammar
                    </th>
                    <th className="w-[5.2rem] p-2" align="left">
                      Overall Score
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {testHistoryReading.length > 0 ? (
                    testHistoryReading.map((data, index) => (
                      <tr
                        key={index}
                        className={`text-bodyr ${index % 2 === 0 ? 'bg-[#FAFAFA]' : ''}`}
                      >
                        <td className="p-2 text-bodym rounded-l-xl">
                          {data.section}
                        </td>
                        <td
                          className="p-2 text-bodyr"
                          dangerouslySetInnerHTML={createMarkup(
                            data.finalkeywords,
                          )}
                        ></td>
                        <td
                          className="p-2 text-bodyr"
                          dangerouslySetInnerHTML={createMarkup(
                            data.question_type === 14
                              ? data.question_desc
                              : data.useanswer,
                          )}
                        ></td>
                        <td className="p-2 text-bodyr">{data.grammar}</td>
                        <td className="p-2 text-bodyr rounded-r-xl">
                          {data.totalScore}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className="bg-[#FAFAFA]">
                      <td
                        colSpan={5}
                        className="p-4 text-center text-bodyr text-red-400 font-bold"
                      >
                        No reading test history available.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default ReportHistoryReadingSectionalSection
