import React from 'react'
import BlogPost from '../../components/LandingPage/BlogPost'
import BlogImage1 from '../../images/Landingpage/Blog/blog-1.png'
import BlogImage2 from '../../images/Landingpage/Blog/blog-2.png'
import BlogImage3 from '../../images/Landingpage/Blog/blog-3.png'
import BlogImage4 from '../../images/Landingpage/Blog/blog-4.png'
import BlogImage5 from '../../images/Landingpage/Blog/blog-5.png'
import BlogImage6 from '../../images/Landingpage/Blog/blog-6.png'

const BlogPostPage: React.FC = () => {
  // Blog posts data
  const blogs = [
    {
      slug: 'top-tips-for-agents',
      title: 'Top Tips for Agents: Avoiding Common Buyer Pitfalls',
      image: BlogImage1,
      time: '6 min read',
      content: `
        Buying a property is one of the most significant decisions in a person's life. For agents, understanding the common buyer pitfalls is essential to guide clients effectively. 
        In this blog, we cover strategies for building trust with your clients, managing expectations, and avoiding common mistakes like over-promising. 
        Discover how you can create a seamless experience for buyers and become a go-to agent in your area.
      `,
    },
    {
      slug: 'mastering-test-prep',
      title: 'Mastering Test Prep: Strategies for Success',
      image: BlogImage2,
      time: '8 min read',
      content: `
        Preparing for tests can be daunting, but with the right strategies, you can achieve success. This blog dives into the best practices for time management, creating effective study plans, and leveraging modern tools for exam preparation.
        Learn how to stay focused, overcome anxiety, and achieve your target scores. Whether it's academic tests or professional certifications, these tips will set you on the right path.
      `,
    },
    {
      slug: 'pte-coaching-techniques',
      title: 'A Guide to PTE Coaching Techniques',
      image: BlogImage3,
      time: '5 min read',
      content: `
        Coaching for PTE requires a deep understanding of the test format, question types, and scoring system. In this guide, we outline proven techniques that coaches can use to enhance their students' performance.
        From personalized feedback to adaptive practice tests, explore the tools and strategies that will make your coaching sessions impactful and efficient.
      `,
    },
    {
      slug: 'understanding-migration-rules',
      title: 'Understanding Migration Rules',
      image: BlogImage4,
      time: '7 min read',
      content: `
        Migration can be a complex process with ever-changing rules and regulations. This blog provides a comprehensive overview of the latest migration policies, tips for smooth applications, and common pitfalls to avoid.
        Whether you're a migration agent or someone planning to migrate, this blog will equip you with the knowledge you need to navigate the system confidently.
      `,
    },
    {
      slug: 'boost-your-test-score',
      title: 'Boost Your Test Score with These Tips',
      image: BlogImage5,
      time: '6 min read',
      content: `
        Achieving a high test score is possible with the right approach. This blog shares actionable tips on improving your skills in key test areas like reading, writing, listening, and speaking.
        Discover how regular practice, targeted preparation, and time management can significantly enhance your performance. Start implementing these tips today to see immediate improvement.
      `,
    },
    {
      slug: 'key-migration-insights',
      title: 'Key Migration Insights for Agents',
      image: BlogImage6,
      time: '9 min read',
      content: `
        As a migration agent, staying informed about the latest insights is crucial for providing accurate advice to your clients. This blog highlights key trends, challenges, and opportunities in the migration industry.
        Learn how to address client concerns effectively, stay updated on policy changes, and grow your expertise in the field. Equip yourself with the insights needed to excel as a migration professional.
      `,
    },
  ]

  return <BlogPost blogPosts={blogs} />
}

export default BlogPostPage
