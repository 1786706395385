import { useForm } from 'react-hook-form'
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js'
import { checkoutStripe } from '../../services/shop.services'
import { useAuth } from '../../providers/AuthProvider'
import Path from '../../routes/Path'
import { useNavigate } from 'react-router-dom'
import { createOrder } from '../../services/cart.services'
import { toastError } from '../../utils/utils'

interface Props {
  total: string
}

interface FormData {
  userEmail: string
}

const CheckoutForm: React.FC<Props> = ({ total }) => {
  const stripe = useStripe()
  const elements = useElements()
  const { userProfile } = useAuth()
  const navigate = useNavigate()

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<FormData>()

  // Customize the CardElement
  const ELEMENT_OPTIONS = {
    style: {
      base: {
        color: '#32325d',
        fontSize: '14px',
        fontWeight: '400',
        fontFamily: 'Roboto',
        lineHeight: '22px',
        backgroundColor: 'white',
        '::placeholder': {
          color: '#00000073',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  }

  const onSubmit = async (data: FormData) => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable form submission until Stripe.js has loaded.
      toastError('Stripe is not loaded yet, please try again later!')
      return
    }

    const cardNumberElement = elements.getElement(CardNumberElement)
    const cardExpiryElement = elements.getElement(CardExpiryElement)
    const cardCvcElement = elements.getElement(CardCvcElement)

    if (!cardNumberElement || !cardExpiryElement || !cardCvcElement) {
      toastError('Card Element not found!')
      return
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardNumberElement,
      billing_details: {
        name: userProfile?.name || 'Default username', //Will be changed based on username and email
        email: data.userEmail,
      },
    })

    if (error) {
      toastError(
        error.message || 'An unknown error occurred. Please try again.',
      )
    } else {
      console.log('[PaymentMethod]', paymentMethod)
      // Process paymentMethod further
      if (userProfile && userProfile.userId) {
        try {
          const response = await checkoutStripe(
            userProfile.userId,
            paymentMethod.id,
          )
          if (response.data.success) {
            navigate(Path.purchaseSucceed.path)
          } else {
            toastError(response.data.message)
          }
        } catch (error) {
          if (error instanceof TypeError) {
            toastError(error.message)
          } else {
            toastError('Something wrong!')
          }
        }
      } else {
        console.log(userProfile)
        toastError('Something wrong!')
      }
    }
  }

  const paypalStyle = {
    border: '1px solid #000',
    borderRadius: '10px',
  }

  const handleCreateOrder = async () => {
    if (userProfile && userProfile.userId) {
      const order = await createOrder(userProfile.userId)
      if (order.data.message !== 'Total payment done successfully!') {
        window.location.replace(
          `https://www.sandbox.paypal.com/checkoutnow?token=${order.data}`,
        )
      }
    }
  }

  return (
    <>
      <p className="text-h2 mb-4">Pay with Stripe</p>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="checkout-form-details w-full lg:min-h-[23.625rem] h-full font-landingPrimary bg-[#F0F7FF] p-4 rounded-lg flex flex-col gap-4 relative my-4"
      >
        <div className="checkout-form-details__title text-h3m">
          Payment Info
        </div>
        <div className="checkout-form-details__total-price flex justify-between items-center border-y-2 border-[#0000000F] w-full h-[2.875rem]">
          <p className="text-bodym text-neutrals-2">Total</p>
          <p className="text-bodyb">AUD ${total}</p>
        </div>
        <div className="checkout-form-details__customer-email flex flex-col gap-2">
          <label htmlFor="customer-email" className="text-bodym block">
            Customer's email
          </label>
          <input
            id="customer-email"
            type="text"
            placeholder="Email address"
            className={`rounded-lg shadow-cart-checkout-input h-[2.375rem] p-[0.438rem] ${errors.userEmail ? 'border border-1 border-red-500' : ''}`}
            {...register('userEmail', {
              required: 'Please enter your email address!',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Invalid email address!',
              },
            })}
          />
          {errors.userEmail && (
            <div className="text-red-500 text-bodyr">
              {errors.userEmail.message}
            </div>
          )}
        </div>
        <div className="checkout-form-details__stripe-info flex gap-3">
          <div className="stripe-info__card-number lg:w-[18.25rem] w-full flex flex-col gap-2">
            <label htmlFor="stripe__card-number" className="text-bodym">
              Card number
            </label>
            <CardNumberElement
              id="stripe__card-number"
              options={ELEMENT_OPTIONS}
              className="text-bodyr bg-white shadow-cart-checkout-input p-2 border border-1 border-[#0000000F] rounded w-full mb-4"
            />
          </div>
          <div className="stripe-info__expired-date lg:w-[4.875rem] w-full flex flex-col gap-2">
            <label htmlFor="stripe__expired-date" className="text-bodym">
              Expired date
            </label>
            <CardExpiryElement
              id="stripe__expired-date"
              options={ELEMENT_OPTIONS}
              className="text-bodyr bg-white shadow-cart-checkout-input p-2 border border-1 border-[#0000000F] rounded w-full mb-4"
            />
          </div>
          <div className="stripe-info__cvc lg:w-[3.4rem] w-full flex flex-col gap-2">
            <label htmlFor="stripe__cvc" className="text-bodym">
              CVC
            </label>
            <CardCvcElement
              id="stripe__cvc"
              options={ELEMENT_OPTIONS}
              className="text-bodyr bg-white shadow-cart-checkout-input p-2 border border-1 border-[#0000000F] rounded w-full mb-4"
            />
          </div>
        </div>
        <div className="checkout-form-details__stripe-checkout flex justify-end w-full">
          <button
            type="submit"
            disabled={!stripe}
            className="bg-info hover:bg-[#1890FF] lg:w-[6.563rem] w-full h-[2.75rem] text-white text-bodyr rounded-lg shadow-cart-checkout-pay"
          >
            Check out
          </button>
        </div>
      </form>
      <p className="text-h2 mb-4">Pay with Paypal</p>
      <button onClick={handleCreateOrder} style={paypalStyle}>
        <svg
          width="100px"
          height="70px"
          viewBox="0 -140 780 780"
          enableBackground="new 0 0 780 500"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="m168.38 169.35c-8.399-5.774-19.359-8.668-32.88-8.668h-52.346c-4.145 0-6.435 2.073-6.87 6.215l-21.264 133.48c-0.221 1.311 0.107 2.51 0.981 3.6 0.869 1.092 1.962 1.635 3.271 1.635h24.864c4.361 0 6.758-2.068 7.198-6.215l5.888-35.986c0.215-1.744 0.982-3.162 2.291-4.254 1.308-1.09 2.944-1.803 4.907-2.129 1.963-0.324 3.814-0.488 5.562-0.488 1.743 0 3.814 0.111 6.217 0.328 2.397 0.217 3.925 0.324 4.58 0.324 18.756 0 33.478-5.285 44.167-15.867 10.684-10.576 16.032-25.242 16.032-44.004 0-12.868-4.203-22.191-12.598-27.974zm-26.989 40.08c-1.094 7.635-3.926 12.649-8.506 15.049-4.581 2.403-11.124 3.599-19.629 3.599l-10.797 0.326 5.563-35.007c0.434-2.397 1.851-3.597 4.252-3.597h6.218c8.72 0 15.049 1.257 18.975 3.761 3.924 2.51 5.233 7.801 3.924 15.869z"
            fill="#003087"
          />
          <path
            d="m720.79 160.68h-24.207c-2.406 0-3.822 1.2-4.254 3.601l-21.266 136.1-0.328 0.654c0 1.096 0.436 2.127 1.311 3.109 0.867 0.98 1.963 1.471 3.27 1.471h21.596c4.137 0 6.428-2.068 6.871-6.215l21.264-133.81v-0.325c-1e-3 -3.055-1.423-4.581-4.257-4.581z"
            fill="#009CDE"
          />
          <path
            d="m428.31 213.36c0-1.088-0.438-2.126-1.305-3.105-0.875-0.981-1.857-1.475-2.945-1.475h-25.191c-2.404 0-4.367 1.096-5.891 3.271l-34.678 51.039-14.395-49.074c-1.096-3.487-3.492-5.236-7.197-5.236h-24.541c-1.093 0-2.074 0.492-2.941 1.475-0.875 0.979-1.309 2.019-1.309 3.105 0 0.439 2.127 6.871 6.379 19.303 4.252 12.436 8.832 25.85 13.74 40.246 4.908 14.393 7.469 22.031 7.688 22.896-17.886 24.432-26.825 37.518-26.825 39.26 0 2.838 1.415 4.254 4.253 4.254h25.191c2.398 0 4.36-1.088 5.89-3.27l83.427-120.4c0.433-0.432 0.65-1.192 0.65-2.29z"
            fill="#003087"
          />
          <path
            d="m662.89 208.78h-24.865c-3.057 0-4.904 3.6-5.559 10.799-5.678-8.722-16.031-13.089-31.084-13.089-15.703 0-29.064 5.89-40.076 17.668-11.016 11.778-16.521 25.632-16.521 41.552 0 12.871 3.762 23.121 11.285 30.752 7.525 7.639 17.611 11.451 30.266 11.451 6.324 0 12.758-1.311 19.301-3.926 6.543-2.617 11.664-6.105 15.379-10.469 0 0.219-0.223 1.197-0.654 2.941-0.441 1.748-0.656 3.061-0.656 3.926 0 3.494 1.414 5.234 4.254 5.234h22.576c4.139 0 6.541-2.068 7.193-6.215l13.416-85.39c0.215-1.31-0.111-2.507-0.982-3.599-0.877-1.088-1.965-1.635-3.273-1.635zm-42.694 64.454c-5.562 5.453-12.27 8.178-20.121 8.178-6.328 0-11.449-1.742-15.377-5.234-3.928-3.482-5.891-8.281-5.891-14.395 0-8.064 2.727-14.886 8.182-20.447 5.445-5.562 12.213-8.342 20.283-8.342 6.102 0 11.174 1.799 15.213 5.396 4.031 3.6 6.055 8.562 6.055 14.889-2e-3 7.851-2.783 14.505-8.344 19.955z"
            fill="#009CDE"
          />
          <path
            d="m291.23 208.78h-24.865c-3.058 0-4.908 3.6-5.563 10.799-5.889-8.722-16.25-13.089-31.081-13.089-15.704 0-29.065 5.89-40.078 17.668-11.016 11.778-16.521 25.632-16.521 41.552 0 12.871 3.763 23.121 11.288 30.752 7.525 7.639 17.61 11.451 30.262 11.451 6.104 0 12.433-1.311 18.975-3.926 6.543-2.617 11.778-6.105 15.704-10.469-0.875 2.615-1.309 4.906-1.309 6.867 0 3.494 1.417 5.234 4.253 5.234h22.574c4.141 0 6.543-2.068 7.198-6.215l13.413-85.39c0.215-1.31-0.111-2.507-0.981-3.599-0.873-1.088-1.962-1.635-3.269-1.635zm-42.695 64.616c-5.563 5.35-12.382 8.016-20.447 8.016-6.329 0-11.4-1.742-15.214-5.234-3.819-3.482-5.726-8.281-5.726-14.395 0-8.064 2.725-14.886 8.18-20.447 5.449-5.562 12.211-8.343 20.284-8.343 6.104 0 11.175 1.8 15.214 5.397 4.032 3.6 6.052 8.562 6.052 14.889-1e-3 8.07-2.781 14.779-8.343 20.117z"
            fill="#003087"
          />
          <path
            d="m540.04 169.35c-8.398-5.774-19.355-8.668-32.879-8.668h-52.02c-4.363 0-6.764 2.073-7.197 6.215l-21.266 133.48c-0.221 1.311 0.107 2.51 0.982 3.6 0.865 1.092 1.961 1.635 3.27 1.635h26.826c2.617 0 4.361-1.416 5.236-4.252l5.889-37.949c0.217-1.744 0.98-3.162 2.291-4.254 1.309-1.09 2.943-1.803 4.908-2.129 1.961-0.324 3.812-0.488 5.561-0.488 1.744 0 3.814 0.111 6.215 0.328 2.398 0.217 3.93 0.324 4.58 0.324 18.76 0 33.479-5.285 44.168-15.867 10.688-10.576 16.031-25.242 16.031-44.004 1e-3 -12.868-4.2-22.192-12.595-27.974zm-33.533 53.819c-4.799 3.271-11.998 4.906-21.592 4.906l-10.471 0.328 5.562-35.008c0.432-2.396 1.85-3.598 4.252-3.598h5.887c4.799 0 8.615 0.219 11.455 0.654 2.83 0.438 5.561 1.799 8.178 4.088 2.619 2.291 3.926 5.619 3.926 9.979 0 9.164-2.402 15.377-7.197 18.651z"
            fill="#009CDE"
          />
        </svg>
      </button>
    </>
  )
}

export default CheckoutForm
