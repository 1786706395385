import React, { createContext, useContext, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  login as apiLogin,
  logout as apiLogout,
  socialLogin as apiSocialLogin,
} from '../services/auth.services'
import Path from '../routes/Path'
import UserProfile from '../models/userProfile.model'

type Props = {
  children: React.ReactNode
}

interface AuthValue {
  login: (email: string, password: string) => Promise<void>
  socialLogin: (email: string, name: string, imageURL: string) => Promise<void>
  logout: () => Promise<void>
  token: string | null
  userProfile: UserProfile | undefined
  setUserProfile: React.Dispatch<React.SetStateAction<UserProfile | undefined>>
}

export const AuthContext = createContext<AuthValue | null>(null)

export function useAuth(): AuthValue {
  const state = useContext(AuthContext)
  if (!state) {
    throw new Error('useAuth must be used within AuthProvider')
  }
  return state
}

export function AuthProvider({ children }: Props) {
  const [token, setToken] = useState<string | null>(
    localStorage.getItem('accessToken'),
  )
  const [userProfile, setUserProfile] = useState<UserProfile | undefined>(
    () => {
      const savedProfile = localStorage.getItem('userProfile')
      return savedProfile ? JSON.parse(savedProfile) : {}
    },
  )
  const navigate = useNavigate()

  useEffect(() => {
    if (token) {
      localStorage.setItem('accessToken', token)
    } else {
      localStorage.removeItem('accessToken')
    }
  }, [token])

  useEffect(() => {
    if (userProfile && Object.keys(userProfile).length !== 0) {
      localStorage.setItem('userProfile', JSON.stringify(userProfile))
    } else {
      localStorage.removeItem('userProfile')
    }
  }, [userProfile])

  const login = async (email: string, password: string) => {
    try {
      const response = await apiLogin(email, password)

      if (!response.data.success) {
        // If the API responds with success: false, throw an error with the provided message
        throw new Error(response.data.message)
      }

      // Set the token and user profile if the login was successful
      setToken(response.data.token)
      setUserProfile({
        userId: response.data.userId,
        email: response.data.email,
        name: response.data.name,
        phone: response.data.phone,
        image: response.data.image,
      })
    } catch (error: any) {
      // Check if the error has a response with a message from the API, otherwise, show a default error message
      const errorMessage =
        error.message ||
        error.response?.data?.message ||
        'Login failed. Please try again.'
      throw new Error(errorMessage)
    }
  }

  const socialLogin = async (email: string, name: string, imageURL: string) => {
    try {
      const response = await apiSocialLogin(email, name, imageURL)

      if (!response.data.success) {
        throw new Error(response.data.message)
      } else {
        setToken(response.data.token)
        setUserProfile({
          userId: response.data.userId,
          email: response.data.email,
          name: response.data.name,
          phone: response.data.phone,
          image: response.data.image,
        })
      }
    } catch (error: any) {
      const errorMessage =
        error.message ||
        error.response?.data?.message ||
        'Login failed. Please try again.'
      throw new Error(errorMessage)
    }
  }

  const logout = async () => {
    try {
      if (userProfile) {
        const response = await apiLogout(userProfile.userId)
        if (response.data.success === false) {
          // throw new Error(response.data.message)
          console.error('Logout failed:', response.data.message)
        }
      }
    } catch (error: any) {
      // throw new Error(
      //   error.response?.data?.message || 'Logout failed. Please try again.',
      // )
      console.error('Logout failed:', error.message)
    } finally {
      setToken(null)
      setUserProfile(undefined)
      navigate(Path.landing.main.path)
      localStorage.removeItem('accessToken')
      localStorage.removeItem('userProfile')
    }
  }

  const providerValue = {
    login,
    socialLogin,
    logout,
    token,
    userProfile,
    setUserProfile,
  }

  return (
    <AuthContext.Provider value={providerValue}>
      {children}
    </AuthContext.Provider>
  )
}
