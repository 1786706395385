import Test from '../../models/test.model'
import { useNavigate } from 'react-router-dom'
import Path from '../../routes/Path'
import { ReactComponent as DurationIcon } from '../../images/Purchase/purchase-duration-icon.svg'
import { ReactComponent as QuestionIcon } from '../../images/Purchase/purchase-question-icon.svg'
import { ReactComponent as PriceIcon } from '../../images/Purchase/purchase-price-icon.svg'
import { ReactComponent as UnlockIcon } from '../../images/Purchase/purchase-unlock-icon.svg'
import PurchaseBanner from '../../images/Purchase/purchase-banner.png'
import ErrorMessage from '../Items/ErrorMessage'
import { unlockMockByCredits } from '../../services/purchase.services'
import { useAuth } from '../../providers/AuthProvider'
import { toastError } from '../../utils/utils'

interface MockTestSectionProps {
  mockTests: Test[]
}

const MockTestSection: React.FC<MockTestSectionProps> = ({ mockTests }) => {
  const navigate = useNavigate()
  const { userProfile } = useAuth()
  //Find all tests that: completed_statut != 0 && mockCredits != 0
  const filteredTests: Test[] = mockTests.filter(
    (test) => test.completed_statut !== 0 && test.mockCredits !== 0,
  )

  const handleUnlockMockByCredits = async (testId: number) => {
    if (userProfile) {
      try {
        const response = await unlockMockByCredits(userProfile.userId, testId)
        if (response.data.success) {
          navigate(Path.myUnlockedTests.path)
        } else {
          toastError(response.data.message)
        }
      } catch (error) {
        console.error(error)
        toastError('Something went wrong!')
      }
    }
  }

  return (
    <div className="purchase-section__mock-test font-landingPrimary w-full flex flex-wrap gap-6">
      {filteredTests.length === 0 ? (
        <ErrorMessage message="You have no items to show." />
      ) : (
        filteredTests.map((test: Test) => (
          <div
            key={test.id}
            className="purchase-section__mock-test__item lg:w-[18.75rem] w-full lg:min-h-[26.75rem] h-full rounded-lg shadow-purchase-card"
          >
            <div className="mock-test__item__image w-full h-[14.375rem] bg-[#D9D9D9] rounded-t-lg relative">
              <img
                src={PurchaseBanner}
                alt={test.name}
                className="object-fill w-full h-full rounded-t-lg"
              />
              <div className="mock-test__item__sale-off w-[5.25rem] h-[1.25rem] rounded-[6.25rem] bg-[#A0D911] absolute top-4 right-4">
                <p className="text-footnote text-center">
                  {test.discount}% Sale Off
                </p>
              </div>
            </div>
            <div className="mock-test__item__item__title mt-[1rem] mb-[0.6rem] px-2">
              <p className="text-h4m">{test.name}</p>
            </div>
            <div className="mock-test__item__info flex flex-col gap-2">
              <div className="mock-test__item__info__price flex gap-2 px-2 items-center">
                <PriceIcon />
                <p className="text-bodyr text-neutrals-2">
                  {Number(test.price).toFixed(2)} AUD
                </p>
              </div>
              <div className="mock-test__item__info__duration flex gap-2 px-2 items-center">
                <DurationIcon />
                <p className="text-bodyr text-neutrals-2">
                  {test.duration} hrs
                </p>
              </div>
              <div className="mock-test__item__info__question flex gap-2 px-2 items-center">
                <QuestionIcon />
                <p className="text-bodyr text-neutrals-2">
                  {test.total_que} questions included
                </p>
              </div>
            </div>
            <div className="mock-test__item__activate w-full px-2 my-[1rem]">
              <button
                onClick={() => handleUnlockMockByCredits(test.id)}
                className="w-full h-[2.5rem] bg-info hover:bg-[#1890FF] text-bodyr text-white rounded-lg flex items-center justify-center gap-3"
              >
                <UnlockIcon />
                Unlock by Credits
              </button>
            </div>
          </div>
        ))
      )}
    </div>
  )
}

export default MockTestSection
