import React from 'react'
import { useParams } from 'react-router-dom'
import SubscribeForm from '../../components/LandingPage/SubcribeForm'
import SkillCTASection from './AcademicSkill/SkillCTASection'
import BlogImage from '../../images/Landingpage/Blog/popular_post.png'

interface BlogPostProps {
  blogPosts: {
    slug: string
    title: string
    content: string
  }[]
}

const BlogPost: React.FC<BlogPostProps> = ({ blogPosts }) => {
  const { slug } = useParams<{ slug: string }>()

  // Find the blog post by slug
  const blogPost = blogPosts.find((post) => post.slug === slug)

  if (!blogPost) {
    return <p>Blog post not found.</p>
  }

  return (
    <div>
      {' '}
      <div className="w-full px-6 lg:px-12 py-6 lg:py-12 flex flex-col lg:flex-row gap-6">
        {/* Left Section: Blog Content */}
        <div className="w-full lg:w-2/3 pr-0 lg:pr-12">
          <h1 className="text-h1 mb-6">{blogPost.title}</h1>
          <div className="text-bodyr text-[#595959]">{blogPost.content}</div>
        </div>

        {/* Right Section: Popular Posts and Subscribe Form */}
        <div className="w-full lg:w-1/3 space-y-6">
          <div>
            <h2 className="text-h5m text-[#00000073] mb-4">Popular Posts</h2>
            <div className="rounded-md shadow-md overflow-hidden">
              <img
                src={BlogImage}
                alt="Popular Post"
                className="w-full object-cover"
              />
              <div className="p-4">
                <button className="px-6 py-2 bg-yellow-500 text-white rounded-full text-footnote">
                  View Practice Demo
                </button>
                <h3 className="text-h5m text-[#141414] mt-4">
                  Top Tips for Agents: Avoiding Common Buyer Pitfalls
                </h3>
                <p className="text-h5m text-[#BFBFBF] mt-2">6 min read</p>
              </div>
            </div>
          </div>
          <SubscribeForm />
        </div>
      </div>
      <SkillCTASection />
    </div>
  )
}

export default BlogPost
