import React, { useState, useEffect, useCallback } from 'react'
import { Link } from 'react-router-dom'
import CartSection from '../components/CartPage/CartSection'
import { getCart, removeCart } from '../services/cart.services'
import { useAuth } from '../providers/AuthProvider'
import Cart from '../models/cart.model'
import { AxiosResponse } from 'axios'
import LoadingMessage from '../components/Items/LoadingMessage'
import { toastError } from '../utils/utils'

const CartPage: React.FC = () => {
  const { userProfile } = useAuth()
  const [cartData, setCartData] = useState<Cart | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  // Fetch cart data with error handling
  const fetchCartData = useCallback(async () => {
    if (!userProfile) return

    setLoading(true)
    setError(null)

    try {
      const response = await getCart(userProfile.userId)
      setCartData(response.data)
    } catch (error) {
      console.error('Error fetching cart data:', error)
      setError('Failed to load cart data.')
    } finally {
      setTimeout(() => {
        setLoading(false)
      }, 2000)
    }
  }, [userProfile])

  // Fetch cart data on component mount or when userProfile changes
  useEffect(() => {
    if (userProfile) {
      fetchCartData()
    }
  }, [userProfile, fetchCartData])

  // Handle removing cart item
  const handleRemoveCartItem = async (cartId: number) => {
    try {
      const response: AxiosResponse = await removeCart(cartId)
      if (response.data.success) {
        fetchCartData()
      } else {
        toastError(response.data.message)
        console.error('Error:', response.data.message)
      }
    } catch (error) {
      toastError('Failed to remove cart item.')
      console.error('Error removing cart item:', error)
    }
  }

  return (
    <>
      <div className="navigation my-[1rem]">
        <p className="text-bodyr font-landingPrimary text-neutrals-2">
          <Link to="/homepage">Home</Link> / Cart
        </p>
      </div>
      <div className="w-full min-h-[80vh] lg:px-[4rem] lg:py-[2rem] p-[1rem] bg-white rounded-l-lg">
        {loading && <LoadingMessage message="Loading cart information..." />}
        {error && <p className="text-red-500">{error}</p>}
        {cartData && !loading && !error && (
          <CartSection
            cartData={cartData}
            onRemoveCartItem={handleRemoveCartItem}
          />
        )}
      </div>
    </>
  )
}

export default CartPage
