import React, { useState } from 'react'
import PlanTokenCard from './PlanTokenCard'
import PlanCheckoutForm from './PlanCheckoutForm'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import TokenPackage from '../../models/tokenPakage.model'

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLIC_KEY as string,
)

interface CartSectionProps {
  planData: TokenPackage
}

const PlanDetailSection: React.FC<CartSectionProps> = ({ planData }) => {
  const [totalAmount, setTotalAmount] = useState<number>(
    Number(planData.tkPkgPrice),
  )
  const [promo, setPromo] = useState<string>('')

  const handleUpdateTokenPrice = (newPrice: string) => {
    setTotalAmount(Number(newPrice))
  }

  const handleUpdatePromo = (promo: string) => {
    setPromo(promo)
  }

  const renderTokenPacks = () => (
    <PlanTokenCard
      planDetails={planData}
      onUpdatePrice={handleUpdateTokenPrice}
      onUpdatePromo={handleUpdatePromo}
    />
  )

  return (
    <div className="cart-section font-landingPrimary">
      <div className="cart-section__checkout lg:w-full w-full lg:min-h-[56rem] lg:mt-[2rem] mt-[0.4rem] flex flex-wrap lg:gap-[2rem] gap-4">
        <div className="cart-section__checkout__products lg:w-[35.125rem] w-full h-full lg:py-0 py-[2rem] flex flex-col gap-4">
          <p className="text-h2">Mock test pack</p>
          {renderTokenPacks()}
        </div>
        <div className="cart-section__checkout__form lg:w-[30rem] w-full h-full">
          <Elements stripe={stripePromise}>
            <PlanCheckoutForm
              tokenId={planData.tkPkgId}
              total={totalAmount.toFixed(2)}
              promo={promo}
            />
          </Elements>
        </div>
      </div>
    </div>
  )
}

export default PlanDetailSection
