// src/utils/ArrayUtils.ts

export default class ArrayUtils {
  /**
   * Shuffle an array in random order
   * @param array - The array to shuffle
   * @returns A new array with elements shuffled
   */
  static shuffleArray<T>(array: T[]): T[] {
    const shuffled = [...array] // Clone the array to avoid mutating the original
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      ;[shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]] // Swap elements
    }
    return shuffled
  }
}
