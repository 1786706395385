import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import GoogleLoginButton from './GoogleLoginButton'
import { registerAccount } from '../../../services/auth.services'
import { toastError, toastSuccess } from '../../../utils/utils'

const RegistrationForm: React.FC = () => {
  const [checkPrivacy, setCheckPrivacy] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)

  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: {
      fullname: '',
      email: '',
      phoneNumber: '',
      nationality: '',
      password: '',
      confirmPassword: '',
    },
  })

  const onSubmit = async (data: any) => {
    try {
      const response = await registerAccount(
        data.email,
        data.fullname.trim(),
        data.password.trim(),
        data.nationality,
        data.phoneNumber,
      )
      if (response.data.success) {
        toastSuccess(response.data.message)
        setCheckPrivacy(false)
        reset()
      } else {
        toastError(response.data.message)
      }
    } catch (error: any) {
      if (error instanceof TypeError) {
        toastError(error.message)
      } else {
        toastError('Something wrong!')
      }
    }
  }

  return (
    <div className="registration-form lg:w-[31.5rem] md:w-2/3 lg:h-fit w-full h-full bg-neutrals-4 rounded-xl p-8">
      <GoogleLoginButton disabled={isDisabled} setDisabled={setIsDisabled} />
      <hr className="my-[2rem]" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4">
          <label htmlFor="fullname" className="block mb-1">
            Full name
          </label>
          <input
            type="text"
            id="fullname"
            className="w-full border border-[#D9D9D9] rounded-md px-4 py-2"
            placeholder="Type your full name here"
            {...register('fullname', {
              required: 'Please enter your full name!',
            })}
          />
          {errors.fullname && (
            <div className="text-red-500 mb-4">{errors.fullname.message}</div>
          )}
        </div>
        <div className="mb-4">
          <label htmlFor="email" className="block mb-1">
            Email Address
          </label>
          <input
            type="text"
            id="email"
            className="w-full border border-[#D9D9D9] rounded-md px-4 py-2"
            placeholder="Type your email address here"
            {...register('email', {
              required: 'Please enter your email address!',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Invalid email address!',
              },
            })}
          />
          {errors.email && (
            <div className="text-red-500 mb-4">{errors.email.message}</div>
          )}
        </div>
        <div className="mb-4">
          <label htmlFor="phoneNumber" className="block mb-1">
            Mobile Number
          </label>
          <input
            type="text"
            id="phoneNumber"
            className="w-full border border-[#D9D9D9] rounded-md px-4 py-2"
            placeholder="Type your mobile number here"
            {...register('phoneNumber', {
              required: 'Please enter your phone number!',
              pattern: {
                value:
                  /^\+?\d{1,4}[-\s]?\(?\d{2,4}\)?[-\s]?\d{3,4}[-\s]?\d{3,4}$/,
                message: 'Invalid phone number!',
              },
            })}
          />
          {errors.phoneNumber && (
            <div className="text-red-500 mb-4">
              {errors.phoneNumber.message}
            </div>
          )}
        </div>
        <div className="mb-4">
          <label htmlFor="nationality" className="block mb-1">
            Nationality
          </label>
          <select
            id="nationality"
            className="w-full border border-[#D9D9D9] rounded-md px-4 py-2"
            {...register('nationality', {
              required: 'Please select your nationality!',
            })}
          >
            <option value="">Select nationality</option>
            <option value="Vietnamese">Vietnamese</option>
            <option value="Indian">Indian</option>
            <option value="Chinese">Chinese</option>
            <option value="Napalese">Napalese</option>
            <option value="Bangladeshis">Bangladeshis</option>
            <option value="Philippines">Philippines</option>
            <option value="Others">Others</option>
          </select>
          {errors.nationality && (
            <div className="text-red-500 mb-4">
              {errors.nationality.message}
            </div>
          )}
        </div>
        <div className="mb-4">
          <label htmlFor="password" className="block mb-1">
            Password
          </label>
          <input
            type="password"
            id="password"
            className="w-full border border-[#D9D9D9] rounded-md px-4 py-2"
            placeholder="Type your password here"
            {...register('password', {
              required: 'Please enter your password!',
              minLength: {
                value: 6,
                message: 'Password must be from 6 charaters!',
              },
            })}
          />
          {errors.password && (
            <div className="text-red-500 mb-4">{errors.password.message}</div>
          )}
        </div>
        <div className="mb-4">
          <label htmlFor="confirmPassword" className="block mb-1">
            Confirm Password
          </label>
          <input
            type="password"
            id="confirmPassword"
            className="w-full border border-[#D9D9D9] rounded-md px-4 py-2"
            placeholder="Type your password here"
            {...register('confirmPassword', {
              required: 'Please enter your confirm password!',
              validate: (val: string) => {
                if (watch('password') !== val) {
                  return 'Your confirm password does not match with password!'
                }
              },
            })}
          />
          {errors.confirmPassword && (
            <div className="text-red-500 mb-4">
              {errors.confirmPassword.message}
            </div>
          )}
        </div>
        <div className="mt-[2rem] mb-[0.4rem] flex gap-x-2">
          <input
            data-testid="confirmPrivacy"
            type="checkbox"
            id="confirmPrivacy"
            checked={checkPrivacy}
            onChange={() => setCheckPrivacy(!checkPrivacy)}
          />
          <label
            htmlFor="confirmPrivacy"
            className="text-bodyr font-landingPrimary"
          >
            I have read and accept the Terms of Service and Privacy Policy.
          </label>
        </div>
        <button
          type="submit"
          className="w-full bg-info hover:bg-[#1890FF] text-white rounded-md p-3.5  disabled:bg-[#1890FF] disabled:text-white disabled:hover:bg-[#1890FF] disabled:cursor-not-allowed"
          disabled={!checkPrivacy}
        >
          Register
        </button>
        <div className="text-center font-landingPrimary my-2">
          Already have an account?{' '}
          <Link
            to="/login"
            className="center text-bodyr font-landingPrimary text-info"
          >
            Log in
          </Link>
        </div>
      </form>
    </div>
  )
}

export default RegistrationForm
