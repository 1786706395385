import React, { useRef, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import PteMasterLogo from '../../images/Landingpage/pte-master-logo.png'
import Path from '../../routes/Path'

const Header: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [practiceDropdownOpen, setPracticeDropdownOpen] = useState(false)
  const [mobileDropdownOpen, setMobileDropdownOpen] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)

  const practiceMaterialsItems = [
    { label: 'Speaking Section', path: Path.landing.academicSpeaking.path },
    { label: 'Writing Section', path: Path.landing.academicWriting.path },
    { label: 'Reading Section', path: Path.landing.academicReading.path },
    { label: 'Listening Section', path: Path.landing.academicListening.path },
  ]

  const headerMenuItems = [
    { content: 'Practice Materials', direction: '/' },
    {
      content: 'Automated Machine Scoring Test',
      direction: Path.landing.automateScoring.path,
    },
    { content: 'How It Works', direction: Path.landing.howItWorks.path },
    { content: 'Contact Us', direction: Path.landing.contactUs.path },
    { content: 'AI PTE Mock Test', direction: Path.landing.aiPteMockTest.path },
    {
      content: 'Online PTE Preparation Tool',
      direction: Path.landing.prepareTools.path,
    },
    { content: 'Blogs', direction: Path.landing.blog.main.path },
    { content: 'FAQs', direction: Path.landing.faqs.path },
    {
      content: 'Freebook',
      direction: 'https://home.masterpte.com.au/freebook/',
      isExternal: true,
    },
    {
      content: 'Toolkit',
      direction: 'https://lab.masterpte.com.au/order-form1588480379850',
      isExternal: true,
    },
  ]

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev)
  }

  const togglePracticeDropdown = () => {
    setPracticeDropdownOpen((prev) => !prev)
  }

  const toggleMobileDropdown = () => {
    setMobileDropdownOpen((prevState) => !prevState)
  }

  const closeDropdown = () => {
    setPracticeDropdownOpen(false)
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        closeDropdown()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <header className="w-full relative">
      {/* Main Nav */}
      <nav className="font-landingPrimary bg-white px-4 py-2.5">
        <div className="flex flex-wrap justify-between items-center mx-auto gap-y-2">
          {/* Logo */}
          <Link to="/" className="flex items-center">
            <img
              src={PteMasterLogo}
              alt="PTE-Master Logo"
              className="lg:w-[144px] md:w-[144px] w-[120px] h-[46px]"
            />
          </Link>

          {/* First Navigation Links */}
          <div className="hidden lg:flex items-center space-x-10 lg:order-1">
            {/* Practice Materials with Dropdown */}
            <div className="relative" ref={dropdownRef}>
              <button
                onClick={togglePracticeDropdown}
                className="text-bodyr text-neutrals-1 hover:text-blue-500"
              >
                Practice Materials
              </button>
              {practiceDropdownOpen && (
                <div className="absolute left-0 mt-2 bg-white shadow-lg rounded-lg w-48 z-10">
                  <ul className="py-2 text-left">
                    {practiceMaterialsItems.map((item, index) => (
                      <li key={index}>
                        <Link
                          to={item.path}
                          className="block px-4 py-2 text-bodyr text-gray-700 hover:bg-gray-100"
                        >
                          {item.label}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>

            {headerMenuItems.slice(1, 4).map((item, index) => (
              <Link
                key={index}
                to={item.direction}
                className="text-bodyr text-neutrals-1 hover:text-blue-500"
              >
                {item.content}
              </Link>
            ))}
          </div>

          {/* Register and Login Buttons */}
          <div className="flex items-center lg:order-2">
            <Link
              to="/register"
              className="hidden lg:block text-bodyr text-primary-5 border border-primary-5 bg-neutrals-4 hover:bg-[#E6F7FF] rounded-xl px-4 lg:px-[2rem] py-2 lg:py-2.5 mr-2"
            >
              Register
            </Link>
            <Link
              to="/login"
              className="hidden lg:block text-bodyr text-white bg-info hover:bg-[#1890FF] px-4 lg:px-[2rem] py-2 lg:py-2.5 rounded-xl"
            >
              Login
            </Link>

            {/* Mobile Menu Button */}
            <button
              onClick={toggleMenu}
              type="button"
              className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className={`w-6 h-6 ${menuOpen ? 'hidden' : 'block'}`}
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <svg
                className={`w-6 h-6 ${menuOpen ? 'block' : 'hidden'}`}
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      </nav>

      {/* Secondary Nav */}
      <nav
        className="hidden lg:block text-white py-3"
        style={{
          background: 'linear-gradient(360deg, #2054E9 -0.54%, #2C62FF 100%)',
        }}
      >
        <div className="flex justify-between items-center mx-auto xl:max-w-[50%] w-[90%]">
          {headerMenuItems.slice(4).map((item, index) =>
            item.isExternal ? (
              <a
                key={index}
                href={item.direction}
                target="_blank"
                rel="noopener noreferrer"
                className="text-bodyr"
              >
                {item.content}
              </a>
            ) : (
              <Link key={index} to={item.direction} className="text-bodyr">
                {item.content}
              </Link>
            ),
          )}
        </div>
      </nav>

      {/* Mobile Menu */}
      {menuOpen && (
        <>
          <div className="fixed top-0 right-0 h-full w-[250px] bg-[#FFFF] transform translate-x-0 transition-transform duration-300 lg:hidden z-50">
            {/* Close Button */}
            <button
              onClick={toggleMenu}
              className="text-gray-500 hover:text-gray-700 absolute top-4 right-4 text-2xl"
            >
              &times;
            </button>
            <ul className="flex flex-col mt-12 text-bodyr space-y-4 p-4">
              <li>
                <button
                  onClick={toggleMobileDropdown}
                  className="flex items-center justify-between w-full text-bodyr text-neutrals-1 block py-2 pr-4 pl-3 rounded hover:bg-gray-200"
                >
                  Practice Materials
                  <span
                    className={`ml-2 transform transition-transform ${
                      mobileDropdownOpen ? 'rotate-90' : ''
                    }`}
                  >
                    &gt;
                  </span>
                </button>
                {mobileDropdownOpen && (
                  <div className="ml-4 bg-white">
                    <ul className="pb-2 text-left">
                      {practiceMaterialsItems.map((item, index) => (
                        <li key={index}>
                          <Link
                            to={item.path}
                            className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                          >
                            {item.label}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </li>

              {headerMenuItems.slice(1).map((item, index) =>
                item.isExternal ? (
                  <a
                    key={index}
                    href={item.direction}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-bodyr block py-2 pr-4 pl-3 rounded text-neutrals-1 hover:bg-gray-200"
                  >
                    {item.content}
                  </a>
                ) : (
                  <Link
                    key={index}
                    to={item.direction}
                    className="text-bodyr block py-2 pr-4 pl-3 rounded text-neutrals-1 hover:bg-gray-200"
                  >
                    {item.content}
                  </Link>
                ),
              )}

              <li>
                <Link
                  to="/login"
                  className="text-bodyr text-white bg-info hover:bg-[#1890FF] px-4 py-2 rounded-xl"
                >
                  Login
                </Link>
              </li>

              <li>
                <Link
                  to="/register"
                  className="text-bodyr text-primary-5 border border-primary-5 bg-white hover:bg-gray-100 rounded-xl px-4 py-2"
                >
                  Register
                </Link>
              </li>
            </ul>
          </div>
          <div
            className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm z-40"
            onClick={toggleMenu}
          ></div>
        </>
      )}
    </header>
  )
}

export default Header
