import React from 'react'
import SkillCTASection from '../../components/LandingPage/AcademicSkill/SkillCTASection'
import SkillHeader from '../../components/LandingPage/AcademicSkill/SkillHeader'
import SkillTestFormat from '../../components/LandingPage/AcademicSkill/SkillTestFormat'
import AcademicBanner from '../../images/Landingpage/academic-banner.png'

const AcademicWritingPage: React.FC = () => {
  // Dynamic content for SkillHeader
  const skillHeaderContent = {
    title: 'PTE Academic Writing Section',
    subtitle:
      'Develop your English language Writing Skills with PTE Academic Writing Test',
    description:
      'The Pearson Test of English is one of the widely recognized exams for verifying the skills of an individual in the English language. The demand for PTE training course is higher due to Validate your English language writing skills with PTE Academic Writing test the numerous opportunities it opens for candidates. The test involves four different academic sections for different tasks, such as reading, writing, listening, and speaking. The PTE writing section proves your ability to produce written English in an academic setting. Before you start your preparations for the writing section of the PTE Mock Test, let us take a look at some of the essentials.',
    bannerImage: AcademicBanner,
  }

  // Dynamic content for SkillTestFormat
  const skillTestTabs = [
    {
      id: 'test-format',
      label: 'Test Format',
      content: (
        <div>
          <p className="text-bodyr pb-4">
            First of all, it is important to know about the PTE academic exam
            format.
          </p>
          <ul className="list-none space-y-2">
            {[
              'PTE Academic is a computer-based English language test for non-native English speakers aspiring to study abroad.',
              'The complete test involves a single session of 3 hours at a computer in a secure exam environment.',
              'The foremost advantage of the PTE Academic test is the quick availability of results.',
              'A closer look at the PTE Academic syllabus for the PTE writing module can show two different sections prominently.',
            ].map((point, index) => (
              <li key={index} className="flex items-start">
                <span className="text-green-600 font-bold mr-2">✔</span>
                <p className="text-bodyr">{point}</p>
              </li>
            ))}
          </ul>
        </div>
      ),
    },
    {
      id: 'skill-test',
      label: 'Skill Test',
      content: (
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
          {[
            {
              title: 'PTE Summarize Written Text',
              description: [
                'PTE summarize a text is the test for writing a summary of a passage.',
                'Candidates get 10 minutes to write a summary of various paragraphs on a specific topic.',
                'The PTE Summarize Written Text section is intended to test both the writing and reading skills of the candidate.',
              ],
            },
            {
              title: 'PTE Essay Writing',
              description: [
                'PTE Write essay is the test of writing an essay on a particular topic within 200 to 300 words.',
                'Candidates get 20 minutes to write the essay.',
                'PTE writing essay test helps in checking the candidate’s ability for writing skills.',
              ],
            },
          ].map((item, index) => (
            <div key={index} className="bg-[#F5F5F5] p-4 rounded-lg">
              <p className="text-h4m">{item.title}</p>
              <ul className="list-none space-y-2 mt-2">
                {item.description.map((point, idx) => (
                  <li key={idx} className="flex items-start">
                    <span className="text-green-600 font-bold mr-2">✔</span>
                    <p className="text-bodyr text-[#595959]">{point}</p>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      ),
    },
  ]

  return (
    <div className="w-full bg-white">
      {/* Header Section */}
      <SkillHeader
        title={skillHeaderContent.title}
        subtitle={skillHeaderContent.subtitle}
        description={skillHeaderContent.description}
        bannerImage={skillHeaderContent.bannerImage}
      />

      {/* Test Format Section */}
      <SkillTestFormat tabs={skillTestTabs} />

      {/* Call-to-Action Section */}
      <SkillCTASection />
    </div>
  )
}

export default AcademicWritingPage
