import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import { ReactComponent as PreviousArrowSvg } from '../../images/WeeklyPrediction/weekly-prediction-previous-arrow.svg'
import { ReactComponent as NextArrowSvg } from '../../images/WeeklyPrediction/weekly-prediction-next-arrow.svg'
import TryAgainArrowSvg from '../../images/Speaking/try-again-arrow.svg'
import { ReactComponent as ReturnArrowSvg } from '../../images/WeeklyPrediction/weekly-preidction-return.svg'
import ProgressBar from '../ProgressBar'
import LoadingMessage from '../Items/LoadingMessage'
import ErrorMessage from '../Items/ErrorMessage'
import { useAuth } from '../../providers/AuthProvider'
import {
  practiceNowWithFilter,
  updateAttempt,
  getAllMockQuestions,
} from '../../services/practice.services'
import {
  handleExceptionError,
  setupSpeechRecognition,
  toastError,
} from '../../utils/utils'
import PracticeMockQuestion from '../../models/practiceMockQuestion.model'
import SpeakingQuestionStates from './SpeakingQuestionStates'
import MockQuestion from '../../models/mockQuestion.model'
import { useNavigate } from 'react-router-dom'
import Path from '../../routes/Path'
import { Link } from 'react-router-dom'
import { QuestionModelId } from '../../models/QuestionModels'

type AnswerShortQuestionProps = {
  setShowScoreBoard: (value: boolean) => void
  showScoreBoard: boolean
}
//qQuality = 0, qType = 0
const AnswerShortQuestion: React.FC<AnswerShortQuestionProps> = ({
  setShowScoreBoard,
  showScoreBoard,
}) => {
  const { userProfile } = useAuth()
  const navigate = useNavigate()
  const maxRecordingTime = 10
  const recordingTimeout = 5

  const [initialDelay, setInitialDelay] = useState<null | number>(
    recordingTimeout,
  )
  const [isRecording, setIsRecording] = useState(false)
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null)
  const [recordingTime, setRecordingTime] = useState(maxRecordingTime)
  const [recordingProgress, setRecordingProgress] = useState(0)
  const audioRef = useRef<HTMLAudioElement>(null)
  const recorderRef = useRef<MediaRecorder | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [errorMsg, setErrorMsg] = useState('')
  const [question, setQuestion] = useState<PracticeMockQuestion | undefined>(
    undefined,
  )
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [transcript, setTranscript] = useState<string>('')
  const [realAnswer, setRealAnswer] = useState<string>('')
  const [alternativeAnswer, setAlternativeAnswer] = useState<string>('')
  const [audioPlayError, setAudioPlayError] = useState(false)
  const [allMockQuestions, setAllMockQuestions] = useState<MockQuestion[]>([])

  useEffect(() => {
    setTimeout(async () => {
      const questionId = localStorage.getItem('questionIdPTE')
      const questionType = localStorage.getItem('questionTypePTE')
      const questionLabel = localStorage.getItem('ptePracticeQuestionLabel')

      if (
        userProfile &&
        userProfile.userId &&
        !isNaN(Number(questionId)) &&
        !isNaN(Number(questionType)) &&
        !isNaN(Number(questionLabel)) &&
        questionType === QuestionModelId.S_AnswerShortQuestion
      ) {
        try {
          const response = await practiceNowWithFilter(
            userProfile.userId,
            Number(questionType),
            Number(questionId),
            0,
            0,
            0,
            Number(questionLabel),
            4,
          )
          if (response.data.success) {
            const mockQuestions = response.data.mockQuestion
            if (Array.isArray(mockQuestions) && mockQuestions.length > 0) {
              setQuestion(mockQuestions[0])

              const allMockQuestionsData = await getAllMockQuestions(
                userProfile.userId,
                Number(questionType),
                Number(questionId),
                0,
                0,
                0,
                Number(questionLabel),
                4,
              )
              if (allMockQuestionsData.data.success) {
                setAllMockQuestions(allMockQuestionsData.data.allMockQuestion)
              } else {
                console.error('Failed to fetch all mock questions.')
              }

              await updateAttempt(
                userProfile.userId,
                Number(questionType),
                Number(questionId),
                0,
                0,
                0,
                Number(questionLabel),
                4,
              )
            } else {
              console.error('No questions found in the response')
              setErrorMsg('No valid question data found in the response!')
            }
            setRealAnswer(response.data.pv)
            setAlternativeAnswer(response.data.alt_pv)
          }
        } catch (error) {
          handleExceptionError(error)
          setErrorMsg('Error while getting the question data!!!')
        }
      } else {
        setErrorMsg('Error while getting the question data!!!')
      }
      setIsLoading(false)
    }, 1000)
  }, [userProfile])

  // Use useMemo to initialize SpeechRecognition
  const recognition = useMemo(() => setupSpeechRecognition(), [])
  if (recognition) {
    recognition.onresult = (event: any) => {
      let interimTranscript = ''
      let finalTranscript = ''

      for (let i = event.resultIndex; i < event.results.length; i++) {
        const result = event.results[i]
        if (result.isFinal) {
          finalTranscript += result[0].transcript // Final result
        } else {
          interimTranscript += result[0].transcript // Partial result
        }
      }

      // Combine final and interim transcripts
      const fullTranscript = finalTranscript + interimTranscript
      setTranscript(fullTranscript)
    }

    recognition.onerror = (event: any) => {
      console.error('Speech recognition error:', event.error)
    }
  }

  const startRecording = () => {
    if (!isRecording && !audioBlob) {
      setIsRecording(true)
      setRecordingTime(10)
      setRecordingProgress(0)
      setTranscript('')

      const beep = new Audio('/sound-beep.mp3')
      beep.play().catch((error) => {
        console.log('Beep sound play error:', error)
      })

      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          const newRecorder = new MediaRecorder(stream)
          recorderRef.current = newRecorder
          const chunks: BlobPart[] = []

          newRecorder.ondataavailable = (event) => chunks.push(event.data)
          newRecorder.onstop = () => {
            setAudioBlob(new Blob(chunks, { type: 'audio/wav' }))
            setRecordingProgress(100)
          }
          newRecorder.start()

          if (recognition) {
            recognition.start()
          }
        })
        .catch((error) => {
          console.error('Error accessing microphone:', error)
        })
    }
  }

  const stopRecording = useCallback(() => {
    if (recorderRef.current) {
      recorderRef.current.stop()
      setIsRecording(false)
      setRecordingTime(10)
      setRecordingProgress(100)
      setIsSubmitted(true)
    }

    // Stop speech recognition
    if (recognition) {
      recognition.stop()
    }
  }, [recognition])

  const resetState = () => {
    setIsSubmitted(false)
    setInitialDelay(5)
    setIsRecording(false)
    setAudioBlob(null)
    setRecordingTime(10)
    setRecordingProgress(0)
    setTranscript('')
    setShowScoreBoard(false)
    setAudioPlayError(false)
    audioRef.current && (audioRef.current.src = question?.audio || '')
  }

  useEffect(() => {
    let intervalId: NodeJS.Timeout
    if (isRecording) {
      intervalId = setInterval(() => {
        setRecordingTime((prevTimer) => {
          let newTime = prevTimer - 1
          setRecordingProgress(((10 - newTime) / 10) * 100)
          if (newTime > 0) {
            return newTime
          } else {
            clearInterval(intervalId)
            stopRecording()
            return 0
          }
        })
      }, 1000)
    }
    return () => clearInterval(intervalId)
  }, [isRecording, stopRecording])

  useEffect(() => {
    let timerId: NodeJS.Timeout
    if (question) {
      if (initialDelay && initialDelay > 0) {
        timerId = setTimeout(() => setInitialDelay(initialDelay - 1), 1000)
      } else if (initialDelay === 0) {
        audioRef.current?.play().catch(() => {
          setAudioPlayError(true) // Set error state if play fails
        })
        setInitialDelay(null)
      }
      return () => clearTimeout(timerId)
    }
  }, [initialDelay, question])

  const isCorrectAnswer = (
    originalAnswer: string,
    alternativeRealAnswer: string,
    transcript: string,
  ): boolean => {
    // Helper function to normalize and split text into an array of words
    const normalizeTextToWords = (text: string): string[] =>
      text
        .trim()
        .toLowerCase()
        .replace(/[^\w\s]/g, '') // Remove punctuation
        .split(/\s+/) // Split by whitespace

    const originalWords = normalizeTextToWords(originalAnswer)
    const alternativeWords = alternativeRealAnswer.trim()
      ? normalizeTextToWords(alternativeRealAnswer)
      : []
    const transcriptWords = normalizeTextToWords(transcript)

    // Function to check if any word in the transcript matches a set of words
    const hasMatch = (words: string[]): boolean =>
      transcriptWords.some((word) => words.includes(word))

    // Check matches against both original and alternative answers
    return hasMatch(originalWords) || hasMatch(alternativeWords)
  }

  const handlePlayAudio = () => {
    audioRef.current
      ?.play()
      .then(() => {
        setAudioPlayError(false) // Reset error state if audio plays successfully
      })
      .catch(() => {
        setAudioPlayError(true) // Set error state if play fails
      })
  }

  const handleQuestionChange = (selectedQuestionId: string) => {
    localStorage.setItem('questionIdPTE', selectedQuestionId)
    navigate(0)
  }

  const isFirstQuestion = (): boolean => {
    const position = allMockQuestions.findIndex(
      (mockQuestion) => mockQuestion.id === question?.id,
    )
    if (allMockQuestions.length === 0 || position === -1 || position !== 0) {
      return false
    } else {
      return true
    }
  }

  const isLastQuestion = (): boolean => {
    const position = allMockQuestions.findIndex(
      (mockQuestion) => mockQuestion.id === question?.id,
    )
    if (
      allMockQuestions.length === 0 ||
      position === -1 ||
      position + 1 !== allMockQuestions.length
    ) {
      return false
    } else {
      return true
    }
  }

  const displayPreviousQuestion = () => {
    if (allMockQuestions.length === 0) {
      toastError('No questions in the list!')
    }

    const position = allMockQuestions.findIndex(
      (mockQuestion) => mockQuestion.id === question?.id,
    )

    if (position === -1) {
      toastError('Can not find the current question in the list!')
    } else if (position === 0) {
      toastError('This question is the first question!')
    } else {
      const previousQuestionId = allMockQuestions[position - 1].id
      localStorage.setItem('questionIdPTE', String(previousQuestionId))
      navigate(0)
    }
  }

  const displayNextQuestion = () => {
    if (allMockQuestions.length === 0) {
      toastError('No questions in the list!')
    }

    const position = allMockQuestions.findIndex(
      (mockQuestion) => mockQuestion.id === question?.id,
    )

    if (position === -1) {
      toastError('Can not find the current question in the list!')
    } else if (position + 1 === allMockQuestions.length) {
      toastError('This question is the last question!')
    } else {
      const nextQuestionId = allMockQuestions[position + 1].id
      localStorage.setItem('questionIdPTE', String(nextQuestionId))
      navigate(0)
    }
  }

  return (
    <div className="min-h-[80vh]">
      {isLoading ? (
        <LoadingMessage message="Loading question..." />
      ) : errorMsg ? (
        <ErrorMessage message={errorMsg} />
      ) : (
        <>
          <p className="text-bodyr my-2 text-neutrals-2">
            <Link to={Path.weeklyPrediction.path}>Practice</Link> /{' '}
            <Link to={Path.weeklyPredictionCourse.path}>Speaking Section</Link>{' '}
            / <span className="text-neutrals-1">Answer Short Question</span>
          </p>
          <Link to={Path.weeklyPredictionCourse.path}>
            <button className="py-2 px-4 flex items-center gap-2 my-6 bg-info rounded-lg">
              <ReturnArrowSvg fill="white" className="mr-2" />
              <span className="text-bodyr text-white">Return</span>
            </button>
          </Link>
          <p className="text-bodym text-danger mt-2 md:mt-8">
            * This question carries marks for Speaking (~3%) and Listening (
            ~7%){' '}
          </p>

          <div className="p-8 rounded-xl shadow mt-8">
            <p className="text-h4m text-neutrals-1">{question?.short_title}</p>

            {!isRecording && !audioBlob && (
              <div className="bg-[#F1EFF2] text-center p-4 rounded-xl lg:w-[50%] w-full mx-auto mt-4">
                {initialDelay ? (
                  <p className="text-h4m text-neutrals-1">
                    Audio will start in: {initialDelay} seconds
                  </p>
                ) : audioPlayError ? (
                  <p className="text-red-600 text-footnote w-full text-center">
                    Oops! It seems the audio didn't start automatically. No
                    worries—just click{' '}
                    <button
                      onClick={handlePlayAudio}
                      className="underline text-blue-600 bg-yellow-200 px-2 rounded-lg"
                    >
                      Here
                    </button>{' '}
                    to play it manually!
                  </p>
                ) : (
                  <p className="text-h4m text-neutrals-1">Original Audio</p>
                )}

                <audio
                  ref={audioRef}
                  src={question && question.audio ? question.audio : ''}
                  preload="auto"
                  controls
                  className="my-4 w-full"
                ></audio>

                <button
                  onClick={startRecording}
                  className="mx-auto flex items-center px-2 md:px-4 py-2 bg-white rounded-xl cursor-pointer hover:scale-105"
                >
                  <p className="text-bodyr text-neutrals-1">Record Now</p>
                </button>
              </div>
            )}

            {isRecording && (
              <div className="bg-[#F1EFF2] text-center p-4 rounded-xl w-[50%] mx-auto mt-4">
                <p className="text-h5r mt-4">Timer: {recordingTime} seconds</p>
                <ProgressBar progress={recordingProgress} />
                <button
                  onClick={stopRecording}
                  className="text-bodyr text-neutrals-1 p-2 mt-2 rounded-lg border border-[1px]-[#D9D9D9] hover:scale-105"
                >
                  Stop Recording
                </button>
              </div>
            )}

            <SpeakingQuestionStates question={question} />
          </div>

          <div className="flex w-full xl:w-[50%] justify-center gap-4 mx-auto mt-4">
            <button
              className={`flex items-center px-4 py-2 rounded-xl ${isFirstQuestion() ? 'bg-[#F0F0F0] text-gray-400 cursor-not-allowed' : 'text-white bg-info cursor-pointer'}`}
              disabled={isFirstQuestion()}
              onClick={displayPreviousQuestion}
            >
              <PreviousArrowSvg
                fill={isFirstQuestion() ? 'gray' : 'white'}
                className="md:mr-2"
              />
              <p className="hidden md:block text-bodyr">Previous</p>
            </button>
            <div className="flex text-white">
              <button
                className="flex items-center px-4 py-2 bg-info rounded-xl cursor-pointer"
                onClick={resetState}
              >
                <p className="hidden md:block text-bodyr text-white">
                  Try Again
                </p>
                <img src={TryAgainArrowSvg} alt="again" className="md:ml-2" />
              </button>
            </div>

            <div>
              <select
                className="px-2 md:px-4 py-2 rounded-xl border border-[1px]-[#D9D9D9] overflow-y"
                defaultValue={question?.id}
                onChange={(e) => handleQuestionChange(e.target.value)}
              >
                {allMockQuestions.map((mockQuestion, index) => (
                  <option
                    key={mockQuestion.id}
                    value={mockQuestion.id}
                    className="text-bodyr"
                  >
                    {index + 1}
                  </option>
                ))}
              </select>
            </div>

            <button
              className={`flex items-center px-4 py-2 rounded-xl ${isLastQuestion() ? 'bg-[#F0F0F0] text-gray-400 cursor-not-allowed' : 'bg-info text-white cursor-pointer'}`}
              onClick={displayNextQuestion}
              disabled={isLastQuestion()}
            >
              <p className="hidden md:block text-bodyr">Next</p>
              <NextArrowSvg
                fill={isLastQuestion() ? 'gray' : 'white'}
                className="md:ml-2"
              />
            </button>
          </div>
          {isSubmitted && (
            <div className="grid grid-row-2 lg:grid-cols-2 gap-4 mt-4">
              <div className="col-span-1 bg-[#F1EFF2] p-6 rounded-xl text-center">
                <p className="text-h4m">Original Answer</p>
                <p className="text-bodyr">{question?.description || ''}</p>
              </div>
              <div className="col-span-1 bg-[#F1EFF2] p-6 rounded-xl text-center">
                <p className="text-h4m">Your Answer</p>
                {audioBlob && (
                  <>
                    <audio
                      controls
                      src={URL.createObjectURL(audioBlob)}
                      className="my-4 w-full"
                    />
                    {isCorrectAnswer(
                      realAnswer,
                      alternativeAnswer,
                      transcript,
                    ) ? (
                      <p className="text-bodyr font-bold text-success">
                        Your answer is correct!
                      </p>
                    ) : (
                      <p className="text-bodyr font-bold text-danger">
                        Your answer is incorrect!
                      </p>
                    )}
                    <p className="text-bodyr">Your transcript: {transcript}</p>
                  </>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default AnswerShortQuestion
