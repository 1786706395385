import React, { useState, useEffect, useRef, ChangeEvent } from 'react'
import WeeklyPredictionSubmitIcon from '../../images/WeeklyPrediction/weekly-prediction-submit.svg'
import WeeklyPredictionKeywordIcon from '../../images/WeeklyPrediction/weekly-prediction-keyword.svg'
import { ReactComponent as PreviousArrowSvg } from '../../images/WeeklyPrediction/weekly-prediction-previous-arrow.svg'
import { ReactComponent as NextArrowSvg } from '../../images/WeeklyPrediction/weekly-prediction-next-arrow.svg'
import TryAgainArrowSvg from '../../images/Speaking/try-again-arrow.svg'
import { ReactComponent as ReturnArrowSvg } from '../../images/WeeklyPrediction/weekly-preidction-return.svg'
import AudioListeningCourse from './AudioListeningCourse'
import DiffMatchPatch from 'diff-match-patch'
import LoadingMessage from '../Items/LoadingMessage'
import ErrorMessage from '../Items/ErrorMessage'
import { useAuth } from '../../providers/AuthProvider'
import {
  practiceNowWithFilter,
  updateAttempt,
  getAllMockQuestions,
} from '../../services/practice.services'
import { handleExceptionError, toastError } from '../../utils/utils'
import PracticeMockQuestion from '../../models/practiceMockQuestion.model'
import MockQuestion from '../../models/mockQuestion.model'
import ListeningQuestionStates from './ListeningQuestionStates'
import { displaySkillProgressColor } from '../../utils/utils'
import Path from '../../routes/Path'
import { Link } from 'react-router-dom'
import { QuestionModelId } from '../../models/QuestionModels'

const WriteFromDictationQuestion = () => {
  const { userProfile } = useAuth()

  const [questionId, setQuestionId] = useState<string | null>(
    localStorage.getItem('questionIdPTE'),
  )
  const [question, setQuestion] = useState<PracticeMockQuestion | undefined>(
    undefined,
  )
  const [errorMsg, setErrorMsg] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [allMockQuestions, setAllMockQuestions] = useState<MockQuestion[]>([])
  const answerSectionRef = useRef<HTMLDivElement>(null)
  const questionSectionRef = useRef<HTMLDivElement>(null)
  const audioRef = useRef<{ resetAudio: () => void }>(null)
  const [wordsCount, setWordsCount] = useState(0)
  const [userAnswer, setUserAnswer] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [finalListeningScore, setFinalListeningScore] = useState(10)
  const [finalWritingScore, setFinalWritingScore] = useState(10)

  useEffect(() => {
    setIsSubmitted(false)
    setIsLoading(true)
    setTimeout(async () => {
      const questionType = localStorage.getItem('questionTypePTE')
      const questionLabel = localStorage.getItem('ptePracticeQuestionLabel')

      if (
        userProfile &&
        userProfile.userId &&
        !isNaN(Number(questionId)) &&
        !isNaN(Number(questionType)) &&
        !isNaN(Number(questionLabel)) &&
        questionType === QuestionModelId.L_WriteFromDictation
      ) {
        try {
          const response = await practiceNowWithFilter(
            userProfile.userId,
            Number(questionType),
            Number(questionId),
            0,
            0,
            0,
            Number(questionLabel),
            4,
          )
          if (response.data.success) {
            const mockQuestions = response.data.mockQuestion
            if (Array.isArray(mockQuestions) && mockQuestions.length > 0) {
              setQuestion(mockQuestions[0])
              setUserAnswer('')
              setWordsCount(0)
              const allMockQuestionsData = await getAllMockQuestions(
                userProfile.userId,
                Number(questionType),
                Number(questionId),
                0,
                0,
                0,
                Number(questionLabel),
                4,
              )
              if (allMockQuestionsData.data.success) {
                setAllMockQuestions(allMockQuestionsData.data.allMockQuestion)
              } else {
                console.error('Failed to fetch all mock questions.')
              }

              await updateAttempt(
                userProfile.userId,
                Number(questionType),
                Number(questionId),
                0,
                0,
                0,
                Number(questionLabel),
                4,
              )
            } else {
              console.error('No questions found in the response')
              setErrorMsg('No valid question data found in the response!')
            }
          }
        } catch (error) {
          handleExceptionError(error)
          setErrorMsg('Error while getting the question data!!!')
        }
      } else {
        setErrorMsg('Error while getting the question data!!!')
      }
      setIsLoading(false)
    }, 1000)
  }, [questionId, userProfile])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [questionId])

  const finalScore = [
    {
      title: 'Listening',
      mark: finalListeningScore,
    },
    {
      title: 'Writing',
      mark: finalWritingScore,
    },
  ]

  const handleTextChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    const text = e.target.value
    const wordCount = text.trim().split(/\s+/).filter(Boolean).length
    setWordsCount(wordCount)
    const formattedText = text.trim().replace(/[^a-zA-Z0-9 ]/g, '')
    setUserAnswer(formattedText)
  }

  const resetState = () => {
    setWordsCount(0)
    setUserAnswer('')
    setIsSubmitted(false)

    if (audioRef.current) {
      audioRef.current.resetAudio()
    }

    if (questionSectionRef.current) {
      questionSectionRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
  }

  const checkSimilarText = (newText: string, oldText: string): number => {
    let count = 0
    const oldArray: string[] = oldText.split(' ')
    const oldArrayCount: number = oldArray.length
    const newArray: string[] = newText.split(' ')
    const newArrayCount: number = newArray.length
    for (let i = 0; i < oldArrayCount; i++) {
      for (let j = 0; j < newArrayCount; j++) {
        if (oldArray[i] === newArray[j]) {
          count++
          break
        }
      }
    }
    const finalCount: number = (count * 100) / oldArrayCount
    return finalCount
  }

  const handleSubmitAnswer = () => {
    setIsSubmitted(true)
    if (question) {
      const formattedUserAnswer = userAnswer
        .trim()
        .toLowerCase()
        .replace(/[^a-zA-Z0-9 ]/g, '')
      const originalAnswerString = question.description
        .trim()
        .toLowerCase()
        .replace(/[^a-zA-Z0-9 ]/g, '')

      const dmp = new DiffMatchPatch()
      const diffs = dmp.diff_main(originalAnswerString, formattedUserAnswer)

      let r = 0
      let u = 0
      let f = 0

      for (let i = 0; i < diffs.length; i++) {
        diffs[i][0] === 0
          ? (u += diffs[i][1].length)
          : diffs[i][0] === -1
            ? (r += diffs[i][1].length)
            : (f += diffs[i][1].length)
      }

      const d = (u * 100) / (r + u + f)
      const listeningPercentage = parseFloat(d.toString()).toFixed(2)
      const listeningFinal = Math.round(
        (90 * parseFloat(listeningPercentage)) / 100,
      )
      setFinalListeningScore(listeningFinal)

      const writingPercentage = checkSimilarText(
        formattedUserAnswer,
        originalAnswerString,
      )
      const writingFinal = Math.round((90 * writingPercentage) / 100)
      setFinalWritingScore(writingFinal)
    } else {
      console.error('Question is missing!')
    }
  }

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  const handleQuestionChange = (selectedQuestionId: string) => {
    localStorage.setItem('questionIdPTE', selectedQuestionId)
    setQuestionId(selectedQuestionId)
  }

  const isFirstQuestion = (): boolean => {
    const position = allMockQuestions.findIndex(
      (mockQuestion) => mockQuestion.id === question?.id,
    )
    if (allMockQuestions.length === 0 || position === -1 || position !== 0) {
      return false
    } else {
      return true
    }
  }

  const isLastQuestion = (): boolean => {
    const position = allMockQuestions.findIndex(
      (mockQuestion) => mockQuestion.id === question?.id,
    )
    if (
      allMockQuestions.length === 0 ||
      position === -1 ||
      position + 1 !== allMockQuestions.length
    ) {
      return false
    } else {
      return true
    }
  }

  const displayPreviousQuestion = () => {
    if (allMockQuestions.length === 0) {
      toastError('No questions in the list!')
    }

    const position = allMockQuestions.findIndex(
      (mockQuestion) => mockQuestion.id === question?.id,
    )

    if (position === -1) {
      toastError('Can not find the current question in the list!')
    } else if (position === 0) {
      toastError('This question is the first question!')
    } else {
      const previousQuestionId = allMockQuestions[position - 1].id
      localStorage.setItem('questionIdPTE', String(previousQuestionId))
      setQuestionId(String(previousQuestionId))
    }
  }

  const displayNextQuestion = () => {
    if (allMockQuestions.length === 0) {
      toastError('No questions in the list!')
    }

    const position = allMockQuestions.findIndex(
      (mockQuestion) => mockQuestion.id === question?.id,
    )

    if (position === -1) {
      toastError('Can not find the current question in the list!')
    } else if (position + 1 === allMockQuestions.length) {
      toastError('This question is the last question!')
    } else {
      const nextQuestionId = allMockQuestions[position + 1].id
      localStorage.setItem('questionIdPTE', String(nextQuestionId))
      setQuestionId(String(nextQuestionId))
    }
  }

  useEffect(() => {
    if (isSubmitted && answerSectionRef.current) {
      answerSectionRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
  }, [isSubmitted])

  return (
    <div className="min-h-[80vh]">
      {isLoading ? (
        <LoadingMessage message="Loading question..." />
      ) : errorMsg ? (
        <ErrorMessage message={errorMsg} />
      ) : (
        <>
          <p className="text-bodyr my-2 text-neutrals-2">
            <Link to={Path.weeklyPrediction.path}>Practice</Link> /{' '}
            <Link to={Path.weeklyPredictionCourse.path}>Listening Section</Link>{' '}
            / <span className="text-neutrals-1">Write From Dictation</span>
          </p>
          <Link to={Path.weeklyPredictionCourse.path}>
            <button className="py-2 px-4 flex items-center gap-2 my-6 bg-info rounded-lg">
              <ReturnArrowSvg fill="white" className="mr-2" />
              <span className="text-bodyr text-white">Return</span>
            </button>
          </Link>
          <p className="text-bodym text-danger mt-2">
            * This question carries marks for Writing (~30%) and Listening
            (~27%)
          </p>
          <div className="p-8 rounded-xl shadow mt-8" ref={questionSectionRef}>
            <p className="text-h4m text-neutrals-1 mb-[2rem]">
              {question?.short_title}
            </p>
            <AudioListeningCourse
              ref={audioRef}
              audioUrl={question && question ? question.audio : ''}
            />
            <div className="flex flex-col gap-2 mb-4">
              <label htmlFor="swt-answer" className="text-h5b">
                Your answer
              </label>
              <textarea
                className="w-full h-[5rem] text-bodyr border border-1 border-[#D9D9D9] p-2 resize-none"
                placeholder="Write here..."
                onChange={handleTextChange}
                value={userAnswer}
                disabled={isSubmitted}
              />
            </div>
            <p className="text-body mb-4">Words count: {wordsCount}</p>
            <ListeningQuestionStates question={question} />
            <div className="w-[90%] lg:w-[60%] mb-4 mx-auto">
              <div className="flex place-content-center mt-8">
                <button
                  className={`flex text-bodyr text-white items-center py-2 px-6 rounded-lg mr-2 
                      ${wordsCount === 0 || isSubmitted ? 'bg-gray-400 cursor-not-allowed' : 'bg-success'}`}
                  onClick={handleSubmitAnswer}
                  disabled={wordsCount === 0 || isSubmitted}
                >
                  <img
                    src={WeeklyPredictionSubmitIcon}
                    alt="submit"
                    className="mr-2"
                  />
                  Submit
                </button>

                <button
                  className="flex text-bodyr text-neutrals-2 items-center p-2 border border-1px-neutrals-2 rounded-lg"
                  onClick={toggleModal}
                >
                  <img
                    src={WeeklyPredictionKeywordIcon}
                    alt="keyword"
                    className="mr-2"
                  />
                  Reveal Answer
                </button>
              </div>
            </div>
          </div>

          <div className="flex w-full xl:w-[50%] justify-center gap-4 mx-auto mt-4">
            <button
              className={`flex items-center px-4 py-2 rounded-xl ${isFirstQuestion() ? 'bg-[#F0F0F0] text-gray-400 cursor-not-allowed' : 'bg-info text-white cursor-pointer'}`}
              disabled={isFirstQuestion()}
              onClick={displayPreviousQuestion}
            >
              <PreviousArrowSvg
                fill={isFirstQuestion() ? 'gray' : 'white'}
                className="md:mr-2"
              />
              <p className="hidden md:block text-bodyr">Previous</p>
            </button>
            <div className="flex text-white">
              <button
                className="flex items-center px-4 py-2 bg-info rounded-xl cursor-pointer"
                onClick={resetState}
              >
                <p className="hidden md:block text-bodyr text-white">
                  Try Again
                </p>
                <img src={TryAgainArrowSvg} alt="again" className="md:ml-2" />
              </button>
            </div>

            <div>
              <select
                className="px-2 md:px-4 py-2 rounded-xl border border-[1px]-[#D9D9D9] overflow-y"
                defaultValue={question?.id}
                onChange={(e) => handleQuestionChange(e.target.value)}
              >
                {allMockQuestions.map((mockQuestion, index) => (
                  <option
                    key={mockQuestion.id}
                    value={mockQuestion.id}
                    className="text-bodyr"
                  >
                    {index + 1}
                  </option>
                ))}
              </select>
            </div>

            <button
              className={`flex items-center px-4 py-2 rounded-xl ${isLastQuestion() ? 'bg-[#F0F0F0] text-gray-400 cursor-not-allowed' : 'bg-info text-white cursor-pointer'}`}
              onClick={displayNextQuestion}
              disabled={isLastQuestion()}
            >
              <p className="hidden md:block text-bodyr">Next</p>
              <NextArrowSvg
                fill={isLastQuestion() ? 'gray' : 'white'}
                className="md:ml-2"
              />
            </button>
          </div>

          {isModalOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
              <div className="bg-white p-6 rounded-lg shadow-lg w-[90%] md:w-[50%]">
                <h2 className="text-h3m mb-4">Reveal Answer</h2>
                <p className="text-bodyr font-bold text-green-500">
                  {question?.description}
                </p>
                <button
                  className="mt-4 px-4 py-2 bg-danger text-white rounded-lg"
                  onClick={toggleModal}
                >
                  Close
                </button>
              </div>
            </div>
          )}

          {isSubmitted && (
            <div ref={answerSectionRef}>
              {
                <>
                  <div className="bg-gray-100 rounded-xl py-4 grid grid-cols-2 gap-6 mt-4 place-items-center">
                    {finalScore.map((score, index) => (
                      <div key={index} className="flex flex-col items-center">
                        <p className="text-center text-gray-600 font-medium mb-2">
                          {score.title}
                        </p>
                        <div
                          className="flex flex-col items-center justify-center rounded-full text-white w-[4rem] h-[4rem] shadow-md"
                          style={{
                            backgroundColor: displaySkillProgressColor(
                              score.mark,
                            ),
                          }}
                        >
                          <p className="text-h5b">{score.mark}/90</p>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="grid grid-row-2 lg:grid-cols-2 gap-4 mt-4">
                    <div className="col-span-1 bg-[#F1EFF2] p-6 rounded-xl">
                      <p className="text-h4m text-center">Original Answer</p>
                      <audio
                        controls
                        src={question?.audio || ''}
                        className="my-4 w-full"
                      />
                      <p>
                        <span className="font-bold">Transcripted audio: </span>
                        <span className="text-bodyr mt-4">
                          {question?.description}
                        </span>
                      </p>
                    </div>
                    <div className="col-span-1 bg-[#F1EFF2] p-6 rounded-xl">
                      <p className="text-h4m text-center">Your answer</p>
                      <p className="text-bodyr mt-4">{userAnswer}</p>
                    </div>
                  </div>
                </>
              }
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default WriteFromDictationQuestion
