import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import WeeklyPredictionSubmitIcon from '../../images/WeeklyPrediction/weekly-prediction-submit.svg'
import PreviousArrowSvg from '../../images/Speaking/previous-arrow.svg'
import NextArrowSvg from '../../images/Speaking/next-arrow.svg'
import TryAgainArrowSvg from '../../images/Speaking/try-again-arrow.svg'
import ReadAloudSaveAsSvg from '../../images/Speaking/read-aloud-save-as.svg'
import ReadAloudEditSvg from '../../images/Speaking/read-aloud-edit.svg'
import ReadAloudMarkSvg from '../../images/Speaking/read-aloud-mark.svg'
import AudioSectional from '../SectionalTest/AudioSectional'

const question = {
  audio:
    'https://ptefilesbucket-stg.s3.amazonaws.com/test_question/0/5c49c0d7e0d2b.mp3',
  category_id: '20',
  created_at: '2023-04-02 17:50:29',
  description: '',
  dynamic_column_count: '0',
  frequency: '20292',
  highly_repeated: '0',
  id: '2610',
  image: '',
  material_type: '1',
  my_attampt_sort: '0',
  question: '',
  question_quality_sort: '0',
  question_type_sort: '0',
  random_order: '0',
  sequence_category_id: '17',
  short_title:
    '(Telecommuting)You will hear an audio. Click on the paragraph that best relates to the recording.',
  test_id: '0',
}

const options = [
  {
    alt_option: '',
    correct: '0',
    id: '12864',
    options:
      'Company leaders have to be careful that they do not have one set of practices for those in the office and another for those who telecommute. Besides needing to be fair at all times, managers will find that a telecommuting arrangement will simply not work if workers feel isolated and excluded from the company culture.',
    sequence: '0',
  },
  {
    alt_option: '',
    correct: '1',
    id: '12865',
    options:
      'Telecommuting has a lot of advantages but to make it work. company leaders need to plan in advance to ensure that they anticipate issues for example training, security and communication. They also need to ensure that they hire workers who are suited to working remotely and ensure equal access to resources and advancement.',
    sequence: '0',
  },
  {
    alt_option: '',
    correct: '0',
    id: '12866',
    options:
      'When workers ask if they can work from home, companies should consider a telecommuting arrangement. as it has several advantages for businesses as well as workers. There is money to be saved on overheads and training but for telecommuting to function properly, only independent staff should be allowed to work in this way.',
    sequence: '0',
  },
  {
    alt_option: '',
    correct: '0',
    id: '12867',
    options:
      "Managers should not rush into letting their employees telecommute. It may sound good because businesses can save money on things like office space, but if workers' needs and ambitions are not well catered for in the arrangement, the company culture will ultimately be damaged and they may even be sued.",
    sequence: '0',
  },
]

const HighlightCorrectSummaryQuestion = () => {
  const [isSubmitted, setIsSubmitted] = useState(false)
  const navigate = useNavigate()

  const handleReload = () => {
    navigate(0) // this reloads the current route
  }

  const handleToggleSubmitted = () => {
    setIsSubmitted((prevState) => !prevState)
  }

  return (
    <div>
      <p className="text-bodym text-danger mt-2">
        * This question carries marks for Listening (~2%) and Reading (~2%)
      </p>
      <div className="p-8 rounded-xl shadow mt-8">
        <p className="text-h4m text-neutrals-1 mb-[2rem]">
          {question.short_title}
        </p>
        <AudioSectional audioUrl={question.audio} />
        <div className="mx-auto mb-6 w-full px-[2rem] w-80">
          <div className="flex flex-col gap-2 mt-2">
            {options.map((option, index) => (
              <div
                key={option.id}
                className="options__item flex items-center gap-4"
              >
                <input
                  data-testid={`option-${index}`}
                  type="radio"
                  id={option.id}
                  name="hcm_options"
                  value={option.id}
                  className="accent-primary-6 w-4 h-4 flex-shrink-0"
                />
                <label
                  htmlFor={option.id}
                  className={`flex-1 text-bodyr ${option.correct === '1' && isSubmitted ? 'bg-green-400' : ''}`}
                >
                  {option.options}
                </label>
              </div>
            ))}
          </div>
        </div>
        <div className="flex items-center mb-4">
          <p className="text-bodym text-neutrals-1">My note:</p>
          <img
            src={ReadAloudSaveAsSvg}
            alt="save as"
            className="ml-4 hover:scale-105 cursor-pointer"
          />
          <img
            src={ReadAloudEditSvg}
            alt="edit"
            className="ml-4 hover:scale-105 cursor-pointer"
          />
          <img
            src={ReadAloudMarkSvg}
            alt="mark"
            className="ml-4 hover:scale-105 cursor-pointer"
          />
        </div>
        <div className="w-[90%] lg:w-[60%] mb-4 mx-auto">
          <div className="flex place-content-center mt-8">
            <button
              className="flex text-bodyr text-white items-center py-2 px-6 bg-success rounded-lg mr-2"
              onClick={handleToggleSubmitted}
            >
              <img
                src={WeeklyPredictionSubmitIcon}
                alt="submit"
                className="mr-2"
              />
              Submit
            </button>
          </div>
        </div>
      </div>

      <div className="flex w-full justify-evenly xl:w-[50%] xl:justify-between mx-auto mt-4">
        <button className="flex items-center px-4 py-2 bg-info rounded-xl cursor-pointer">
          <img src={PreviousArrowSvg} alt="previous" className="md:mr-2" />
          <p className="hidden md:block text-bodyr text-white">Previous</p>
        </button>
        <button
          className="flex items-center px-4 py-2 bg-info rounded-xl cursor-pointer"
          onClick={handleReload}
        >
          <p className="hidden md:block text-bodyr text-white">Try Again</p>
          <img src={TryAgainArrowSvg} alt="again" className="md:ml-2" />
        </button>
        <div>
          <select className="px-2 md:px-4 py-2 rounded-xl border border-[1px]-[#D9D9D9] overflow-y">
            {Array.from({ length: 20 }, (_, i) => (
              <option key={i + 1} value={i + 1}>
                {i + 1}
              </option>
            ))}
          </select>
        </div>
        <button className="flex items-center px-4 py-2 bg-info rounded-xl cursor-pointer">
          <p className="hidden md:block text-bodyr text-white">Next</p>
          <img src={NextArrowSvg} alt="next" className="md:ml-2" />
        </button>
      </div>
    </div>
  )
}

export default HighlightCorrectSummaryQuestion
