import React from 'react'
import howItWorksBanner from '../../images/Landingpage/HowItWorkspage/how-it-works-banner.png'
import { ReactComponent as CpuIcon } from '../../images/Landingpage/HowItWorkspage/how-it-works-cpu.svg'
import { ReactComponent as MonitorIcon } from '../../images/Landingpage/HowItWorkspage/how-it-works-monitor.svg'
import { ReactComponent as UsersIcon } from '../../images/Landingpage/HowItWorkspage/how-it-works-users.svg'
import testScoreReport from '../../images/Landingpage/HowItWorkspage/test-score-report.png'
import testAnalysis from '../../images/Landingpage/HowItWorkspage/test-analysis.png'
import mockTest from '../../images/Landingpage/HowItWorkspage/mock-test.png'
import practiceTest from '../../images/Landingpage/HowItWorkspage/practice-test.png'
import SwitchableSection from '../../components/SwitchableSection'
import Tabs from '../../components/Tabs/Tabs'
import TabBody from '../../components/Tabs/TabBody'
import { ReactComponent as DocumentClockIcon } from '../../images/Landingpage/HowItWorkspage/document-clock.svg'
import { ReactComponent as ClipboardCheckIcon } from '../../images/Landingpage/HowItWorkspage/clipboard-check.svg'
import { ReactComponent as FaceRecognitionIcon } from '../../images/Landingpage/HowItWorkspage/face-recognition.svg'
import { ReactComponent as AnswerValidationIcon } from '../../images/Landingpage/HowItWorkspage/text-validation.svg'
import { ReactComponent as PointerClickIcon } from '../../images/Landingpage/HowItWorkspage/pointer-click.svg'
import { ReactComponent as ArrowRightIcon } from '../../images/Landingpage/HowItWorkspage/blueArrowRight.svg'
import SkillCTASection from '../../components/LandingPage/AcademicSkill/SkillCTASection'

const HowItWorksPage: React.FC = () => {
  const iconCards = [
    {
      icon: <MonitorIcon />,
      title: 'Online English Screening Test',
      description:
        'This computer-based English test evaluates your Reading, Writing, Listening, and Speaking skills, offering feedback on Oral Fluency, Grammar, Vocabulary, and more.',
    },
    {
      icon: <UsersIcon />,
      title: 'Easy Accessibility',
      description:
        'Our system is very simple enough to prompt people to take test anywhere and they will receive their scores automatically and instantly.',
    },
    {
      icon: <CpuIcon />,
      title: 'Automated AI Score Card',
      description:
        'Our advanced AI provides quick feedback on your test performance, highlighting mistakes and correct answers to help you excel in the real PTE Exam.',
    },
  ]

  const speakingData = [
    {
      title: 'Read Aloud',
      skills: [
        { name: 'Pronunciation', score: '5/5' },
        { name: 'Fluency', score: '5/5' },
        { name: 'Content', score: '5/5' },
      ],
    },
    {
      title: 'Repeat Sentence',
      skills: [
        { name: 'Pronunciation', score: '5/5' },
        { name: 'Fluency', score: '5/5' },
        { name: 'Content', score: '5/5' },
      ],
    },
    {
      title: 'Describe Image',
      skills: [
        { name: 'Pronunciation', score: '5/5' },
        { name: 'Fluency', score: '5/5' },
        { name: 'Content', score: '5/5' },
      ],
    },
    {
      title: 'Retell Lecture',
      skills: [
        { name: 'Pronunciation', score: '5/5' },
        { name: 'Fluency', score: '5/5' },
        { name: 'Content', score: '5/5' },
      ],
    },
    {
      title: 'Answer Short Question',
      skills: [{ name: 'Answer Short Question', score: '5/5' }],
    },
  ]

  const writingData = [
    {
      title: 'Essay',
      skills: [
        { name: 'Spelling', score: '5/5' },
        { name: 'Grammar', score: '5/5' },
        { name: 'Form', score: '5/5' },
        {
          name: 'Content - Vocabs - Linguistic Range and Structure',
          score: '5/5',
        },
      ],
    },
    {
      title: 'Summarize Written Text',
      skills: [
        { name: 'Grammar', score: '5/5' },
        { name: 'Form', score: '5/5' },
        {
          name: 'Content - Vocabs - Linguistic Range and Structure',
          score: '5/5',
        },
      ],
    },
  ]

  const readingData = [
    {
      title: 'Multiple Choice',
      skills: [
        { name: 'Single Answer', score: '5/5' },
        { name: 'Multiple Answer', score: '5/5' },
      ],
    },
    {
      title: 'Fill in the Blanks',
      skills: [
        { name: 'Fill in the blanks', score: '5/5' },
        { name: 'Fill in the blanks', score: '5/5' },
      ],
    },
    {
      title: 'Reorder Paragraph',
      skills: [{ name: 'Reorder paragraph', score: '5/5' }],
    },
  ]

  const listeningData = [
    {
      title: 'Summarize Spoken Text',
      skills: [
        { name: 'Spelling', score: '5/5' },
        { name: 'Grammar', score: '5/5' },
        { name: 'Form', score: '5/5' },
        {
          name: 'Content - Vocabs - Linguistic range and Structure',
          score: '5/5',
        },
      ],
    },
    {
      title: 'Reorder Paragraph',
      skills: [{ name: 'Reorder paragraph', score: '5/5' }],
    },
    {
      title: 'Multiple Choice - Multiple Answer',
      skills: [{ name: 'Multiple Choice - Multiple Answer', score: '5/5' }],
    },
    {
      title: 'Multiple Choice - Single Answer',
      skills: [{ name: 'Multiple Choice - Single Answer', score: '5/5' }],
    },
    {
      title: 'Highlight Correct Summary',
      skills: [{ name: 'Highlight Correct Summary', score: '5/5' }],
    },
    {
      title: 'Highlight Missing Words',
      skills: [{ name: 'Highlight Missing Words', score: '5/5' }],
    },
    {
      title: 'Highlight Incorrect Words',
      skills: [{ name: 'Highlight Incorrect Words', score: '5/5' }],
    },
    {
      title: 'Dictation',
      skills: [{ name: 'Dictation', score: '5/5' }],
    },
  ]

  const analysisTabs = [
    {
      id: 'speaking',
      label: 'Speaking',
      content: <TabBody sections={speakingData} />,
    },
    {
      id: 'writing',
      label: 'Writing',
      content: <TabBody sections={writingData} />,
    },
    {
      id: 'reading',
      label: 'Reading',
      content: <TabBody sections={readingData} />,
    },
    {
      id: 'listening',
      label: 'Listening',
      content: <TabBody sections={listeningData} />,
    },
  ]

  return (
    <div className="w-full flex flex-col items-center bg-gray-50 py-12">
      {/* Top Section with Image and Button */}
      <div className="w-full lg:w-[80%] lg:px-0 px-8 flex flex-col items-center mb-[80px]">
        <div className="relative w-full h-[317px]">
          <img
            src={howItWorksBanner}
            alt="Test Mockup"
            className="w-full h-full object-cover rounded-lg"
          />
          <button className="w-[50%] h-[54px] absolute bottom-0 right-0 bg-white text-info px-6 py-2 rounded-br-lg  rounded-tl-[24px] flex items-center">
            <ArrowRightIcon className="mr-2 flex-shrink-0" />
            <span className="text-h5m">Sign up</span>
          </button>
        </div>
      </div>

      {/* How It Works Section */}
      <div className="w-full lg:w-[80%] lg:px-0 px-8 flex mb-[80px] flex-col lg:flex-row">
        <h2 className="text-h1 mb-6 w-full lg:w-[80%] lg:pr-[171px] pr-0">
          How it works
        </h2>
        <div className="flex flex-col">
          <p className="text-gray-600 text-bodyr leading-relaxed mb-8">
            At PTE Master, we prepare our mock tests taking into consideration
            the original algorithms of the PTE Tests. Many things differentiate
            PTE Academic from its immediate rivals; IELTS and TOEFL. The number
            one thing that differentiates it is the scoring system and results.
            Since the tests are totally computerized, the results are quickly
            released—usually less than five working days. This makes PTE tests
            more suitable for students who are in haste.
            <br />
            <br />
            The PTE Academic Syllabus is grouped into four (4) sections—Speaking
            & Writing, Reading, and Listening. The marks cover the four (4)
            sections and you will as well get the PTE Academic score. The
            overall score is the sum of the scores in all the sections.
          </p>
          {/* Icon Cards */}
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
            {iconCards.map((card, index) => (
              <div
                key={index}
                className="flex flex-col p-6 border rounded-lg shadow hover:shadow-lg bg-[#F5F5F5]"
              >
                <div>{card.icon}</div>
                <h3 className="text-h4m my-[16px]">{card.title}</h3>
                <p className="text-gray-600 text-bodyr leading-relaxed">
                  {card.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <SwitchableSection
        title="Scored PTE Mock Test"
        description="PTE Mock Test is a 3-hour computer-based English test which helps users to experience the real-time Pearson’s test environment. Each mock test consists of 71 questions combined with Speaking, Writing, Reading, and Listening and comes with an AI-based scorecard, coach feedback & expert answers. After finishing the exam, the user will receive a full score report with overall, communicative, and enabling skills scores."
        image={mockTest}
        imageAlt="Mock Test"
        textFlex="flex-[0.4] mt-[40px] lg:mt-0"
        imageFlex="flex-[0.4]"
        className="mb-[80px]"
      >
        <h3 className="text-xl font-bold text-black mb-4">
          Scored PTE Mock Test
        </h3>
        <ul className="list-none space-y-2 text-gray-600">
          <li className="flex items-center">
            <DocumentClockIcon className="w-5 h-5 mr-2" />
            Real-time exam experience
          </li>
          <li className="flex items-center">
            <ClipboardCheckIcon className="w-5 h-5 mr-2" />
            Instant score report
          </li>
          <li className="flex items-center">
            <FaceRecognitionIcon className="w-5 h-5 mr-2" />
            AI-generated live scorecard
          </li>
          <li className="flex items-center">
            <AnswerValidationIcon className="w-5 h-5 mr-2" />
            Sample answers
          </li>
          <li className="flex items-center">
            <PointerClickIcon className="w-5 h-5 mr-2" />
            Suggestions for areas of improvement
          </li>
        </ul>
      </SwitchableSection>

      <SwitchableSection
        title="Scored PTE Practice Test"
        description="Machine-based Scored PTE practice tests consist of individual sectional test practice with different sets of questions as per the guidelines of Pearson. Users can choose from a range of any sections to test and get any questions to check your sub-skills for all the four modules. The Score of PTE practice test is based on deep analysis of each question type and the areas to improve in pronunciation, fluency, vocabulary, grammar, and content."
        reverse={true}
        image={practiceTest}
        imageAlt="Practice Test"
        textFlex="flex-[0.4]"
        imageFlex="flex-[0.4] mt-[40px] lg:mt-0"
      >
        <h3 className="text-xl font-bold text-black mb-4">
          Some of the features of PTE practice test are:
        </h3>
        <ul className="list-none space-y-2 text-gray-600">
          <li className="flex items-center">
            <DocumentClockIcon className="w-5 h-5 mr-2" />
            Instant scoring
          </li>
          <li className="flex items-center">
            <ClipboardCheckIcon className="w-5 h-5 mr-2" />
            Highlights your error in each answer
          </li>
          <li className="flex items-center">
            <FaceRecognitionIcon className="w-5 h-5 mr-2" />
            Analyze your strong and weak points
          </li>
          <li className="flex items-center">
            <AnswerValidationIcon className="w-5 h-5 mr-2" />
            Sample answers for every question to improve mistakes
          </li>
        </ul>
      </SwitchableSection>

      {/* Remaining Sections */}
      <div className="w-full lg:w-[80%] mt-[80px]">
        <h2 className="text-h3m text-center mb-[24px] ">
          Upon completion you will receive
        </h2>
        <img
          src={testScoreReport}
          alt="Test Score Report"
          className="mx-auto rounded-lg shadow lg:px-0 px-8"
        />
      </div>

      <div className="w-full lg:w-[80%] mt-[80px]">
        <h2 className="text-h3m text-center mb-[24px]">
          Under Test Analysis, you will be able to see your inputs
        </h2>
        <img
          src={testAnalysis}
          alt="Test Score Analysis"
          className="mx-auto rounded-lg shadow lg:px-0 px-8"
        />
      </div>

      <div className="w-full lg:w-2/3 mb-12 mt-[80px]">
        <h2 className="text-h3m text-center mb-[24px]">
          Maximum Points for Each Part in Analysis
        </h2>
        <Tabs
          tabs={analysisTabs}
          defaultActiveTab="speaking"
          className="rounded-lg"
        />
      </div>

      <SkillCTASection />
    </div>
  )
}

export default HowItWorksPage
