import React from 'react'

interface Skill {
  name: string
  score: string
}

interface Section {
  title: string
  skills: Skill[]
}

interface TabBodyProps {
  sections: Section[]
}

const TabBody: React.FC<TabBodyProps> = ({ sections }) => {
  return (
    <div className="grid grid-cols-1 xl:grid-cols-2 gap-6 mx-auto md:max-w-[53%]">
      {sections.map((section, index) => (
        <div
          className={`flex flex-col ${
            index === sections.length - 1 && sections.length % 2 !== 0
              ? 'md:col-span-1'
              : 'md:col-span-1'
          }`}
          key={section.title}
        >
          {/* Section Header */}
          <div className="flex justify-between items-center border-transparent text-gray-500">
            <h3 className="text-bodyr">{section.title}</h3>
            <span className="text-bodyr">Overall Score</span>
          </div>

          {/* Section Skills */}
          {section.skills.map((skill, skillIndex) => (
            <div
              className="flex justify-between py-[16px] px-[8px] bg-[#F5F5F5] border border-[#E0E0E0] rounded-[8px] text-bodym mt-[8px]"
              key={skillIndex}
            >
              <span>{skill.name}</span>
              <span>{skill.score}</span>
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}

export default TabBody
