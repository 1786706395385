import axiosInstance from '../configs/axiosConfig'

export function getMyUnlockedTest(userId: number) {
  const data = {
    user_id: userId,
    limit: 8,
  }

  return axiosInstance.post(`/purchase/my-test`, data)
}

export function getAccountDetailsForMyPurchase(userId: number) {
  const data = {
    user_id: userId,
  }

  return axiosInstance.post(`/purchase/account-details-for-my-purchase`, data)
}

export function getMockTestListToUnlock(userId: number, start: number) {
  const data = {
    user_id: userId,
    start: start,
  }

  return axiosInstance.post(`/purchase/mock-test-list-to-unlock`, data)
}

export function getPurchaseBundle(userId: number) {
  const data = {
    user_id: userId,
  }

  return axiosInstance.post(`/purchase/get-purchase-bundle`, data)
}

export function activeToken(userId: number, time: number, id: number) {
  const data = {
    user_id: userId,
    time: time,
    id: id,
  }

  return axiosInstance.post(`/purchase/active-token`, data)
}

export function unlockMockByCredits(userId: number, testId: number) {
  const data = {
    user_id: userId,
    test_id: testId,
  }

  return axiosInstance.post(`/purchase/unlock-mock-by-credits`, data)
}
