import React from 'react'
import faqsPTE from '../../images/Landingpage/Faqspage/faqs-pte.png'
import Accordion from '../../components/Accordion'
import { ReactComponent as ArrowAccordionDown } from '../../images/Landingpage/Faqspage/arrow-accordion-down.svg'
import { ReactComponent as ArrowAccordionRight } from '../../images/Landingpage/Faqspage/arrow-accordion-right.svg'
import TestimonialsCarousel from '../../components/Testimonials/TestimonialsCarousel'
import FaqsTestimonialsAvatar from '../../images/Landingpage/Faqspage/faqs-testimonial-avatar.png'
import FAQTestFormat from '../../components/FAQTestFormat'
import SkillCTASection from '../../components/LandingPage/AcademicSkill/SkillCTASection'

const FAQsPage: React.FC = () => {
  const faqItems = [
    {
      id: 1,
      title: 'What is PTE?',
      content: (
        <p>
          PTE is a computer-based test. It evaluates an individual's English
          ability in Speaking, Writing, Reading, & Listening. More importantly,
          it is entirely automated and delivers accurate and unbiased English
          language assessment results within a few hours. PTE has been accepted
          by educational institutions and governments worldwide for migration
          and study purposes.
        </p>
      ),
    },
    {
      id: 2,
      title: 'How long does it take to get the result?',
      content: (
        <p>
          PTE offers one of the fastest score delivery flexibilities. Test
          results are typically released within just 1–5 working days. In most
          cases, the results come out in just 48 hours! Wow! You can share your
          achieved score report. That's LIGHTNING-FAST compared to any other
          English test provider.
        </p>
      ),
    },
    {
      id: 3,
      title: 'Is PTE recognized as an international exam?',
      content: (
        <p>
          PTE is accepted for student and migration applications across the
          world. In addition to its unbiased approach, it has been trusted by
          numerous leading universities. It is one of the most popular exams in
          Australia, New Zealand, UK for migration and study purposes. It is
          also widely accepted by many schools and universities across the globe
          like Harvard, Yale, and INSEAD.
        </p>
      ),
    },
    {
      id: 4,
      title: 'How many parts in PTE?',
      content: (
        <p>
          The PTE exam consists of four main parts – Speaking & Writing, Reading
          and Listening, with the candidates needing to complete this in 2.5
          hours or 3 hours with an optional 10 minutes break between the reading
          and listening parts.
        </p>
      ),
    },
  ]

  const testformat = {
    id: 'test-format',
    label: 'Test Format',
    sublabel: 'Session times',
    description:
      'Please check your official test confirmation email for your session times.',
    content: (
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
        {[
          {
            title: 'Part 1: Speaking and Writing (77 to 93 minutes)',
            description: [
              'Personal Introduction',
              'Read aloud',
              'Repeat sentence',
              'Describe image',
              'Re-tell lecture',
              'Answer short question',
              'Summarize written text (one sentence)',
              'Summarize written text (paragraph)',
              'Essay (20 minutes)',
            ],
          },
          {
            title: 'Part 2: Reading (32 to 41 minutes)',
            description: [
              'Multiple choice questions (x2)',
              'Re-order paragraphs',
              'Fill in the blanks (x2)',
              'A ten-minute break is optional',
            ],
          },
          {
            title: 'Part 3: Listening (45 to 57 minutes)',
            description: [
              'Summarize spoken text',
              'Multiple choice (x2)',
              'Fill in the blanks',
              'Highlight the correct summary',
              'Select missing word',
              'Write from dictation',
            ],
          },
        ].map((item, index) => (
          <div
            key={index}
            className="flex flex-col bg-[#F5F5F5] justify-between group border border-gray-300 shadow-sm p-6 rounded-lg hover:shadow-lg hover:bg-gradient-to-b hover:from-[#2054E9] hover:to-[#2C62FF] hover:text-[#FFFFFF]"
          >
            <div>
              <p className="text-h4m transition-all duration-300">
                {item.title}
              </p>
              <ul className="list-disc list-inside space-y-1">
                {item.description.map((point, idx) => (
                  <li
                    key={idx}
                    className="text-gray-700 text-sm transition-all duration-300 group-hover:text-[#FFFFFF]"
                  >
                    {point}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    ),
  }

  const testimonials = [
    {
      avatar: FaqsTestimonialsAvatar,
      name: 'Thang Hua',
      rating: 5,
      content: ' I came to PTE Master ',
    },
    {
      avatar: FaqsTestimonialsAvatar,
      name: 'Tom Panson',
      rating: 4,
      content:
        ' I came to PTE Master just by accidentally clicked on the Facebook Ads. Perhaps, this is the first time Facebook Ads proved to be useful. Although it was just a mistake, I won\'t regret it at all. After ten dating times with Pearson, I felt utterly exhausted and even starting to think of a new place likes Canada. Things changed when Julia spent nearly thirty minutes just to convince me to give it a last try. In fact, I decided to take her advice and went for the last dating with Pearson, but this time I have fully prepared with Julia\'s "no-brain" techniques. No doubt, I finally got my desired scores with the overall 80+. Again, thank you a bunch for just everything, Julia. You had helped me to put a step closer to my PR ticket.',
    },
    {
      avatar: FaqsTestimonialsAvatar,
      name: 'Jane Doe',
      rating: 5,
      content:
        'The trainers at PTE Master were incredibly supportive. I highly recommend their services to anyone looking to achieve high scores in PTE.',
    },
    {
      avatar: FaqsTestimonialsAvatar,
      name: 'Jane Doe',
      rating: 6,
      content:
        'The trainers at PTE Master were incredibly supportive. I highly recommend their services to anyone looking to achieve high scores in PTE.',
    },
  ]

  return (
    <div className="w-full">
      {/* Banner Section */}
      <img
        src={faqsPTE}
        alt="FAQs Banner"
        className="w-full h-auto object-cover"
      />

      {/* Accordion Section */}
      <div className="flex flex-col justify-between lg:flex-row lg:gap-12 sm:w-[80%] w-full mx-auto px-6 py-[40px]">
        <h1 className="text-center lg:text-left lg:text-h2 text-h4m mb-6 w-full lg:w-1/3">
          Frequently Asked Questions
        </h1>
        <div className="w-full lg:w-2/3">
          <Accordion
            items={faqItems}
            expandedIcon={<ArrowAccordionDown />}
            collapsedIcon={<ArrowAccordionRight />}
          />
        </div>
      </div>

      <div className="w-full lg:w-[80%] mx-auto">
        <FAQTestFormat tabs={testformat} />
      </div>

      <div className="w-full bg-gray-100">
        <div className="w-full lg:w-[80%] mx-auto py-8">
          <TestimonialsCarousel testimonials={testimonials} />
        </div>
      </div>

      <SkillCTASection />
    </div>
  )
}

export default FAQsPage
