import React, { useState, useEffect, useRef, ChangeEvent } from 'react'
import WeeklyPredictionSubmitIcon from '../../images/WeeklyPrediction/weekly-prediction-submit.svg'
import { ReactComponent as PreviousArrowSvg } from '../../images/WeeklyPrediction/weekly-prediction-previous-arrow.svg'
import { ReactComponent as NextArrowSvg } from '../../images/WeeklyPrediction/weekly-prediction-next-arrow.svg'
import TryAgainArrowSvg from '../../images/Speaking/try-again-arrow.svg'
import { ReactComponent as ReturnArrowSvg } from '../../images/WeeklyPrediction/weekly-preidction-return.svg'
import ReadAloudArrowSvg from '../../images/Speaking/read-aloud-arrow.svg'
import LoadingMessage from '../Items/LoadingMessage'
import ErrorMessage from '../Items/ErrorMessage'
import { useAuth } from '../../providers/AuthProvider'
import {
  practiceNowWithFilter,
  updateAttempt,
  getAllMockQuestions,
  createResultWritingPractice,
} from '../../services/practice.services'
import {
  handleExceptionError,
  toastError,
  formatScore,
} from '../../utils/utils'
import PracticeMockQuestion from '../../models/practiceMockQuestion.model'
import MockQuestion from '../../models/mockQuestion.model'
import PracticeWritingResult from '../../models/practiceWritingResult.model'
import WritingQuestionStates from './WritingQuestionStates'
import WritingFinalResult from './WritingFinalResult'
import Path from '../../routes/Path'
import { Link } from 'react-router-dom'
import { QuestionModelId } from '../../models/QuestionModels'

type SummarizeWrittenTextProps = {
  setShowScoreBoard: (value: boolean) => void
  showScoreBoard: boolean
}

const WriteEssayQuestion: React.FC<SummarizeWrittenTextProps> = ({
  setShowScoreBoard,
  showScoreBoard,
}) => {
  const { userProfile } = useAuth()

  const [questionId, setQuestionId] = useState<string | null>(
    localStorage.getItem('questionIdPTE'),
  )
  const [question, setQuestion] = useState<PracticeMockQuestion | undefined>(
    undefined,
  )
  const [errorMsg, setErrorMsg] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [allMockQuestions, setAllMockQuestions] = useState<MockQuestion[]>([])
  const answerSectionRef = useRef<HTMLDivElement>(null)
  const questionSectionRef = useRef<HTMLDivElement>(null)
  const [wordsCount, setWordsCount] = useState(0)
  const [userAnswer, setUserAnswer] = useState('')
  const [isLoadingWritingResult, setIsLoadingWritingResult] = useState(true)
  const [writingResultResponse, setWritingResultResponse] = useState<
    PracticeWritingResult | undefined
  >(undefined)
  const [timer, setTimer] = useState({ minutes: 20, seconds: 0 })

  useEffect(() => {
    setIsLoading(true)
    setTimeout(async () => {
      const questionType = localStorage.getItem('questionTypePTE')
      const questionLabel = localStorage.getItem('ptePracticeQuestionLabel')

      if (
        userProfile &&
        userProfile.userId &&
        !isNaN(Number(questionId)) &&
        !isNaN(Number(questionType)) &&
        !isNaN(Number(questionLabel)) &&
        questionType === QuestionModelId.W_WriteEssay
      ) {
        try {
          const response = await practiceNowWithFilter(
            userProfile.userId,
            Number(questionType),
            Number(questionId),
            0,
            0,
            0,
            Number(questionLabel),
            4,
          )
          if (response.data.success) {
            const mockQuestions = response.data.mockQuestion
            if (Array.isArray(mockQuestions) && mockQuestions.length > 0) {
              setQuestion(mockQuestions[0])

              const allMockQuestionsData = await getAllMockQuestions(
                userProfile.userId,
                Number(questionType),
                Number(questionId),
                0,
                0,
                0,
                Number(questionLabel),
                4,
              )
              if (allMockQuestionsData.data.success) {
                setAllMockQuestions(allMockQuestionsData.data.allMockQuestion)
              } else {
                console.error('Failed to fetch all mock questions.')
              }

              await updateAttempt(
                userProfile.userId,
                Number(questionType),
                Number(questionId),
                0,
                0,
                0,
                Number(questionLabel),
                4,
              )
            } else {
              console.error('No questions found in the response')
              setErrorMsg('No valid question data found in the response!')
            }
          }
        } catch (error) {
          handleExceptionError(error)
          setErrorMsg('Error while getting the question data!!!')
        }
      } else {
        setErrorMsg('Error while getting the question data!!!')
      }
      setIsLoading(false)
    }, 1000)
  }, [questionId, userProfile])

  // Function to start the timer countdown
  useEffect(() => {
    let interval: NodeJS.Timeout | undefined

    if (timer.minutes === 0 && timer.seconds === 0) {
      // Timer has reached 00:00, stop the timer
      clearInterval(interval)
    } else {
      interval = setInterval(() => {
        if (timer.seconds === 0) {
          setTimer({ minutes: timer.minutes - 1, seconds: 59 })
        } else {
          setTimer({ ...timer, seconds: timer.seconds - 1 })
        }
      }, 1000)
    }

    // Cleanup function to clear the interval when component unmounts or timer stops
    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [timer])

  const formattedTime = `${timer.minutes.toString().padStart(2, '0')}:${timer.seconds.toString().padStart(2, '0')}`

  const handleTextChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    const text = e.target.value
    const wordCount = text.trim().split(/\s+/).filter(Boolean).length
    setWordsCount(wordCount)
    setUserAnswer(text)
  }

  const handleSubmitAnswer = () => {
    setIsSubmitted(true)
    setTimeout(async () => {
      if (userProfile && question) {
        try {
          const resultWriting = await createResultWritingPractice(
            userProfile.userId,
            '',
            question.id,
            question.category_id,
            userAnswer.trim(),
          )

          if (resultWriting.data.success) {
            setWritingResultResponse(resultWriting.data.result)
          } else {
            console.error(resultWriting.data.message)
          }
        } catch (error) {
          handleExceptionError(error)
        }
      } else {
        console.error('Userprofile or question is missing!')
      }
      setIsLoadingWritingResult(false)
    }, 1000)
  }

  const handleResetState = () => {
    setTimer({ minutes: 20, seconds: 0 })
    setUserAnswer('')
    setWordsCount(0)
    setIsSubmitted(false)
    setIsLoadingWritingResult(true)
    setShowScoreBoard(false)

    if (questionSectionRef.current) {
      questionSectionRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }

  const handleQuestionChange = (selectedQuestionId: string) => {
    localStorage.setItem('questionIdPTE', selectedQuestionId)
    setQuestionId(selectedQuestionId)
    handleResetState()
  }

  const isFirstQuestion = (): boolean => {
    const position = allMockQuestions.findIndex(
      (mockQuestion) => mockQuestion.id === question?.id,
    )
    if (allMockQuestions.length === 0 || position === -1 || position !== 0) {
      return false
    } else {
      return true
    }
  }

  const isLastQuestion = (): boolean => {
    const position = allMockQuestions.findIndex(
      (mockQuestion) => mockQuestion.id === question?.id,
    )
    if (
      allMockQuestions.length === 0 ||
      position === -1 ||
      position + 1 !== allMockQuestions.length
    ) {
      return false
    } else {
      return true
    }
  }

  const displayPreviousQuestion = () => {
    if (allMockQuestions.length === 0) {
      toastError('No questions in the list!')
    }

    const position = allMockQuestions.findIndex(
      (mockQuestion) => mockQuestion.id === question?.id,
    )

    if (position === -1) {
      toastError('Can not find the current question in the list!')
    } else if (position === 0) {
      toastError('This question is the first question!')
    } else {
      const previousQuestionId = allMockQuestions[position - 1].id
      localStorage.setItem('questionIdPTE', String(previousQuestionId))
      setQuestionId(String(previousQuestionId))
      handleResetState()
    }
  }

  const displayNextQuestion = () => {
    if (allMockQuestions.length === 0) {
      toastError('No questions in the list!')
    }

    const position = allMockQuestions.findIndex(
      (mockQuestion) => mockQuestion.id === question?.id,
    )

    if (position === -1) {
      toastError('Can not find the current question in the list!')
    } else if (position + 1 === allMockQuestions.length) {
      toastError('This question is the last question!')
    } else {
      const nextQuestionId = allMockQuestions[position + 1].id
      localStorage.setItem('questionIdPTE', String(nextQuestionId))
      setQuestionId(String(nextQuestionId))
      handleResetState()
    }
  }

  useEffect(() => {
    if (isSubmitted && answerSectionRef.current) {
      answerSectionRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
  }, [isSubmitted])

  const writingFinalMarks = [
    {
      name: 'Grammar',
      score: `${writingResultResponse ? formatScore(writingResultResponse.grammar) : 0}/2`,
    },
    {
      name: 'Content',
      score: `${writingResultResponse ? formatScore(writingResultResponse.context) : 0}/3`,
    },
    {
      name: 'Form',
      score: `${writingResultResponse ? formatScore(writingResultResponse.form) : 0}/2`,
    },
    {
      name: 'Spelling',
      score: `${writingResultResponse ? formatScore(writingResultResponse.spelling) : 0}/2`,
    },
    {
      name: 'Vocabulary',
      score: `${writingResultResponse ? formatScore(writingResultResponse.vocab) : 0}/2`,
    },
    {
      name: 'Linguistic range',
      score: `${writingResultResponse ? formatScore(writingResultResponse.vocab) : 0}/2`,
    },
    {
      name: 'Structure',
      score: `${writingResultResponse ? formatScore(writingResultResponse.vocab) : 0}/2`,
    },
    {
      name: 'Final',
      score: `${writingResultResponse ? Math.round(writingResultResponse.marks) : 10}/90`,
    },
  ]

  return (
    <div>
      {isLoading ? (
        <LoadingMessage message="Loading question..." />
      ) : errorMsg ? (
        <ErrorMessage message={errorMsg} />
      ) : (
        <div ref={questionSectionRef}>
          <p className="text-bodyr my-2 text-neutrals-2">
            <Link to={Path.weeklyPrediction.path}>Practice</Link> /{' '}
            <Link to={Path.weeklyPredictionCourse.path}>Writing Section</Link> /{' '}
            <span className="text-neutrals-1">Write Essay</span>
          </p>
          <Link to={Path.weeklyPredictionCourse.path}>
            <button className="py-2 px-4 flex items-center gap-2 my-6 bg-info rounded-lg">
              <ReturnArrowSvg fill="white" className="mr-2" />
              <span className="text-bodyr text-white">Return</span>
            </button>
          </Link>
          <div>
            <p className="text-bodym text-danger mt-2">
              * This question carries marks for Writing only (~18%)
            </p>
            <div className="p-8 rounded-xl shadow mt-8">
              <div className="w-[6.25rem] h-[3.5rem] bg-primary-2 mb-6 rounded-lg flex justify-center items-center mx-auto">
                <p className="text-h2 text-white">{formattedTime}</p>
              </div>
              <p className="text-h4m text-neutrals-1">
                {question?.short_title}
              </p>
              <div
                className="text-h5r text-neutrals-1 pt-4 mb-4"
                dangerouslySetInnerHTML={{ __html: question?.question || '' }}
              ></div>
              <div className="flex flex-col gap-2 mb-4">
                <label htmlFor="swt-answer" className="text-h5b">
                  Your answer
                </label>
                <textarea
                  className="w-full h-[18rem] text-bodyr border border-1 border-[#D9D9D9] p-2 resize-none"
                  maxLength={3000}
                  placeholder="Write here..."
                  value={userAnswer}
                  onChange={handleTextChange}
                  disabled={isSubmitted}
                />
              </div>
              <p className="text-body mb-4">Words count: {wordsCount}</p>
              <WritingQuestionStates question={question} />
              <div className="w-[90%] lg:w-[60%] mb-4 mx-auto">
                <div className="flex place-content-center	mt-8">
                  <button
                    className={`flex text-bodyr text-white items-center py-2 px-6 rounded-lg mr-2 
                        ${wordsCount === 0 || isSubmitted ? 'bg-gray-400 cursor-not-allowed' : 'bg-success'}`}
                    onClick={handleSubmitAnswer}
                    disabled={wordsCount === 0 || isSubmitted}
                  >
                    <img
                      src={WeeklyPredictionSubmitIcon}
                      alt="submit"
                      className="mr-2"
                    />
                    Submit
                  </button>
                </div>
              </div>
              <div
                className="flex items-center justify-center mt-[2rem] cursor-pointer"
                onClick={() => setShowScoreBoard(!showScoreBoard)}
              >
                <img
                  src={ReadAloudArrowSvg}
                  alt="arrow"
                  className={`transition-transform duration-300 ${showScoreBoard ? 'rotate-180' : 'rotate-0'}`}
                />

                <p className="text-bodyr text-info ml-2">
                  {!showScoreBoard ? 'Show' : 'Hide'} Score Board
                </p>
              </div>
            </div>

            <div className="flex w-full xl:w-[50%] justify-center gap-4 mx-auto mt-4">
              <button
                className={`flex items-center px-4 py-2 rounded-xl ${isFirstQuestion() ? 'bg-[#F0F0F0] text-gray-400 cursor-not-allowed' : 'bg-info text-white cursor-pointer'}`}
                disabled={isFirstQuestion()}
                onClick={displayPreviousQuestion}
              >
                <PreviousArrowSvg
                  fill={isFirstQuestion() ? 'gray' : 'white'}
                  className="md:mr-2"
                />
                <p className="hidden md:block text-bodyr">Previous</p>
              </button>
              <div className="flex text-white">
                <button
                  className="flex items-center px-4 py-2 bg-info rounded-xl cursor-pointer"
                  onClick={handleResetState}
                >
                  <p className="hidden md:block text-bodyr text-white">
                    Try Again
                  </p>
                  <img src={TryAgainArrowSvg} alt="again" className="md:ml-2" />
                </button>
              </div>

              <div>
                <select
                  className="px-2 md:px-4 py-2 rounded-xl border border-[1px]-[#D9D9D9] overflow-y"
                  defaultValue={question?.id}
                  onChange={(e) => handleQuestionChange(e.target.value)}
                >
                  {allMockQuestions.map((mockQuestion, index) => (
                    <option
                      key={mockQuestion.id}
                      value={mockQuestion.id}
                      className="text-bodyr"
                    >
                      {index + 1}
                    </option>
                  ))}
                </select>
              </div>

              <button
                className={`flex items-center px-4 py-2 rounded-xl ${isLastQuestion() ? 'bg-[#F0F0F0] text-gray-400 cursor-not-allowed' : 'bg-info text-white cursor-pointer'}`}
                onClick={displayNextQuestion}
                disabled={isLastQuestion()}
              >
                <p className="hidden md:block text-bodyr">Next</p>
                <NextArrowSvg
                  fill={isLastQuestion() ? 'gray' : 'white'}
                  className="md:ml-2"
                />
              </button>
            </div>

            {isSubmitted && (
              <div ref={answerSectionRef}>
                {isLoadingWritingResult ? (
                  <div className="mt-4">
                    <LoadingMessage message="Loading final result..." />
                  </div>
                ) : (
                  <WritingFinalResult finalResult={writingFinalMarks} />
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default WriteEssayQuestion
