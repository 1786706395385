import React, { useState } from 'react'

type Tab = {
  id: string | number
  label: string
  content: React.ReactNode // Content to display for each tab
}

type TabsProps = {
  tabs: Tab[] // Array of tab data
  defaultActiveTab?: string | number // ID of the default active tab
  onTabChange?: (tabId: string | number) => void // Callback when tab changes
  className?: string // Optional custom class for the tabs container
}

const Tabs: React.FC<TabsProps> = ({
  tabs,
  defaultActiveTab,
  onTabChange,
  className = '',
}) => {
  const [activeTab, setActiveTab] = useState<string | number>(
    defaultActiveTab || tabs[0].id,
  )

  const handleTabClick = (tabId: string | number) => {
    setActiveTab(tabId)
    if (onTabChange) {
      onTabChange(tabId)
    }
  }

  return (
    <div className={`tabs-container ${className}`}>
      {/* Tab Headers */}
      <div className="grid grid-cols-1 sm:flex sm:justify-center sm:mb-4 gap-4 sm:gap-0">
        {tabs.map((tab, index) => (
          <button
            key={tab.id}
            onClick={() => handleTabClick(tab.id)}
            className={`text-h5r px-4 py-2 rounded-[4px] ${
              activeTab === tab.id
                ? 'bg-info text-white'
                : 'border-transparent text-[#00000073]'
            }`}
          >
            {tab.label}
          </button>
        ))}
      </div>

      {/* Tab Content */}
      <div className="tab-content">
        {tabs.map(
          (tab) =>
            activeTab === tab.id && (
              <div key={tab.id} className="p-4">
                {tab.content}
              </div>
            ),
        )}
      </div>
    </div>
  )
}

export default Tabs
