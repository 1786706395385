import React from 'react'

interface TextInputProps {
  label?: string // Optional label
  placeholder: string
  type?: string
}

const TextInput: React.FC<TextInputProps> = ({
  label,
  placeholder,
  type = 'text',
}) => {
  return (
    <div className="flex flex-col">
      {label && (
        <label className="text-bodyr mb-[8px] text-neutrals-2">{label}</label>
      )}
      <input
        type={type}
        placeholder={placeholder}
        className="border border-gray-300 p-3 rounded-md w-full"
      />
    </div>
  )
}

export default TextInput
