interface SpeakingFinalResultProps {
  finalResult: {
    name: string
    score: string
  }[]
}

const SpeakingFinalResult: React.FC<SpeakingFinalResultProps> = ({
  finalResult,
}) => {
  return (
    <div className="bg-gray-100 rounded-xl py-4 grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-3 grid-cols-2 gap-6 mt-4 place-items-center">
      {finalResult.map((mark, index) => (
        <div key={index} className="flex flex-col items-center">
          <p className="text-center text-gray-600 font-medium mb-2">
            {mark.name}
          </p>
          <div className="flex flex-col items-center justify-center rounded-full bg-primary-2 text-white w-[4rem] h-[4rem] shadow-md">
            <p className="text-h5b">{mark.score}</p>
          </div>
        </div>
      ))}
    </div>
  )
}

export default SpeakingFinalResult
