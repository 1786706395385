import React, { useState } from 'react'

type SkillTestFormatProps = {
  tabs: {
    id: string
    label: string
    content: React.ReactNode
  }[]
}

const SkillTestFormat: React.FC<SkillTestFormatProps> = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(tabs[0].id)

  return (
    <div className="w-full px-6 lg:px-12 py-6 lg:py-12">
      <div className="lg:w-[80%] mx-auto flex flex-col lg:flex-row">
        {/* Tabs Section */}
        <div className="w-full flex flex-col md:flex-row lg:flex-col mb-6 lg:mb-0 lg:w-1/3 lg:pr-4 lg:border-r-[2px] border-gray-300 lg:justify-normal md:justify-around">
          {tabs.map((tab) => (
            <div
              key={tab.id}
              className={`text-h2 cursor-pointer ${
                activeTab === tab.id ? 'text-black' : 'text-[#BFBFBF]'
              }`}
              onClick={() => setActiveTab(tab.id)}
            >
              {tab.label}
            </div>
          ))}
        </div>

        {/* Content Section */}
        <div className="w-full lg:w-[80%] lg:pl-4">
          {tabs.map(
            (tab) =>
              activeTab === tab.id && (
                <div key={tab.id} className="bg-[#F5F5F5] p-3 rounded-lg">
                  {tab.content}
                </div>
              ),
          )}
        </div>
      </div>
    </div>
  )
}

export default SkillTestFormat
