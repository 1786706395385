import { QuestionModelId } from '../../models/QuestionModels'
import AnswerShortQuestionList from './AnswerShortQuestionList'
import DescribeImageList from './DescribeImageList'
import OtherSpeakingQuestionTypeList from './OtherSpeakingQuestionTypeList'

interface SpeakingQuestionListProps {
  questionType: QuestionModelId
  practiceType: number
  labelType: number
}

const SpeakingQuestionList: React.FC<SpeakingQuestionListProps> = ({
  questionType,
  practiceType,
  labelType,
}) => {
  return (
    <>
      {questionType === QuestionModelId.S_DescribeImage ? (
        <DescribeImageList
          categoryType={questionType}
          practiceType={practiceType}
          labelType={labelType}
        />
      ) : questionType === QuestionModelId.S_AnswerShortQuestion ? (
        <AnswerShortQuestionList
          categoryType={questionType}
          practiceType={practiceType}
          labelType={labelType}
        />
      ) : (
        <OtherSpeakingQuestionTypeList
          categoryType={questionType}
          practiceType={practiceType}
          labelType={labelType}
        />
      )}
    </>
  )
}

export default SpeakingQuestionList
