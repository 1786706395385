import axiosInstance from '../configs/axiosConfig'

//System Requirement
export function startTestNow1(userId: number, buyId: number, testId: number) {
  const data = {
    user_id: userId,
    buyid: buyId,
    testid: testId,
  }

  return axiosInstance.post(`/mocktest/start-test-now-1`, data)
}

export function doTest1(buyId: number, testId: number, userId: number) {
  const data = {
    ubuyid: buyId,
    testid: testId,
    user_id: userId,
  }

  return axiosInstance.post(`/mocktest/do-test-1`, data)
}

export function doTest1Update(
  buyId: number,
  testId: number,
  userId: number,
  row: number,
  questionType: number,
  speakingUpdatedTime: string,
  updatedTime: string,
  allcount: number,
  butNext: string,
) {
  const data: any = {
    ubuyid: buyId,
    testid: testId,
    user_id: userId,
    row: row,
    updatedtime: updatedTime,
    allcount: allcount,
    but_next: butNext,
  }

  if (questionType === 1) {
    data['speakingUpdatedTime'] = speakingUpdatedTime
  }
  if (questionType === 3) {
    data['readingUpdatedTime'] = speakingUpdatedTime
  }
  if (questionType === 4) {
    data['listening_section_timer'] = speakingUpdatedTime
  }

  return axiosInstance.post(`/mocktest/do-test-1`, data)
}

export function addResultSpeakingMock(
  newRecordName: string,
  mocqdesc: string,
  userId: number,
  testId: number,
  testQuestionId: number,
  testCategoryId: number,
  recordDuration: number,
  userText: string,
  confidenceMark: number,
  fluencyMark: number,
) {
  const data = {
    newRecordName: newRecordName,
    mocqdesc: mocqdesc || 'none',
    userId: userId,
    testId: testId,
    testQuestionId: testQuestionId,
    testCategoryId: testCategoryId,
    recordDuration: recordDuration,
    userText: userText,
    confidenceMark: confidenceMark,
    fluencyMark: fluencyMark,
  }

  return axiosInstance.post(`/mock-test/speaking`, data)
}

export function addResultWritingMock(
  mocqdesc: string,
  userId: number,
  testId: number,
  testQuestionId: number,
  testCategoryId: number,
  userAnswer: string,
) {
  const data = {
    mocqdesc: mocqdesc || 'none',
    userId: userId,
    testId: testId,
    testQuestionId: testQuestionId,
    testCategoryId: testCategoryId,
    userAnswer: userAnswer,
  }

  return axiosInstance.post(`/mock-test/writing`, data)
}

export function addResultReadingMock(
  mocqdesc: string,
  userId: number,
  testId: number,
  testQuestionId: number,
  testCategoryId: number,
  userAnswer: string,
  userAnswerStr: string,
) {
  const data = {
    mocqdesc: mocqdesc || 'none',
    userId: userId,
    testId: testId,
    testQuestionId: testQuestionId,
    testCategoryId: testCategoryId,
    mark: testCategoryId === 12 ? parseFloat(userAnswer) : 0,
    userAnswer: userAnswer,
    userAnswerstr: userAnswerStr,
  }

  return axiosInstance.post(`/mock-test/reading`, data)
}

export function addResultListeningMock(
  mocqdesc: string,
  userId: number,
  testId: number,
  testQuestionId: number,
  testCategoryId: number,
  userAnswer: string,
  userAnswerStr: string,
  m: number,
) {
  const data = {
    mocqdesc: mocqdesc || 'none',
    userId: userId,
    testId: testId,
    testQuestionId: testQuestionId,
    testCategoryId: testCategoryId,
    mark: m,
    userAnswer: userAnswer,
    userAnswerstr: userAnswerStr,
  }

  return axiosInstance.post(`/mock-test/listening`, data)
}
