import React from 'react'
import SkillHeader from '../../components/LandingPage/AcademicSkill/SkillHeader'
import SkillTestFormat from '../../components/LandingPage/AcademicSkill/SkillTestFormat'
import SkillCTASection from '../../components/LandingPage/AcademicSkill/SkillCTASection'
import AcademicBanner from '../../images/Landingpage/academic-banner.png'

const AcademicSpeakingPage: React.FC = () => {
  const skillHeaderContent = {
    title: 'PTE Academic Speaking Section',
    subtitle:
      'Establish your English language speaking expertise with the PTE Academic Speaking Test.',
    description:
      "Are you preparing for the PTE (Pearson Test of English) Academic exam? The speaking section is one of the most crucial scoring areas in the exam. Whether you're searching for PTE coaching classes in Australia or anywhere globally, having a deep understanding of the PTE exam's speaking section is essential. Real PTE Practice tutorial providers focus on crafting comprehensive course content tailored to the syllabus.",
    bannerImage: AcademicBanner,
  }

  const skillTestTabs = [
    {
      id: 'test-format',
      label: 'Test Format',
      content: (
        <div>
          <p className="text-bodyr pb-4">
            The basic PTE exam format comprises four different sections. The
            total exam duration for the Pearson Test of English is 2 hours.
          </p>
          <ul className="list-none space-y-2">
            {[
              'The speaking section in PTE is tailored for evaluating the English language speaking, listening, and writing skills of candidates and is important for PTE study.',
              'The exam evaluates the speaking skills of candidates through questions asking them to repeat sentences from an audio clip.',
              'The total duration for the speaking test would range from 45-60 minutes.',
              'The questions also ask candidates to summarize passages on certain academic topics.',
            ].map((point, index) => (
              <li key={index} className="flex items-start">
                <span className="text-green-600 font-bold mr-2">✔</span>
                <p className="text-bodyr">{point}</p>
              </li>
            ))}
          </ul>
        </div>
      ),
    },
    {
      id: 'skill-test',
      label: 'Skill Test',
      content: (
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
          {[
            {
              title: 'Personal Introduction',
              description: [
                'The first highlight of the speaking section in the PTE Academic test is the Personal Introduction part.',
                'Candidates do not receive any points for this section.',
                'However, this is a perfect platform to start off in the speaking section.',
                'The Personal Introduction section is meant for security purposes and you should practice it well in PTE mock exam.',
              ],
            },
            {
              title: 'Describe Image',
              description: [
                'Candidates have to describe around 6 to 7 images in this section of the speaking test in PTE, thereby making it one of the difficult parts of a PTE training course.',
                'Candidates get 25 seconds to study each image and prepare their content.',
                'Once 25 seconds are over, candidates have to record their responses.',
                'The scoring criteria for this section are also the same as the previous sections, and the factors for scoring are also similar.',
              ],
            },
            {
              title: 'Read Aloud',
              description: [
                'The important sections of the speaking test start with the “Read Aloud” section.',
                'Candidates have to read aloud the text on their computer screen.',
                'There will be around 4 to 5 texts in this section.',
                'Candidates get a countdown of 30 to 40 seconds for preparing the content.',
                'Once the microphone starts “Recording,” candidates can see it in the status box and record their response.',
                'The scoring criteria for the PTE Read Aloud Test are based on partial credit.',
                'The factors for scoring include reading, speaking, content, pronunciation, and oral fluency.',
              ],
            },
            {
              title: 'Repeat Sentence',
              description: [
                'The Repeat Sentence section is one of the crucial aspects of the speaking test of PTE and an important part of every PTE test preparation course.',
                'Candidates have to repeat the sentences exactly as they hear them in audio clips.',
                'Candidates will find around 10 to 12 sentences in this section.',
                'The scoring criteria for the PTE Academic repeat sentence test follow the partial credit model.',
                'The factors for scoring include listening, oral fluency, content, speaking, and pronunciation.',
                'The scoring criteria for the PTE Read Aloud Test are based on partial credit.',
                'The factors for scoring include reading, speaking, content, pronunciation, and oral fluency.',
              ],
            },

            {
              title: 'Re-tell Lecture',
              description: [
                'Candidates would receive an audio clip of an academic lecture.',
                'The requirement for this section is to repeat the lecture in a summarized form.',
                'Candidates get 10 seconds to prepare for this task once the audio clip’ duration completes.',
                'The scoring for this section is based on listening, content, oral fluency, and pronunciation like the previous sections.',
              ],
            },
            {
              title: 'Answer the Short Question',
              description: [
                'The final section in the speaking test course content on any Real PTE Practice Platform involves answering a question in one or a few words.',
                'Candidates will get around 10 to 12 questions regarding day-to-day life in the form of audio clips.',
                'The scoring criteria for this section of the speaking test include listening, vocabulary, comprehension, and speaking.',
              ],
            },
          ].map((item, index) => (
            <div key={index} className="bg-[#F5F5F5] p-3 rounded-lg">
              <p className="text-h4m">{item.title}</p>
              <ul className="list-none space-y-2 mt-2">
                {item.description.map((point, idx) => (
                  <li key={idx} className="flex items-start">
                    <span className="text-green-600 font-bold mr-2">✔</span>
                    <p className="text-bodyr text-[#595959]">{point}</p>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      ),
    },
  ]

  return (
    <div className="w-full bg-white">
      {/* Header Section */}
      <SkillHeader
        title={skillHeaderContent.title}
        subtitle={skillHeaderContent.subtitle}
        description={skillHeaderContent.description}
        bannerImage={skillHeaderContent.bannerImage}
      />

      {/* Test Format Section */}
      <SkillTestFormat tabs={skillTestTabs} />

      {/* Call-to-Action Section */}
      <SkillCTASection />
    </div>
  )
}

export default AcademicSpeakingPage
