import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import ReportProgressOverallSectionalSection from './ReportProgressOverallSectionalSection'
import RPSpeakingScoreDetailsSection from './RPSpeakingScoreDetailsSection'
import { useAuth } from '../../../providers/AuthProvider'
import { progressTrackerSectionalSpeaking } from '../../../services/testHistory.services'
import LoadingMessage from '../../Items/LoadingMessage'
import { ProgessTrackerSectionalSpeaking } from '../../../models/progressTrackerSectional.model'

const ReportHistorySpeakingSectionalSection = () => {
  let { buyId } = useParams()
  const { userProfile } = useAuth()
  const [isLoading, setIsLoading] = useState(true)
  const [reportHistorySectionalSpeaking, setReportHistorySectionalSpeaking] =
    useState<ProgessTrackerSectionalSpeaking>({
      spk_5_pronoun: 0,
      spk_5_fluency: 0,
      spk_5_contet: 0,
      spk_5_prgrs: 0,
      spk_5_pbgcls_total: '',
      spk_5_contet_pbgcls: '',
      spk_5_fluency_pbgcls: '',
      spk_5_pronoun_pbgcls: '',
      spk_6_pronoun: 0,
      spk_6_fluency: 0,
      spk_6_contet: 0,
      spk_6_prgrs: 0,
      spk_6_pbgcls_total: '',
      spk_6_contet_pbgcls: '',
      spk_6_fluency_pbgcls: '',
      spk_6_pronoun_pbgcls: '',
      spk_7_pronoun: 0,
      spk_7_fluency: 0,
      spk_7_contet: 0,
      spk_7_prgrs: 0,
      spk_7_pbgcls_total: '',
      spk_7_contet_pbgcls: '',
      spk_7_fluency_pbgcls: '',
      spk_7_pronoun_pbgcls: '',
      spk_8_pronoun: 0,
      spk_8_fluency: 0,
      spk_8_contet: 0,
      spk_8_prgrs: 0,
      spk_8_pbgcls_total: '',
      spk_8_contet_pbgcls: '',
      spk_8_fluency_pbgcls: '',
      spk_8_pronoun_pbgcls: '',
      spk_9s: [
        {
          contet: '',
          pbgcls: '',
        },
        {
          contet: '',
          pbgcls: '',
        },
        {
          contet: '',
          pbgcls: '',
        },
        {
          contet: '',
          pbgcls: '',
        },
        {
          contet: '',
          pbgcls: '',
        },
      ],
      s_pronoun: 0,
      s_fluency: 0,
      s_contet: 0,
      sprgrs: 0,
      sprgrs_pbgcls: '',
      sprgrs_pbgcls_total: '',
      s_contet_pbgcls: '',
      s_fluency_pbgcls: '',
      s_pronoun_pbgcls: '',
    })

  useEffect(() => {
    try {
      const fetchProgressTrackerSectionalSpeaking = async () => {
        if (userProfile && userProfile.userId && !isNaN(Number(buyId))) {
          try {
            const response = await progressTrackerSectionalSpeaking(
              userProfile.userId,
              Number(buyId),
            )
            if (response.data.success) {
              setReportHistorySectionalSpeaking(response.data.result)
            }
          } catch (error) {
            console.error(error)
          } finally {
            setTimeout(() => {
              setIsLoading(false)
            }, 2000)
          }
        } else {
          console.error(
            'User profile is missing or invalid or buy id is invalid!',
          )
        }
      }

      fetchProgressTrackerSectionalSpeaking()
    } catch (error) {
      console.error(error)
    }
  }, [userProfile, buyId])

  const overallScores = [
    {
      id: 1,
      skill: 'Overall Pronunciation',
      score: reportHistorySectionalSpeaking.s_pronoun,
    },
    {
      id: 2,
      skill: 'Overall Fluency',
      score: reportHistorySectionalSpeaking.s_fluency,
    },
    {
      id: 3,
      skill: 'Overall Content',
      score: reportHistorySectionalSpeaking.s_contet,
    },
    {
      id: 4,
      skill: 'Overall Speaking Score',
      score: reportHistorySectionalSpeaking.sprgrs,
    },
  ]

  return (
    <div className="report-history-speaking-sectional-section">
      <p className="text-h1 font-landingPrimary mb-[0.4rem]">Report History</p>
      {isLoading ? (
        <div className="mt-[3rem]">
          <LoadingMessage message="Loading report history sectional speaking..." />
        </div>
      ) : (
        <>
          <ReportProgressOverallSectionalSection
            overallScoreInfo={overallScores}
          />
          <RPSpeakingScoreDetailsSection
            resultSpeakingDetails={reportHistorySectionalSpeaking}
          />
        </>
      )}
    </div>
  )
}

export default ReportHistorySpeakingSectionalSection
