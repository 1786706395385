import React, { useState } from 'react'

type AccordionItem = {
  id: string | number
  title: React.ReactNode // Allow richer content for titles
  content: React.ReactNode
}

type AccordionProps = {
  items: AccordionItem[]
  defaultActiveItem?: string | number | null // Default expanded item
  activeItem?: string | number | null // Controlled active item
  onToggle?: (id: string | number | null) => void // Callback when toggling
  headerClassName?: string // Custom class for header
  contentClassName?: string // Custom class for content
  containerClassName?: string // Custom class for container
  expandedIcon?: React.ReactNode // Custom icon for expanded state
  collapsedIcon?: React.ReactNode // Custom icon for collapsed state
  transitionDuration?: number // Transition duration (ms)
}

const Accordion: React.FC<AccordionProps> = ({
  items,
  defaultActiveItem = null,
  activeItem: controlledActiveItem,
  onToggle,
  headerClassName = '',
  contentClassName = '',
  containerClassName = '',
  expandedIcon = '▲',
  collapsedIcon = '▼',
  transitionDuration = 300,
}) => {
  const [internalActiveItem, setInternalActiveItem] = useState<
    string | number | null
  >(defaultActiveItem)

  // Determine the active item (controlled or uncontrolled)
  const activeItem = controlledActiveItem ?? internalActiveItem

  const toggleItem = (id: string | number) => {
    const newActiveItem = activeItem === id ? null : id

    // Update internal state if uncontrolled
    if (controlledActiveItem === undefined) {
      setInternalActiveItem(newActiveItem)
    }

    // Call onToggle callback
    onToggle?.(newActiveItem)
  }

  return (
    <div className={` ${containerClassName}`}>
      {items.map((item) => (
        <div key={item.id} className="border-t border-gray-300 rounded-lg">
          {/* Header */}
          <div
            className={`flex justify-between items-center p-4 cursor-pointer bg-white hover:bg-gray-100 w-full ${headerClassName}`}
            onClick={() => toggleItem(item.id)}
          >
            <h3 className="font-medium text-lg w-full">{item.title}</h3>
            <span className="ml-4">
              {activeItem === item.id
                ? expandedIcon && React.isValidElement(expandedIcon)
                  ? expandedIcon
                  : '▲'
                : collapsedIcon && React.isValidElement(collapsedIcon)
                  ? collapsedIcon
                  : '▼'}
            </span>
          </div>
          {/* Content */}
          <div
            className={`overflow-hidden transition-[max-height] duration-${
              transitionDuration
            } ease-in-out ${
              activeItem === item.id
                ? 'max-h-screen p-4 bg-gray-50' // When open
                : 'max-h-0' // When closed
            } ${contentClassName}`}
            style={{
              visibility: activeItem === item.id ? 'visible' : 'hidden', // Ensure content is fully hidden
            }}
          >
            {item.content}
          </div>
        </div>
      ))}
    </div>
  )
}

export default Accordion
