import React, { useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import TestimonialCard from './TestimonialCard'
import { ReactComponent as ArrowLeft } from '../../images/Landingpage/Faqspage/arrow-left.svg'
import { ReactComponent as ArrowLeftActive } from '../../images/Landingpage/Faqspage/arrow-left-testi-active.svg'
import { ReactComponent as ArrowRight } from '../../images/Landingpage/Faqspage/arrow-right.svg'
import { ReactComponent as ArrowRightActive } from '../../images/Landingpage/Faqspage/arrow-right-testi-active.svg'
import { Swiper as SwiperType } from 'swiper'

type TestimonialsCarouselProps = {
  testimonials: {
    avatar: string
    name: string
    rating: number
    content: string
  }[]
}

const TestimonialsCarousel: React.FC<TestimonialsCarouselProps> = ({
  testimonials,
}) => {
  // Reference to control Swiper instance
  const swiperRef = useRef<SwiperType | null>(null)

  // State to manage navigation button status
  const [isBeginning, setIsBeginning] = useState(true)
  const [isEnd, setIsEnd] = useState(false)

  const handlePrev = () => {
    swiperRef.current?.slidePrev() // Navigate to the previous slide
  }

  const handleNext = () => {
    swiperRef.current?.slideNext() // Navigate to the next slide
  }

  const updateNavigationState = (swiper: SwiperType) => {
    setIsBeginning(swiper.isBeginning)
    setIsEnd(swiper.isEnd)
  }

  return (
    <div className="w-full">
      <div className="flex px-6 flex-col lg:flex-row ">
        <h2 className="text-3xl font-bold mr-[129px] mb-[20px] lg:mb-0">
          Testimonials
        </h2>
        <div className="flex flex-col w-full overflow-hidden">
          <div className="flex space-x-4">
            {/* Previous Button */}
            <button
              onClick={handlePrev}
              className="rounded-full w-10 h-10 flex items-center justify-center"
            >
              {
                isBeginning ? (
                  <ArrowLeft /> // Inactive arrow
                ) : (
                  <ArrowLeftActive />
                ) // Active arrow
              }
            </button>

            {/* Next Button */}
            <button
              onClick={handleNext}
              className="rounded-full w-10 h-10 flex items-center justify-center"
            >
              {
                isEnd ? (
                  <ArrowRight /> // Inactive arrow
                ) : (
                  <ArrowRightActive />
                ) // Active arrow
              }
            </button>
          </div>

          {/* Swiper Component */}
          <Swiper
            onSwiper={(swiper: SwiperType) => (swiperRef.current = swiper)}
            onSlideChange={(swiper: SwiperType) =>
              updateNavigationState(swiper)
            }
            slidesPerView="auto"
            spaceBetween={20}
            className="w-full h-auto"
          >
            {testimonials.map((testimonial, index) => (
              <SwiperSlide key={index}>
                <TestimonialCard {...testimonial} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  )
}

export default TestimonialsCarousel
