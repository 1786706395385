type SkillHeaderProps = {
  title: string
  subtitle: string
  description: string
  bannerImage: string
}

const SkillHeader: React.FC<SkillHeaderProps> = ({
  title,
  subtitle,
  description,
  bannerImage,
}) => {
  return (
    <div className="w-full px-6 lg:px-12 py-6 lg:py-12">
      {/* Header Section */}
      <div className="lg:w-[80%] flex flex-col lg:flex-row justify-between items-center lg:items-start mx-auto">
        <div className="lg:w-1/2 text-center lg:text-left mb-6 lg:mb-0">
          <p className="text-h1 text-left md:text-center">{title}</p>
        </div>
        <div className="lg:w-1/2">
          <p className="text-h3m pb-4">{subtitle}</p>
          <p className="text-bodyr text-[#595959]">{description}</p>
        </div>
      </div>

      {/* Banner Image */}
      <div className="flex justify-center mt-8">
        <img
          className="lg:w-[80%] w-full object-contain"
          src={bannerImage}
          alt="Academic Banner"
        />
      </div>
    </div>
  )
}

export default SkillHeader
