import { displaySkillProgressColor } from '../../../utils/utils'

interface OverallScore {
  id: number
  skill: string
  score: number
}

interface ReportProgressOverallSectionalProps {
  overallScoreInfo: OverallScore[]
}

const ReportProgressOverallSectionalSection: React.FC<
  ReportProgressOverallSectionalProps
> = ({ overallScoreInfo }) => {
  const radius = 64
  const circumference = 2 * Math.PI * radius
  const strokeValCalculation = (score: number) => {
    return (score / 90) * circumference
  }

  return (
    <div
      className={`w-full bg-[#101116] lg:p-0 py-4 lg:h-[14.651rem] h-full rounded-[1.75rem] my-[2rem] grid gap-4 ${overallScoreInfo.length !== 1 ? 'lg:grid-cols-4 grid-cols-2' : 'lg:grid-cols-1 grid-cols-1'}`}
    >
      {overallScoreInfo.map((score, index) => (
        <div
          key={index}
          className="relative flex flex-col gap-2 justify-center items-center"
        >
          <p className="text-h5m mb-2 text-white text-center">{score.skill}</p>
          <button className="relative flex justify-center items-center">
            <svg
              width="141"
              height="141"
              viewBox="0 0 141 141"
              className="transform -rotate-90"
            >
              <circle
                cx="70.5"
                cy="70.5"
                r={radius}
                fill="none"
                stroke="#252525"
                strokeWidth="13"
              />
              <circle
                cx="70.5"
                cy="70.5"
                r={radius}
                fill="none"
                stroke={displaySkillProgressColor(score.score)}
                strokeWidth="13"
                strokeDasharray={circumference}
                strokeDashoffset={
                  circumference - strokeValCalculation(score.score)
                }
                strokeLinecap="round"
              />
            </svg>
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <span className="text-h1 text-white font-semibold">
                {score.score}
              </span>
            </div>
          </button>
        </div>
      ))}
    </div>
  )
}

export default ReportProgressOverallSectionalSection
