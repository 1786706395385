import React, { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, EffectCoverflow } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/autoplay'
import 'swiper/css/effect-coverflow'

import arrowRightIcon from '../../images/Homepage/arrow-right.svg'
import TestHistory from './TestHistory'
import VerifiedUsers from '../../models/verifiedUsers.model'
import ErrorMessage from '../Items/ErrorMessage'

interface HeroSectionProps {
  verifiedUsers: VerifiedUsers[]
}

const HeroSection: React.FC<HeroSectionProps> = ({ verifiedUsers }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [modalId, setModalId] = useState<number | null>(null)

  const toggleModal = (id?: number) => {
    setShowModal(!showModal)
    setModalId(id ?? null)
  }

  const getSlideShadow = (index: number) =>
    index === activeIndex ? 'opacity-100 !scale-110' : 'opacity-60'

  return (
    <div className="w-full block lg:grid lg:grid-cols-5 justify-center">
      {/* Left Section */}
      <div className="col-span-2 p-8 lg:pr-4 justify-center content-center">
        <h1 className="text-h1 text-black mb-4">
          Our Verified Successful Students
        </h1>
        <p className="text-bodyr text-neutrals-2 mb-6">
          Welcome to our PTE test site in Australia, where you can discover the
          success stories of our verified students and see how our tailored
          programs have helped them excel in their exams.
        </p>

        {/* Student Swiper */}
        <div className="hidden lg:block p-4 border rounded-xl w-full min-w-0">
          <Swiper
            spaceBetween={20}
            slidesPerView={3}
            modules={[Autoplay]}
            autoplay={{ delay: 0, disableOnInteraction: false }}
            loop={false}
            speed={1000}
            breakpoints={{
              1024: {
                slidesPerView: 3,
              },
              1280: {
                slidesPerView: 4,
              },
              1440: {
                slidesPerView: 5,
              },
            }}
            className="hero-swiper"
          >
            {verifiedUsers.length === 0 ? (
              <ErrorMessage message="There aren't any verified students here!" />
            ) : (
              verifiedUsers.map((user) => (
                <SwiperSlide key={user.userId}>
                  <img
                    src={user.image}
                    alt={user.name}
                    className="w-[4.25rem] h-[4.25rem] rounded-full"
                  />
                </SwiperSlide>
              ))
            )}
          </Swiper>
        </div>

        {/* Start Button */}
        <div className="flex justify-between items-center">
          <button className="flex items-center text-bodyr text-white bg-info h-12 px-6 rounded-xl hover:scale-105">
            <img src={arrowRightIcon} alt="Arrow Right" className="mr-2" />
            Let's get started
          </button>
          <div className="hidden lg:block">
            <svg
              width="114"
              height="126"
              viewBox="0 0 114 126"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M96.9183 80.9258C83.4468 90.7994 62.0981 99.1933 47.9514 94.9537C41.5333 93.0304 29.0387 81.0383 37.4171 71.2951C46.5972 60.62 54.0035 79.0014 44.0858 86.5269C35.3163 93.181 25.8497 89.8548 19.7217 83.837C8.89203 73.2014 5.18355 58.0948 8.05521 40.2774"
                stroke="#2E2E2E"
                strokeWidth="2"
                strokeMiterlimit="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="animated-path"
              />
              <path
                d="M110.803 80.2536C108.235 83.1224 105.565 85.8658 102.984 88.7168C102.453 89.3028 101.104 91.5316 100.535 91.6959C99.7374 91.9257 98.9594 89.5583 98.7636 89.0164C97.7606 86.2372 97.0187 83.3398 96.2672 80.422C96.1798 80.0831 95.459 78.1268 95.6659 77.84C96.4523 76.7498 98.5969 77.3784 99.4036 77.4346C102.925 77.6802 107.775 77.9032 110.988 79.1967"
                stroke="#2E2E2E"
                strokeWidth="2.93433"
                strokeMiterlimit="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="animated-triangle"
              />
            </svg>
          </div>
        </div>
      </div>

      {/* Score Report Swiper */}
      <div className="col-span-3 px-8 lg:p-4 justify-center content-center">
        {verifiedUsers.length !== 0 ? (
          <Swiper
            modules={[Autoplay, EffectCoverflow]}
            effect="coverflow"
            centeredSlides={true}
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: false,
            }}
            spaceBetween={-120}
            slidesPerView={1.5}
            autoplay={{ delay: 2000, disableOnInteraction: false }}
            loop={false}
            speed={1000}
            breakpoints={{
              425: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 1.5,
              },
              1440: {
                slidesPerView: 2,
              },
            }}
            onSlideChange={(swiper: any) => setActiveIndex(swiper.realIndex)}
          >
            {verifiedUsers.map((student, index) => (
              <SwiperSlide
                key={student.userId}
                className={`${getSlideShadow(index)}`}
                onClick={() => {
                  if (index === activeIndex) toggleModal(student.userId)
                }}
              >
                <img
                  src={student.adminUpload}
                  alt="Student Score Report"
                  className="w-full"
                />
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <div className="h-40 flex justify-center items-center">
            <div
              className="p-4 text-sm text-red-800 rounded-lg bg-red-200 border border-red-600"
              role="alert"
            >
              <span className="font-medium">
                There aren't any verified students here!
              </span>
            </div>
          </div>
        )}
        {showModal && modalId && (
          <TestHistory id={modalId} onClose={toggleModal} />
        )}{' '}
        <div className="w-full text-center mt-[1rem]">
          <button className="bg-primary-6 text-bodyr text-white rounded-xl px-4 py-2 hover:scale-105">
            Compare to Mock up Test
          </button>
        </div>
      </div>
    </div>
  )
}

export default HeroSection
