import { useForm } from 'react-hook-form'
import { useAuth } from '../../providers/AuthProvider'
import { changePassword } from '../../services/user.services'
import { toastError, toastSuccess } from '../../utils/utils'

const ChangePasswordSection = () => {
  const { userProfile } = useAuth()

  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  })

  const onSubmit = async (data: any) => {
    if (userProfile && userProfile.userId) {
      try {
        const response = await changePassword(
          userProfile.userId,
          data.password.trim(),
        )
        if (response.data.success) {
          toastSuccess(response.data.message)
        } else {
          toastError(response.data.message)
        }
      } catch (error) {
        if (error instanceof Error) {
          toastError(error.message)
        } else {
          toastError('An unknown error occurred!')
        }
      }
    } else {
      toastError('Something wrong!')
    }
  }

  return (
    <div className="change-profile-password__password-section mx-auto mt-[1rem] lg:w-[23.438rem] w-full">
      <form onSubmit={handleSubmit(onSubmit)}>
        <p className="password-section__title text-h5m">Change password</p>
        <div className="password-section__password mt-[1rem]">
          <label htmlFor="password" className="password__title text-bodym">
            Password
          </label>
          <input
            type="password"
            id="password"
            placeholder="Enter your password here"
            className="password__input mt-[0.4rem] w-full h-[2.375rem] text-bodyr shadow-user-profile-input rounded-lg p-[7px] border border-1 border-[#0000000F]"
            {...register('password', {
              required: 'Please enter your password!',
              minLength: {
                value: 6,
                message: 'Password must be from 6 charaters!',
              },
            })}
          />
          {errors.password && (
            <div className="text-red-500 text-footnote mt-1">
              {errors.password.message}
            </div>
          )}
        </div>
        <div className="password-section__confirmed-password mt-[1rem]">
          <label
            htmlFor="confirmPassword"
            className="confirmed-password__title text-bodym"
          >
            Confirm password
          </label>
          <input
            type="password"
            id="confirmPassword"
            placeholder="Enter your confirm password here"
            className="phone__input mt-[0.4rem] w-full h-[2.375rem] text-bodyr shadow-user-profile-input rounded-lg p-[7px] border border-1 border-[#0000000F]"
            {...register('confirmPassword', {
              required: 'Please enter your confirm password!',
              validate: (val: string) => {
                if (watch('password') !== val) {
                  return 'Your confirm password does not match with password!'
                }
              },
            })}
          />
          {errors.confirmPassword && (
            <div className="text-red-500 text-footnote mt-1">
              {errors.confirmPassword.message}
            </div>
          )}
        </div>
        <div className="profile-section__submit mt-[1rem] flex justify-end w-full">
          <button
            type="submit"
            className="w-[9.25rem] h-[2.375rem] bg-info hover:bg-[#1890FF] rounded-lg flex justify-center items-center text-white text-bodyr"
          >
            Save new password
          </button>
        </div>
      </form>
    </div>
  )
}

export default ChangePasswordSection
