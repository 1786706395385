import React from 'react'

type SwitchableSectionProps = {
  title: string
  subtitle?: string // Added subtitle as optional
  description?: string
  image?: string
  imageAlt?: string
  children?: React.ReactNode
  className?: string
  reverse?: boolean // To switch the order of text and image
  maxWidth?: string // To set the maximum width dynamically
  textFlex?: string // To control the flex of the text section
  imageFlex?: string // To control the flex of the image section
}

const SwitchableSection: React.FC<SwitchableSectionProps> = ({
  title,
  subtitle,
  description,
  image,
  imageAlt,
  children,
  reverse = false,
  maxWidth = '80%',
  textFlex = 'flex-[0.45]',
  imageFlex = 'flex-[0.45]',
  className = '',
}) => {
  return (
    <div
      className={`w-full lg:w-[80%] flex justify-between flex-col ${
        reverse ? 'lg:flex-row-reverse' : 'lg:flex-row'
      } items-start py-12 ${className}`}
      style={{ maxWidth }}
    >
      {/* Text Content */}
      <div className={`${textFlex}`}>
        {subtitle && (
          <h3 className="text-bodyr text-[#00000073] mb-[10px]">{subtitle}</h3>
        )}{' '}
        {/* Subtitle */}
        <h2 className="text-h1 mb-[16px]">{title}</h2>
        {description && (
          <p className="text-gray-600 text-bodyr mb-[16px]">{description}</p>
        )}
        {children}
      </div>

      {/* Image */}
      {image && (
        <div className={`${imageFlex}`}>
          <img src={image} alt={imageAlt} className="rounded-lg" />
        </div>
      )}
    </div>
  )
}

export default SwitchableSection
