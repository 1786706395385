import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useAuth } from '../providers/AuthProvider'
import { isTokenValid } from '../utils/utils'
import Path from './Path'

interface ProtectedRouteProps {
  component: React.ComponentType
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
}) => {
  const { token } = useAuth()
  const location = useLocation()

  if (!token || !isTokenValid(token)) {
    localStorage.removeItem('accessToken')
    return (
      <Navigate
        to={Path.landing.main.path}
        state={{ from: location }}
        replace
      />
    )
  }

  return <Component />
}

export default ProtectedRoute
