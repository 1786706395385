import React from 'react'
import Vector from '../../images/Landingpage/vector.png'
import HeroSectionBanner from '../../images/Landingpage/hero-section-banner.jpg'
import { Link } from 'react-router-dom'

const HeroSection: React.FC = () => {
  return (
    <div className="hero-section px-8 lg:py-[3rem] py-[1rem]">
      <div className="hero-section__content pb-[3rem] grid grid-cols-5 justify-between">
        <div className="col-span-5 lg:col-span-2">
          <p className="lg:text-2xl text-xl text-neutrals font-landingLogo">
            Welcome to{' '}
            <span className="block text-info lg:text-[3rem] text-[2rem] font-bold mt-6">
              PTE MASTER
            </span>
          </p>
        </div>
        <div className="hidden lg:block col-span-1"></div>
        <div className="hero-section__description col-span-5 lg:col-span-2 lg:ml-auto lg:mt-0 mt-[2rem] font-landingPrimary">
          <p className="text-bodyr">
            At PTE Master, we offer PTE (Pearson Test of English) training
            course to all students that are ready and willing to stand out and
            scale higher above their colleagues by achieving higher scores and
            become much better outside there.
          </p>
          <Link to="https://home.masterpte.com.au/freeebook/">
            <button className="lg:w-2/3 w-full text-bodyr text-white bg-info mt-6 py-3 px-2 rounded-xl flex items-center justify-center hover:scale-105">
              <img src={Vector} alt="vector icon" className="mr-4" />
              Subscribe for free document
            </button>
          </Link>
        </div>
      </div>
      <div className="hero-section__image rounded-3xl font-sans font-medium text-[15px] bg-cover bg-center w-full lg:h-[42rem] h-[22rem] flex items-center justify-center relative">
        <img
          className="rounded-3xl object-cover object-center w-full h-full"
          src={HeroSectionBanner}
          alt="Hero Section Banner"
        />
        <div className="absolute bottom-0 right-0 w-1/3">
          <a
            href="/"
            className="text-sm bg-white text-landingPrimary lg:px-8 px-4 lg:py-4 py-2 rounded-tl-3xl flex items-center "
          >
            <svg
              className="mr-4 w-4 "
              viewBox="0 0 19 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.6643 9.37839L8.96627 0.961318C8.89183 0.897143 8.79685 0.861206 8.69674 0.861206H6.42498C6.23502 0.861206 6.14775 1.09737 6.2915 1.22058L15.281 9.02415H0.259129C0.146183 9.02415 0.053772 9.11656 0.053772 9.22951V10.7697C0.053772 10.8826 0.146183 10.975 0.259129 10.975H15.2784L6.28893 18.7786C6.14518 18.9044 6.23246 19.138 6.42241 19.138H8.77118C8.81996 19.138 8.86873 19.12 8.90466 19.0867L18.6643 10.6208C18.7531 10.5435 18.8244 10.4481 18.8732 10.3409C18.922 10.2338 18.9473 10.1174 18.9473 9.9996C18.9473 9.88183 18.922 9.76544 18.8732 9.65828C18.8244 9.55111 18.7531 9.45567 18.6643 9.37839Z"
                fill="url(#paint0_linear_1391_241)"
              />
              <defs></defs>
              <linearGradient
                id="paint0_linear_1391_241"
                x1="9.55216"
                y1="19.2373"
                x2="9.55216"
                y2="0.861205"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#2054E9" />
                <stop offset="1" stopColor="#2C62FF" />
              </linearGradient>
            </svg>
            <p className="text-info">Why us</p>
          </a>
        </div>
      </div>
    </div>
  )
}

export default HeroSection
