import React from 'react'
import { ReactComponent as DropDownArrowIcon } from '../../images/Landingpage/ContactUspage/chevron-down.svg'

interface DropdownProps {
  label?: string // Optional label
  options: string[]
  defaultText: string
}

const Dropdown: React.FC<DropdownProps> = ({ label, options, defaultText }) => {
  return (
    <div className="flex flex-col">
      {/* Label (if provided) */}
      {label && (
        <label className="text-bodyr mb-[8px] text-neutrals-2">{label}</label>
      )}

      {/* Dropdown container */}
      <div className="relative">
        {/* Custom Arrow Icon */}
        <DropDownArrowIcon className="absolute top-1/2 right-4 transform -translate-y-1/2 pointer-events-none" />

        {/* Dropdown Select */}
        <select className="border text-neutrals-2 border-gray-300 p-3 rounded-md w-full bg-white appearance-none pr-10">
          <option>{defaultText}</option>
          {options.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}

export default Dropdown
