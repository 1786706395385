const PurchaseCancelPage = () => {
  return (
    <>
      <div className="w-full min-h-[80vh] lg:p-[4rem] p-[1rem] bg-[#F0F7FF] rounded-l-lg flex flex-col gap-2 items-center justify-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="60"
          height="60"
          viewBox="0 0 48 48"
        >
          <path
            fill="#f44336"
            d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"
          ></path>
          <path
            fill="#fff"
            d="M29.656,15.516l2.828,2.828l-14.14,14.14l-2.828-2.828L29.656,15.516z"
          ></path>
          <path
            fill="#fff"
            d="M32.484,29.656l-2.828,2.828l-14.14-14.14l2.828-2.828L32.484,29.656z"
          ></path>
        </svg>
        <p className="text-h2">
          Your payment was canceled, please try again later!!!
        </p>
      </div>
    </>
  )
}

export default PurchaseCancelPage
