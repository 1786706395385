import { displaySkillProgressColor } from '../../utils/utils'
import ProgressTracker from '../../models/progressTracker.model'

interface PracticeSpeakingScoreSectionProps {
  progressTrackerInfo: ProgressTracker
}

const PracticeWritingScoreSection: React.FC<
  PracticeSpeakingScoreSectionProps
> = ({ progressTrackerInfo }) => {
  const writingInfo = [
    {
      title: 'Summarize Written Text',
      score: progressTrackerInfo.wrt_10prgrs,
    },
    {
      title: 'Write Essay',
      score: progressTrackerInfo.wrt_11prgrs,
    },
  ]

  return (
    <div className="practice-performance__writing-skill-details font-landingPrimary w-full flex flex-col gap-8">
      <div className="writing-skill-details__writing lg:h-[15rem] h-full rounded-[1rem] shadow-pm-card p-[1.5rem]">
        <p className="text-h4m mb-[1rem]">Writing</p>
        <p className="text-h5m">Total</p>
        <div className="writing-skill-details__writing__detail w-full flex flex-col gap-2 mt-[1rem]">
          {writingInfo.map((info, index) => (
            <div
              key={index}
              className={`writing-skill-details__writing__detail__${info.title}`}
            >
              <p className="text-footnote text-neutrals-2 mb-[0.2rem]">
                {info.title}
              </p>
              <div className="h-[1.5rem] flex gap-2">
                <div
                  style={{
                    width: `${(info.score / 90) * 100}%`,
                    backgroundColor: displaySkillProgressColor(info.score),
                  }}
                ></div>
                <span className="text-bodym">{info.score}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default PracticeWritingScoreSection
