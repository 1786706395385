import React from 'react'
import { ReactComponent as LocationIcon } from '../../images/Landingpage/ContactUspage/location-contact-page.svg'
import SkillCTASection from '../../components/LandingPage/AcademicSkill/SkillCTASection'

const PrivacyPolicyPage: React.FC = () => {
  return (
    <div className="w-full mt-[80px]">
      <div className="mx-auto mb-[50px] justify-between bg-white lg:px-[101px] p-8 rounded-lg flex flex-col lg:flex-row gap-8">
        {/* Left Section */}
        <div className="lg:w-1/4 w-full mr-[338px]">
          <h1 className="text-h1 mb-[15px]">Privacy Policy</h1>
          <p className="text-bodyr text-[#595959] mb-[15px]">
            PTE Master is located at:
          </p>
          <div className="flex items-start">
            <div className="text-blue-500 mr-4">
              {/* Location Icon */}
              <LocationIcon className="h-6 w-6" />
            </div>
            <div className="w-[50%]">
              <p className="text-h5m mb-[8px]">Sydney Office</p>
              <p className="text-bodyr text-[#8C8C8C]">
                Level 36 Gateway, 1 Macquarie Place Sydney NSW 2000
              </p>
            </div>
          </div>
        </div>

        {/* Right Section */}
        <div className="lg:w-[40%] w-full space-y-8">
          {/* Introduction */}
          <section>
            <h2 className="text-h3m mb-[12px]">
              Make your preparation easy using PTE Academic's automated scoring
              system.
            </h2>
            <p className="text-[#595959] text-bodyr leading-6">
              At PTE Master, we respect your privacy and are committed to
              protecting personally identifiable information you may provide us
              through the Website. This Privacy Policy applies to PTE Master
              (hereinafter, "we" or "PTE Master"). We explain what information
              may be collected on our Website, how we use this information, and
              under what circumstances we may disclose the information to third
              parties. This Privacy Policy applies only to information we
              collect through the Website and does not apply to our collection
              of information from other sources.
            </p>
          </section>

          {/* Website Visitors */}
          <section>
            <h2 className="text-h3m mb-[12px]">Website Visitors</h2>
            <p className="text-[#595959] text-bodyr leading-6">
              Like most website operators, PTE Master collects
              non-personally-identifying information of the sort that web
              browsers and servers typically make available, such as the browser
              type, language preference, referring site, and the date and time
              of each visitor request. This information helps us better
              understand how visitors use our Website.
            </p>
          </section>

          {/* Gathering of Personally-Identifying Information */}
          <section>
            <h2 className="text-h3m mb-[12px]">
              Gathering of Personally-Identifying Information
            </h2>
            <p className="text-[#595959] text-bodyr leading-6">
              Certain visitors to PTE Master’s websites choose to interact with
              PTE Master in ways that require PTE Master to gather
              personally-identifying information. The amount and type of
              information depends on the nature of the interaction.
            </p>
          </section>

          {/* Security */}
          <section>
            <h2 className="text-h3m mb-[12px]">Security</h2>
            <p className="text-[#595959] text-bodyr leading-6">
              The security of your Personal Information is important to us, but
              remember that no method of transmission over the Internet or
              method of electronic storage is 100% secure. While we strive to
              use commercially acceptable means to protect your Personal
              Information, we cannot guarantee its absolute security.
            </p>
          </section>

          {/* Google AdWords */}
          <section>
            <h2 className="text-h3m mb-[12px]">
              PTE Master uses Google AdWords for remarketing
            </h2>
            <p className="text-[#595959] text-bodyr leading-6">
              PTE Master uses the remarketing services to advertise on
              third-party websites (including Google) to previous visitors to
              our site. You can set preferences for how Google advertises to you
              by visiting the Google Ad Preferences page.
            </p>
          </section>

          {/* Cookies */}
          <section>
            <h2 className="text-h3m mb-[12px]">Cookies</h2>
            <p className="text-[#595959] text-bodyr leading-6">
              To enrich and perfect your online experience, PTE Master uses
              "Cookies" to display personalized content, appropriate
              advertising, and store your preferences on your computer.
            </p>
          </section>

          {/* Privacy Policy Changes */}
          <section>
            <h2 className="text-h3m mb-[12px]">Privacy Policy Changes</h2>
            <p className="text-[#595959] text-bodyr leading-6">
              Although most changes are likely to be minor, PTE Master may
              change its Privacy Policy from time to time. Visitors are
              encouraged to frequently check this page for any changes.
            </p>
          </section>
        </div>
      </div>
      <SkillCTASection />
    </div>
  )
}

export default PrivacyPolicyPage
