import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import BlogCTA from '../../images/Landingpage/Blog/blog-cta.png'

const SubscribeForm: React.FC = () => {
  const [email, setEmail] = useState('')

  // const handleSubscribe = () => {
  //   alert(`Subscribed with email: ${email}`)
  // }

  return (
    <div
      className="rounded-xl overflow-hidden flex flex-col lg:flex-row justify-between items-stretch h-[300px]"
      style={{
        background: 'linear-gradient(360deg, #2054E9 -0.54%, #2C62FF 100%)',
      }}
    >
      {/* Left Section: Text and Input */}
      <div className="text-white lg:w-2/3 xl:pl-8 lg:pl-4 px-4 py-6 lg:text-left text-center">
        <h2 className="text-h3m mb-2 font-bold">Newsletter</h2>
        <p className="text-h5r mb-6">Get notified about the next update.</p>
        <div className="mb-6 sm:mx-auto lg:mx-0 mx-0">
          <label htmlFor="email" className="block text-h5b text-white mb-2">
            Enter email address
          </label>
          <div className="relative max-w-md lg:mx-0 mx-auto">
            <input
              type="email"
              id="email"
              placeholder="Yourname@email.com*"
              className="w-full py-2 text-h4m text-[#BFBFBF] bg-transparent border-b-[1.5px] border-[#BFBFBF] focus:outline-none focus:ring-0 focus:border-white placeholder:text-[#BFBFBF] placeholder-opacity-80"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
        <Link
          to="/"
          className="inline-block bg-white text-blue-600 font-medium py-2 lg:px-2 xl:px-6 rounded-lg shadow-md transition-transform duration-300 hover:scale-105"
        >
          Get Started Now
        </Link>
      </div>

      {/* Right Section: Full-Height Background Image */}
      <div className="lg:block hidden lg:w-1/3 h-full">
        <img
          src={BlogCTA}
          alt="Blog CTA"
          className="w-full h-full object-cover"
        />
      </div>
    </div>
  )
}

export default SubscribeForm
