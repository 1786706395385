import { useState, useEffect } from 'react'
import ApplyStudentCodeSection from './ApplyStudentCodeSection'
import UpdateProfileSection from './UpdateProfileSection'
import ChangePasswordSection from './ChangePasswordSection'
import SetTargetScoreSection from './SetTargetScoreSection'
import User from '../../models/user.model'
import { useAuth } from '../../providers/AuthProvider'
import { getUserDetails } from '../../services/user.services'

const UserProfileSection = () => {
  const [userDetails, setUserDetails] = useState<User | undefined>(undefined)
  const { userProfile } = useAuth()

  useEffect(() => {
    if (userProfile && userProfile.userId) {
      try {
        getUserDetails(userProfile.userId).then((response) => {
          if (response.data.success) {
            setUserDetails(response.data.userInfo)
          }
        })
      } catch (error) {
        console.error(error)
      }
    } else {
      console.log(userProfile)
    }
  }, [userProfile])

  return (
    <div className="user-profile-section font-landingPrimary">
      <p className="user-profile-section__title text-h1 font-landingPrimary lg:mb-[3rem] mb-[2rem] text-center">
        User Profile
      </p>
      <ApplyStudentCodeSection />
      <div className="user-profile-section__change-profile-password lg:mb-0 mb-[1rem] bg-white shadow-update-user-password-section mx-auto mt-[1rem] rounded-lg shadow-apply-code-section py-[1rem] lg:px-0 px-2 lg:max-w-[49.5rem] w-full lg:min-h-[47.5rem] h-full">
        <UpdateProfileSection user={userDetails} />
        <ChangePasswordSection />
      </div>
      <SetTargetScoreSection user={userDetails} />
    </div>
  )
}

export default UserProfileSection
