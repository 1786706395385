import React from 'react'
import {
  displaySkillProgressColor,
  displaySkillProgressBarWidth,
} from '../../../utils/utils'
import { ProgessTrackerSectionalListening } from '../../../models/progressTrackerSectional.model'

interface RPWritingScoreDetailsSectionnProps {
  resultListeningDetails: ProgessTrackerSectionalListening
}

interface SkillDetail {
  title: string
  score: number
}

const SkillProgressDetail: React.FC<{ info: SkillDetail }> = ({ info }) => (
  <div
    className={`listening-skill-details__${info.title.toLowerCase().replace(/ /g, '-')}`}
  >
    <p className="text-footnote text-neutrals-2 mb-[0.2rem]">{info.title}</p>
    <div className="h-[1.5rem] flex gap-2">
      <div
        style={{
          width: `${displaySkillProgressBarWidth(info.score)}%`,
          backgroundColor: displaySkillProgressColor(info.score),
        }}
      ></div>
      <span className="text-bodym">{info.score}</span>
    </div>
  </div>
)

const SkillSection: React.FC<{ title: string; skills: SkillDetail[] }> = ({
  title,
  skills,
}) => (
  <div
    className={`listening-skill-details__${title.toLowerCase().replace(/ /g, '-')} h-full rounded-[1rem] shadow-pm-card p-[1.5rem]`}
  >
    <p className="text-h4m mb-[1rem]">{title}</p>
    <p className="text-h5m">Total</p>
    <div
      className={`listening-skill-details__${title.toLowerCase().replace(/ /g, '-')}-detail w-full flex flex-col gap-2 mt-[1rem]`}
    >
      {skills.map((info, index) => (
        <SkillProgressDetail key={index} info={info} />
      ))}
    </div>
  </div>
)

const RPListeningScoreDetailsSection: React.FC<
  RPWritingScoreDetailsSectionnProps
> = ({ resultListeningDetails }) => {
  const summarizeSpokenTextInfo: SkillDetail[] = [
    { title: 'Grammar', score: resultListeningDetails.spk_17_grammer },
    { title: 'Spelling', score: resultListeningDetails.spk_17_spell },
    { title: 'Form', score: resultListeningDetails.spk_17_form },
    { title: 'Content', score: resultListeningDetails.spk_17_contet },
    { title: 'Vocabulary', score: resultListeningDetails.spk_17_contet },
    { title: 'Total', score: resultListeningDetails.spk_17_prgrs },
  ]

  const fillInTheBlanksInfo: SkillDetail[] = [
    { title: 'Spelling', score: resultListeningDetails.spk_19_spell },
    { title: 'Total', score: resultListeningDetails.spk_19_prgrs },
  ]

  const highlightCorrectSummaryInfo: SkillDetail[] = [
    { title: 'Total', score: resultListeningDetails.spk_20_prgrs },
  ]

  const selectMissingWordInfo: SkillDetail[] = [
    { title: 'Total', score: resultListeningDetails.spk_22_prgrs },
  ]

  const writeFromDictationInfo: SkillDetail[] = [
    { title: 'Spelling', score: resultListeningDetails.spk_24_spell },
    { title: 'Total', score: resultListeningDetails.spk_24_prgrs },
  ]

  const highlightIncorrectWordsInfo: SkillDetail[] = [
    { title: 'Total', score: resultListeningDetails.spk_23_prgrs },
  ]

  const multipleChoiceMultipleAnswersInfo: SkillDetail[] = [
    { title: 'Total', score: resultListeningDetails.spk_18_prgrs },
  ]

  const multipleChoiceSingleAnswerInfo: SkillDetail[] = [
    { title: 'Total', score: resultListeningDetails.spk_21_prgrs },
  ]

  return (
    <div className="rp-st-listening-skill-details font-landingPrimary w-full flex flex-col gap-8">
      <SkillSection
        title="Summarize Spoken Text"
        skills={summarizeSpokenTextInfo}
      />
      <SkillSection title="Fill In The Blanks" skills={fillInTheBlanksInfo} />
      <SkillSection
        title="Highlight Correct Summary"
        skills={highlightCorrectSummaryInfo}
      />
      <SkillSection
        title="Select Missing Word"
        skills={selectMissingWordInfo}
      />
      <SkillSection
        title="Write From Dictation"
        skills={writeFromDictationInfo}
      />
      <SkillSection
        title="Highlight Incorrect Words"
        skills={highlightIncorrectWordsInfo}
      />
      <SkillSection
        title="Multiple-Choice, Choose Multiple Answers"
        skills={multipleChoiceMultipleAnswersInfo}
      />
      <SkillSection
        title="Multiple-Choice, Choose Single Answer"
        skills={multipleChoiceSingleAnswerInfo}
      />
    </div>
  )
}

export default RPListeningScoreDetailsSection
