import {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react'

interface AudioSectionalProps {
  audioUrl: string
}

const AudioListeningCourse = forwardRef<
  { resetAudio: () => void },
  AudioSectionalProps
>(({ audioUrl }, ref) => {
  const audioRef = useRef<HTMLAudioElement>(null)
  const [progress, setProgress] = useState(0)
  const [error, setError] = useState('')

  useImperativeHandle(ref, () => ({
    resetAudio: () => {
      const audio = audioRef.current
      if (audio) {
        audio.currentTime = 0
        audio
          .play()
          .then(() => {
            setError('')
          })
          .catch((err) => {
            console.error('Playback failed:', err)
          })
      }
    },
  }))

  useEffect(() => {
    const audio = audioRef.current

    if (audio) {
      const handleTimeUpdate = () => {
        setProgress((audio.currentTime / audio.duration) * 100)
      }

      audio.addEventListener('timeupdate', handleTimeUpdate)

      const autoplay = () => {
        audio
          .play()
          .then(() => {
            setError('')
          })
          .catch((err) => {
            console.error('Autoplay failed:', err)
            setError(
              'Autoplay failed. Please click the "Start" button to start the audio.',
            )
          })
      }

      const autoplayTimeout = setTimeout(autoplay, 1000)

      return () => {
        audio.removeEventListener('timeupdate', handleTimeUpdate)
        clearTimeout(autoplayTimeout)
      }
    }
  }, [audioUrl])

  const handlePlayButtonClick = () => {
    const audio = audioRef.current
    if (audio) {
      audio
        .play()
        .then(() => {
          setError('')
        })
        .catch((err) => {
          console.error('Playback failed:', err)
        })
    }
  }

  return (
    <div className="audio-section p-2 mx-auto mb-4 lg:min-w-[30.125rem] w-80 lg:min-h-[5rem] h-full bg-[#F1EFF2] rounded-lg flex flex-col justify-center items-center gap-2">
      {error && (
        <p className="text-red-600 text-footnote w-full text-center">{error}</p>
      )}
      <div className="audio-section__progress-bar px-4 lg:w-[26rem] w-full h-[2.5rem] border border-2 border-gray-400 rounded-lg flex justify-center items-center">
        <audio ref={audioRef} src={audioUrl} />
        <div className="w-full h-[0.375rem] rounded-[0.625rem] bg-[#D9D9D9] relative">
          <div
            style={{
              width: `${progress}%`,
              backgroundColor: '#262626',
              height: '100%',
              borderRadius: '10px',
              transition: 'width 0.1s linear',
            }}
          />
          <div
            style={{
              left: `calc(${progress}% - 0.5rem)`,
              transition: 'left 0.1s linear',
            }}
            className="absolute top-1/2 w-4 h-4 bg-[#262626] rounded-full transform -translate-y-1/2"
          />
        </div>
      </div>
      {error && (
        <button
          onClick={handlePlayButtonClick}
          className="bg-gray-400 text-gray-600 text-bodyr font-bold p-1 rounded-lg"
        >
          Start
        </button>
      )}
    </div>
  )
})

export default AudioListeningCourse
