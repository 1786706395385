import React, { useEffect, useState } from 'react'
import historyTestAnalytics from '../../images/Homepage/history-test-analytics.svg'
import AnalyticsModal from '../../components/AnalyticsModal'
import {
  getTestHistoryMock,
  getReportHistoryDetails,
} from '../../services/testHistory.services'
import TestHistoryMock from '../../models/testHistoryMock.model'
import ReportHistoryDetails from '../../models/reportHistoryDetails.model'
import LoadingMessage from '../../components/Items/LoadingMessage'
import ErrorMessage from '../../components/Items/ErrorMessage'
import Spinner from '../../components/Spinner'

interface TestHistoryModalProps {
  studentId: number
  onClose: () => void
}

const TestHistoryModal: React.FC<TestHistoryModalProps> = ({
  studentId,
  onClose,
}) => {
  const [testHistory, setTestHistory] = useState<TestHistoryMock[]>([])
  const [reportHistoryDetails, setReportHistoryDetails] =
    useState<ReportHistoryDetails>({
      speaking: 10,
      writing: 10,
      listening: 10,
      reading: 10,
      average: 10,
      vocabulary: 10,
      spelling: 10,
      grammar: 10,
      pronunciation: 10,
      form: 10,
      fluency: 10,
    })
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingDisplayRHD, setIsLoadingDisplayRHD] = useState(false)

  useEffect(() => {
    getTestHistoryMock(studentId)
      .then((response) => {
        if (response.data.success === true) {
          setTestHistory(response.data.test)
        }
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        setTimeout(() => {
          setIsLoading(false)
        }, 2000)
      })
  }, [studentId])

  const itemsPerPage = 3
  const [showModal, setShowModal] = useState(false)
  const [selectedTest, setSelectedTest] = useState<TestHistoryMock | null>(null)

  const [currentPage, setCurrentPage] = useState(1)
  const totalPages = Math.ceil(testHistory.length / itemsPerPage)

  const startIndex = (currentPage - 1) * itemsPerPage
  const currentItems: TestHistoryMock[] = testHistory.slice(
    startIndex,
    startIndex + itemsPerPage,
  )

  useEffect(() => {
    const body = document.querySelector('body')
    if (showModal && body) {
      body.style.overflow = 'hidden'
    }
    return () => {
      if (body) {
        body.style.overflow = 'visible'
      }
    }
  }, [showModal])

  const goToPage = (pageNumber: number) => {
    setCurrentPage(pageNumber)
  }

  const handleViewAnalytics = (test: TestHistoryMock) => {
    setIsLoadingDisplayRHD(true)
    setSelectedTest(test)
    setShowModal(false)
    getReportHistoryDetails(test.userId, test.testId, test.id)
      .then((response) => {
        if (response.data.success === true) {
          setReportHistoryDetails({
            speaking: response.data.final_data.score_speaking_section,
            writing: response.data.final_data.score_writing_section,
            listening: response.data.final_data.score_listening_section,
            reading: response.data.final_data.score_reading_section,
            average: response.data.average,
            vocabulary: response.data.final_data.score_vocab,
            grammar: response.data.final_data.score_grammar,
            spelling: response.data.final_data.score_spelling,
            pronunciation: response.data.final_data.score_pronunciation,
            fluency: response.data.final_data.score_fluency,
            form: response.data.final_data.score_form,
          })
        }
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        setTimeout(() => {
          setIsLoadingDisplayRHD(false)
          setShowModal(true)
        }, 2000)
      })
  }

  return (
    <div
      className="fixed z-50 inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-start pt-5"
      onClick={onClose}
    >
      <div
        className="relative mx-2 w-full lg:w-[90%] xl:w-[70%] max-h-[90vh] overflow-y-auto p-6 bg-white rounded-xl shadow-lg"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="w-full text-right">
          <button
            onClick={onClose}
            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg p-1.5"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </button>
        </div>

        <div className="w-full text-center">
          <h2 className="text-h3m">Test History</h2>
        </div>
        <div className="w-full">
          {isLoading ? (
            <div className="h-[4rem] mt-[2rem]">
              <LoadingMessage message="Loading test history ..." />
            </div>
          ) : testHistory.length !== 0 ? (
            <>
              <div className="grid grid-cols-5 text-h5m text-neutrals-2 my-4 text-center">
                <p>Thumbnail</p>
                <p>Title</p>
                <p>Purchase Date</p>
                <p>Duration</p>
                <p>Actions</p>
              </div>
              <div className="w-full text-center">
                {currentItems.map((testHistory: TestHistoryMock) => (
                  <div
                    key={testHistory.id}
                    className="grid grid-cols-5 gap-4 bg-white rounded-lg shadow lg:px-0 px-2 py-4 mb-4 items-center"
                  >
                    <div className="flex justify-center items-center">
                      <img
                        src={testHistory.details?.image}
                        alt="Test Thumbnail"
                        className="w-[6.75ewm] h-[6.75rem] rounded-lg"
                      />
                    </div>
                    <div className=" justify-center text-bodyr text-neutrals-1">
                      {testHistory.details?.name}
                    </div>
                    <div className="justify-center text-bodyr text-neutrals-1">
                      {testHistory.completeDate}
                    </div>
                    <div className="justify-center text-bodyr text-neutrals-1">
                      {testHistory.details?.duration}
                    </div>
                    <div className="flex justify-center gap-2">
                      <button
                        className={`${isLoadingDisplayRHD && selectedTest?.id === testHistory.id ? 'hidden' : ''} bg-info hover:scale-105 hover:cursor-pointer text-bodyr text-white py-2 px-4 rounded-lg`}
                        onClick={() => handleViewAnalytics(testHistory)}
                      >
                        <div className="flex">
                          <img
                            src={historyTestAnalytics}
                            alt="History Test Analytics"
                            className="mr-1"
                          />
                          <span className="lg:inline hidden">
                            View analytics
                          </span>
                        </div>
                      </button>
                      {isLoadingDisplayRHD &&
                        selectedTest?.id === testHistory.id && <Spinner />}
                    </div>
                    <div className="col-span-5">
                      {selectedTest?.id === testHistory.id && (
                        <AnalyticsModal
                          show={showModal}
                          onClose={() => setShowModal(false)}
                          test={reportHistoryDetails}
                        />
                      )}
                    </div>
                  </div>
                ))}
              </div>
              <div className="flex w-[20%] m-auto justify-evenly items-center mt-4">
                <button
                  onClick={() => goToPage(currentPage - 1)}
                  disabled={currentPage === 1}
                  className="hover:text-info"
                >
                  {'<'}
                </button>
                {Array.from({ length: totalPages }, (_, index) => (
                  <button
                    key={index}
                    className={`${currentPage === index + 1 ? 'text-info' : 'text-gray-700'}`}
                    onClick={() => goToPage(index + 1)}
                  >
                    {index + 1}
                  </button>
                ))}
                <button
                  onClick={() => goToPage(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  className="hover:text-info"
                >
                  {'>'}
                </button>
              </div>
            </>
          ) : (
            <div className="h-[4rem] mt-[2rem]">
              <ErrorMessage message="This user has not completed any tests!" />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default TestHistoryModal
