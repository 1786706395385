import React from 'react'
import MonitorIcon from '../../images/Landingpage/AIPteMockTest/monitor.png'
import CPUIcon from '../../images/Landingpage/AIPteMockTest/cpu.png'
import UserIcon from '../../images/Landingpage/AIPteMockTest/users.png'
import AutomateScoringTestFormat from '../../components/LandingPage/AutomateScoring/AutomateScoringTestFormat'
import SkillCTASection from '../../components/LandingPage/AcademicSkill/SkillCTASection'

const AIPteMockTestPage: React.FC = () => {
  const skillTestTabs = [
    {
      id: 'test-format',
      label: 'Test Format',
      content: (
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
          {[
            {
              title: 'PTE Academic Speaking (30 - 35 minutes)',
              description: [
                '1. Personal introduction',
                '2. Read aloud',
                '3. Repeat sentence',
                '4. Describe image',
                '5. Re-tell lecture',
                '6. Answer short question',
                '7. Summarize written text Essay',
              ],
            },
            {
              title: 'PTE Academic Reading (32 - 40 minutes)',
              description: [
                'Reading & writing:',
                '1. Fill in the blanks',
                '2. Multiple-choice',
                '3. Choose multiple answers',
                '4. Re-order paragraphs',
                'Reading:',
                '1. Fill in the blanks',
                '2. Multiple-choice',
                '3. Choose single answer',
              ],
            },
            {
              title: 'PTE Academic Writing (40 - 60 minutes)',
              description: ['1. Summarize Written Text', '2. Write essay'],
            },
            {
              title: 'PTE Academic Listening (45 - 57 minutes)',
              description: [
                '1. Summarize spoken text',
                '2. Multiple-choice, choose multiple answers',
                '3. Fill in the blanks',
                '4. Highlight correct summary',
                '5. Select missing word',
                '6. Highlight incorrect words',
                '7. Write from dictation',
              ],
            },
          ].map((item, index) => (
            <div
              key={index}
              className="flex flex-col justify-between group bg-white border border-gray-300 shadow-sm p-6 rounded-lg hover:shadow-lg hover:bg-gradient-to-b hover:from-[#2054E9] hover:to-[#2C62FF] hover:text-[#FFFFFF]"
            >
              <div>
                <p className="text-h4m transition-all duration-300">
                  {item.title}
                </p>
                <ul className="list-none space-y-2 mt-2">
                  {item.description.map((point, idx) => (
                    <li
                      key={idx}
                      className="text-gray-700 text-sm transition-all duration-300 group-hover:text-[#FFFFFF]"
                    >
                      {point}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="flex justify-end">
                <button className="mt-6 px-6 py-2 bg-blue-600 text-white font-medium rounded-full hover:bg-blue-700 group-hover:bg-[#FFFFFF] flex items-center space-x-2 transition-all duration-300">
                  <span className="text-bodyr transition-all duration-300 group-hover:text-[#2054E9]">
                    Read More
                  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 transition-all duration-300 group-hover:text-[#2054E9]"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </button>
              </div>
            </div>
          ))}
        </div>
      ),
    },
  ]
  return (
    <div className="w-full bg-gray-50 py-12">
      <div className="mx-auto max-w-[80%] lg:px-8 flex flex-col lg:flex-row gap-12">
        {/* Left Section */}
        <div className="lg:w-[50%] w-full">
          <h1 className="text-h1 font-bold lg:w-[301px] w-full ">
            WHY PREPARE USING AI PTE Mock TESTS?
          </h1>
        </div>

        {/* Right Section */}
        <div className="lg:w-[50%] w-full">
          <h2 className="text-h3m text-gray-800 mb-[24px]">
            AI scored PTE practice & mock test system with instant and accurate
            results.
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-[24px]">
            <div className="py-[43.5px] px-[12px] bg-[#F5F5F5] rounded-[8px] flex items-center gap-4">
              <p className="text-h4m">Gain real test-like experience</p>
            </div>
            <div className="py-[43.5px] px-[12px] bg-[#F5F5F5] rounded-[8px] flex items-center gap-4">
              <p className="text-h4m">Boost Confidence</p>
            </div>
            <div className="py-[43.5px] px-[12px] bg-[#F5F5F5] rounded-[8px] flex items-center gap-4">
              <p className="text-h4m">Practice From Anywhere Anytime</p>
            </div>
            <div className="py-[43.5px] px-[12px] bg-[#F5F5F5] rounded-[8px] flex items-center gap-4">
              <p className="text-h4m">Avoid mistakes</p>
            </div>
          </div>
          <p className="text-bodyr px-[12px] pt-[12px] leading-relaxed bg-[#F5F5F5] rounded-[8px]">
            If any students have plans to move to Australia, New Zealand or the
            UK then they must have a clear knowledge of the English language. So
            the PTE Academic is a 100% recognised test of English language
            skills and accepted by a wide network of universities globally and
            for visa purposes.
          </p>
          <p className="text-bodyr px-[12px] leading-relaxed bg-[#F5F5F5]">
            We know almost every student is tense before giving any exam even if
            it is PTE, so we provide PTE mock tests to help students find the
            right process for the exam and bring out their confidence. PTE
            Master provides PTE Academic Mock Tests Practice platform designed
            with AI features to simulate the actual PTE Exam testing experience.
          </p>
          <p className="text-bodyr px-[12px] pb-[12px] leading-relaxed bg-[#F5F5F5] rounded-[8px]">
            The Structure of PTE Academic Mock Test has four modules: Listening,
            Reading, Writing & Speaking and evaluated using AI scoring systems.
            The AI-based PTE mock test includes only those questions and answers
            which have the highest probability of appearing in the real test.
          </p>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mt-6">
            <div
              className="p-4  text-white rounded-lg shadow-md items-start gap-4"
              style={{
                background:
                  'linear-gradient(360deg, #2054E9 -0.54%, #2C62FF 100%)',
              }}
            >
              <img src={MonitorIcon} alt="Monitor Icon" className="w-8 h-8" />
              <div>
                <h3 className="text-h4 font-bold mb-2">Time Based Mock Test</h3>
                <p className="text-bodyr">
                  PTE Mock Test is a 2-hour computer-based test just like the
                  real PTE-A test so students have to manage test time, the
                  number of questions attempted, and more.
                </p>
              </div>
            </div>
            <div
              className="p-4  text-white rounded-lg shadow-md items-start gap-4"
              style={{
                background:
                  'linear-gradient(360deg, #2054E9 -0.54%, #2C62FF 100%)',
              }}
            >
              <img src={CPUIcon} alt="Score Icon" className="w-8 h-8" />
              <div>
                <h3 className="text-h4 font-bold mb-2">
                  Scorecard Report with Details
                </h3>
                <p className="text-bodyr">
                  AI-based score reports will be automatically generated after
                  attempting each PTE academic exam, helping users identify
                  strengths and tackle weaknesses.
                </p>
              </div>
            </div>
            <div
              className="p-4  text-white rounded-lg shadow-md items-start gap-4"
              style={{
                background:
                  'linear-gradient(360deg, #2054E9 -0.54%, #2C62FF 100%)',
              }}
            >
              <img src={CPUIcon} alt="CPU Icon" className="w-8 h-8" />
              <div>
                <h3 className="text-h4 font-bold mb-2">Compatibility</h3>
                <p className="text-bodyr">
                  We recommend Google Chrome to get a smooth experience for
                  Windows, Mac, and Linux operating systems.
                </p>
              </div>
            </div>
            <div
              className="p-4 text-white rounded-lg shadow-md items-start gap-4"
              style={{
                background:
                  'linear-gradient(360deg, #2054E9 -0.54%, #2C62FF 100%)',
              }}
            >
              <img src={UserIcon} alt="User Icon" className="w-8 h-8" />
              <div>
                <h3 className="text-h4 font-bold mb-2">
                  Each Mock Test with Instant Scorecard
                </h3>
                <p className="text-bodyr">
                  Using authentic PTE algorithms, we provide instant scores for
                  your PTE mock tests with a full analysis of each section for a
                  thorough review.
                </p>
              </div>
            </div>
          </div>{' '}
        </div>
      </div>

      <AutomateScoringTestFormat tabs={skillTestTabs} />
      {/* Test Format Section */}

      {/* Call-to-Action Section */}
      <SkillCTASection />
    </div>
  )
}

export default AIPteMockTestPage
