import PracticeListeningResult from '../../models/practiceListeningResult.model'
import { formatScore } from '../../utils/utils'

interface ListeningFinalResultProps {
  finalResult: PracticeListeningResult | undefined
}

const ListeningFinalResult: React.FC<ListeningFinalResultProps> = ({
  finalResult,
}) => {
  const listeningFinalMarks = [
    {
      name: 'Grammar',
      score: `${finalResult ? formatScore(finalResult.grammar) : 0}/2`,
    },
    {
      name: 'Content',
      score: `${finalResult ? formatScore(finalResult.content) : 0}/2`,
    },
    {
      name: 'Spelling',
      score: `${finalResult ? formatScore(finalResult.spelling) : 0}/2`,
    },
    {
      name: 'Form',
      score: `${finalResult ? formatScore(finalResult.form) : 0}/2`,
    },
    {
      name: 'Vocabulary',
      score: `${finalResult ? formatScore(finalResult.vocabulary) : 0}/2`,
    },
    {
      name: 'Final',
      score: `${finalResult ? Math.round(finalResult.marks) : 10}/90`,
    },
  ]

  return (
    <div className="bg-gray-100 rounded-xl py-4 grid lg:grid-cols-6 md:grid-cols-4 sm:grid-cols-3 grid-cols-2 gap-6 mt-4 place-items-center">
      {listeningFinalMarks.map((mark, index) => (
        <div key={index} className="flex flex-col items-center">
          <p className="text-center text-gray-600 font-medium mb-2">
            {mark.name}
          </p>
          <div className="flex flex-col items-center justify-center rounded-full bg-primary-2 text-white w-[4rem] h-[4rem] shadow-md">
            <p className="text-h5b">{mark.score}</p>
          </div>
        </div>
      ))}
    </div>
  )
}

export default ListeningFinalResult
