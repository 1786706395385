import { QuestionModelId } from '../../models/QuestionModels'
import OtherReadingQuestionTypeList from './OtherReadingQuestionTypeList'
import ReOrderParagraphList from './ReOrderParagraphQuestionList'

interface SpeakingQuestionListProps {
  questionType: QuestionModelId
  practiceType: number
  labelType: number
}

const ReadingQuestionList: React.FC<SpeakingQuestionListProps> = ({
  questionType,
  practiceType,
  labelType,
}) => {
  return (
    <>
      {questionType === QuestionModelId.R_ReorderParagraphs ? (
        <ReOrderParagraphList
          categoryType={questionType}
          practiceType={practiceType}
          labelType={labelType}
        />
      ) : (
        <OtherReadingQuestionTypeList
          categoryType={questionType}
          practiceType={practiceType}
          labelType={labelType}
        />
      )}
    </>
  )
}

export default ReadingQuestionList
