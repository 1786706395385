import { Link, useSearchParams } from 'react-router-dom'
import Path from '../routes/Path'
import {
  getUserFromOrderId,
  orderPlanSuccessCallback,
  orderSuccessCallback,
} from '../services/cart.services'

const PurchaseSucceedPage = () => {
  const [searchParams] = useSearchParams()
  // console.log(searchParams.get("token"));
  const orderId = searchParams.get('token') || ''
  if (orderId) {
    const handleOrderSuccessCallback = async () => {
      const ordderData = await getUserFromOrderId(orderId)
      // console.log(ordderData.data.purchase_units[0].description.split('@@')[1]);
      if (ordderData.data.purchase_units[0].reference_id) {
        const randomString: string =
          ordderData.data.purchase_units[0].reference_id
        const tokenId: number =
          ordderData.data.purchase_units[0].description.split('@@')[0]
        const userId: number = Number(
          ordderData.data.purchase_units[0].description.split('@@')[1],
        )
        const codeId: number = Number(
          ordderData.data.purchase_units[0].description.split('@@')[2],
        )

        await orderPlanSuccessCallback(randomString, tokenId, userId, codeId)
      } else {
        const cartIds: number[] = ordderData.data.purchase_units[0].description
          .split('@@')[0]
          .split(',')
          .map(Number)
        const userId: number = Number(
          ordderData.data.purchase_units[0].description.split('@@')[1],
        )

        await orderSuccessCallback(cartIds, userId)
      }
    }
    handleOrderSuccessCallback()
  }

  return (
    <>
      <div className="w-full min-h-[80vh] lg:p-[4rem] p-[1rem] bg-[#F0F7FF] rounded-l-lg flex flex-col gap-2 items-center justify-center">
        <svg
          width="60px"
          height="60px"
          viewBox="0 0 1024 1024"
          className="icon"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M192 938.7H87.9c-48.4 0-87.9-39.5-87.9-88V386.6c0-48.5 39.5-87.9 87.9-87.9h125.4c11.6 0 22.7 4.7 30.8 13.1 8 8.4 12.3 19.6 11.9 31.2l-21.3 554.7c-0.9 22.8-19.8 41-42.7 41zM87.9 384c-1.4 0-2.6 1.2-2.6 2.6v464.1c0 1.4 1.2 2.6 2.6 2.6h63L169 384H87.9z"
            fill="#5F6379"
          />
          <path
            d="M810.4 938.7H275.7l24.6-640H418l72-201.8C510.7 38.9 566 0 627.5 0c42.4 0 82.6 18.4 110.3 50.4S778 124.8 772 166.7l-18.9 132h142.6c70.7 0 128.2 57.5 128.2 128.2l-1 9.3-84.4 379.4c-2.6 68.3-59.1 123.1-128.1 123.1z m-446.1-85.4h446.1c23.6 0 42.9-19.2 42.9-42.9l1-9.3L938.5 423c-2-21.8-20.4-39-42.7-39h-241l32.8-229.4c2.5-17.7-2.5-34.8-14.2-48.3s-28-20.9-45.9-20.9c-25.6 0-48.5 16.2-57.1 40.3L478.1 384h-95.7l-18.1 469.3z"
            fill="#3688FF"
          />
        </svg>
        <p className="text-h2">
          Your payment is successful, click{' '}
          <Link
            className="text-green-700 bg-yellow-200 underline"
            to={Path.yourPurchase.path}
          >
            Here
          </Link>{' '}
          to view your purchase!!!
        </p>
      </div>
    </>
  )
}

export default PurchaseSucceedPage
