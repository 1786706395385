import React, { useState } from 'react'
import BlogImage1 from '../../images/Landingpage/Blog/blog-1.png'
import BlogImage2 from '../../images/Landingpage/Blog/blog-2.png'
import BlogImage3 from '../../images/Landingpage/Blog/blog-3.png'
import BlogImage4 from '../../images/Landingpage/Blog/blog-4.png'
import BlogImage5 from '../../images/Landingpage/Blog/blog-5.png'
import BlogImage6 from '../../images/Landingpage/Blog/blog-6.png'
import SubscribeForm from '../../components/LandingPage/SubcribeForm'
import BlogList from '../../components/LandingPage/BlogList'
import SkillCTASection from '../../components/LandingPage/AcademicSkill/SkillCTASection'

const BlogPage = () => {
  // State for the active tab
  const [activeTab, setActiveTab] = useState('PTE Coaching')

  // Tabs data
  const tabs = [
    { name: 'PTE Coaching', count: 14 },
    { name: 'PTE Migration', count: 2 },
    { name: 'PTE Test', count: 2 },
  ]

  // Blog data
  const blogs = [
    {
      slug: 'top-tips-for-agents',
      title: 'Top Tips for Agents: Avoiding Common Buyer Pitfalls',
      image: BlogImage1,
      time: '6 min read',
    },
    {
      slug: 'mastering-test-prep',
      title: 'Mastering Test Prep: Strategies for Success',
      image: BlogImage2,
      time: '8 min read',
    },
    {
      slug: 'pte-coaching-techniques',
      title: 'A Guide to PTE Coaching Techniques',
      image: BlogImage3,
      time: '5 min read',
    },
    {
      slug: 'understanding-migration-rules',
      title: 'Understanding Migration Rules',
      image: BlogImage4,
      time: '7 min read',
    },
    {
      slug: 'boost-your-test-score',
      title: 'Boost Your Test Score with These Tips',
      image: BlogImage5,
      time: '6 min read',
    },
    {
      slug: 'key-migration-insights',
      title: 'Key Migration Insights for Agents',
      image: BlogImage6,
      time: '9 min read',
    },
  ]

  return (
    <div>
      <div className="w-full mx-auto px-2 sm:px-6 lg:px-12 py-6 lg:py-12 flex flex-col lg:flex-row lg:gap-12 xl:gap-24">
        {/* Blog Section */}
        <div className="w-full lg:w-2/3">
          <BlogList
            tabs={tabs}
            blogs={blogs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </div>

        {/* Right Section */}
        <div className="w-full lg:w-1/3 space-y-6">
          {/* Subscribe Form */}
          <SubscribeForm />
        </div>
      </div>

      {/* CTA Section */}
      <SkillCTASection />
    </div>
  )
}

export default BlogPage
