import PlanBundleSection from './PlanBundleSection'

interface ShopSectionProps {
  tokenPackage: any
}
const PlanPageSection: React.FC<ShopSectionProps> = ({ tokenPackage }) => {
  console.log('tokenPackage', tokenPackage)

  return (
    <div className="shop-section">
      <p className="text-h1 font-landingPrimary mb-[0.4rem]">
        Select Your Plan
      </p>
      <p className="text-bodyr text-neutrals-2 lg:w-[25.875rem] font-landingPrimary">
        Subscribe to our VIP package will grant unlimited scoring for practice
        and allow full access to all prediction files and templates
      </p>
      <div className="shop-section__list-items mt-[2rem] mt-[3rem]">
        <PlanBundleSection tokenPackage={tokenPackage} />
      </div>
    </div>
  )
}

export default PlanPageSection
