import { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ReactComponent as RightArrowIcon } from '../../../images/SystemRequirement/sr-right-arrow-icon.svg'
import Path from '../../../routes/Path'
import { remvoveTestInformation } from '../../../utils/utils'
import {
  startTestNow3,
  doTest3,
} from '../../../services/sectionalTest.services'
import ErrorMessage from '../../../components/Items/ErrorMessage'
import LoadingMessage from '../../../components/Items/LoadingMessage'
import { useAuth } from '../../../providers/AuthProvider'

const SRSectionaWritingPage = () => {
  const [buyId, setBuyId] = useState<number>(0)
  const [testId, setTestId] = useState<number>(0)
  const [isLoading, setIsLoading] = useState(true)
  const [errorMsg, setErrorMsg] = useState('')
  const navigate = useNavigate()
  const { userProfile } = useAuth()

  useEffect(() => {
    //Remove the previous test at localStorage first
    remvoveTestInformation(4)

    const fetchStartTestNow3 = async () => {
      if (userProfile && userProfile.userId) {
        try {
          const response = await startTestNow3(userProfile.userId)
          if (response.data.success) {
            localStorage.setItem('buyIdWPTESectional', response.data.buyid)
            localStorage.setItem('testIdWPTESectional', response.data.testid)
            setBuyId(response.data.buyid)
            setTestId(response.data.testid)
          } else {
            setErrorMsg(response.data.message)
          }
        } catch (error) {
          if (error instanceof TypeError) {
            setErrorMsg(error.message)
          } else {
            setErrorMsg('Something went wrong!')
          }
        } finally {
          setTimeout(() => {
            setIsLoading(false)
          }, 2000)
        }
      } else {
        console.error('User not found!')
      }
    }

    fetchStartTestNow3()
  }, [userProfile])

  const handleContinue = async () => {
    if (userProfile && userProfile.userId) {
      try {
        const response = await doTest3(buyId, testId, userProfile.userId)
        if (response.data.success) {
          localStorage.setItem(
            'resultSectionalWPTE',
            JSON.stringify(response.data.result),
          )
          if (response.data.category_id === '10') {
            navigate(Path.sectionalTest.summarizeWrittenText.path)
          }

          if (response.data.category_id === '11') {
            navigate(Path.sectionalTest.writeEssay.path)
          }
        } else {
          console.error(response.data.message)
        }
      } catch (error) {
        if (error instanceof TypeError) {
          console.error(error.message)
        } else {
          console.error('Something went wrong!')
        }
      }
    } else {
      console.error('User not found!')
    }
  }

  return (
    <>
      <div className="navigation my-[1rem]">
        <p className="text-bodyr font-landingPrimary text-neutrals-2">
          <Link to="/homepage">Home</Link> / Writing Sectional Test / System
          Requirement
        </p>
      </div>
      <div className="w-full min-h-[80vh] lg:p-[4rem] p-[1rem] bg-white rounded-l-lg">
        {isLoading ? (
          <LoadingMessage message="Generating questions... Please wait..." />
        ) : errorMsg ? (
          <ErrorMessage message={errorMsg} />
        ) : (
          <div className="sr-sectional-writing-section font-landingPrimary">
            <p className="sr-sectional-writing-section__title text-h1 text-info">
              Writing Sectional Test
            </p>
            <p className="sr-sectional-writing-section__description text-bodyr text-neutrals-2 lg:w-[36.188rem] w-full mt-[1rem]">
              Each time you create a sectional test, the system will randomly
              pick the questions for you. Every new test will come with
              different sets of questions to ensure complete randomness when it
              comes to practicing. This single sectional test will only examine
              your speaking skill, if you wish to have all of your 4 skills
              evaluated at one time, please purchase a full mock test.
            </p>
            <p className="sr-sectional-speaking-section__instruction text-bodyr mt-[1rem]">
              To start, click the <span className="font-bold">"Record"</span>{' '}
              button below to test your microphone, then click{' '}
              <span className="font-bold">"Continue”</span> to sectional test.
            </p>
            <div className="sr-sectional-speaking-section__micro-check text-bodyr lg:w-[37.188rem] w-full my-[1rem]">
              <p className="text-h5m text-neutrals-2 mb-[1rem]">
                MICROPHONE CHECK
              </p>
              <p>
                This is an opportunity to check if your microphone is working
                properly.
              </p>
              <ol className="list-decimal text-bodyr pl-[1rem] lg:w-[36.188rem]">
                <li className="mb-[0.6rem]">
                  Make sure your headset is on and the microphone is in the
                  downward position near your mouth.
                </li>
                <li className="mb-[0.6rem]">
                  Click on the <span className="font-bold">“Record”</span>{' '}
                  button and say{' '}
                  <span className="font-bold">
                    “Testing, testing, one, two, three”
                  </span>{' '}
                  into the microphone.
                </li>
                <li className="mb-[0.6rem]">
                  After you have spoken, click on the{' '}
                  <span className="font-bold">“Stop”</span> button. Your
                  recording is now complete.
                </li>
                <li className="mb-[0.6rem]">
                  Now click on the <span className="font-bold">“Playback”</span>{' '}
                  button. You should clearly hear yourself speaking.
                </li>
                <li className="mb-[0.6rem]">
                  If you cannot hear your voice clearly, please raise your hand.
                </li>
              </ol>
              <p className="text-bodyr text-neutrals-2 mt-[1rem]">
                During the test, you will not have{' '}
                <span className="font-bold">“Record”</span>,
                <span className="font-bold">“Playback”</span> and{' '}
                <span className="font-bold">“Stop”</span> buttons. The voice
                recording will start automatically.
              </p>
            </div>
            <div className="sr-sectional-writing-section__audio-record mt-[1rem]">
              <button
                className="mt-[1rem] lg:w-[8.625rem] w-full h-[2.5rem] bg-info hover:bg-[#1890FF] text-h5r text-white flex justify-center items-center gap-2 rounded-lg"
                onClick={handleContinue}
              >
                <RightArrowIcon />
                Continue
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default SRSectionaWritingPage
