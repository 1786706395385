import React, { useState } from 'react'
import QuestionModalAccordion from '../../components/QuestionModalAccordion'
import SpeakingQuestionList from '../../components/SpeakingCourse/SpeakingQuestionList'
import WritingQuestionList from '../../components/WritingCourse/WritingQuestionList'
import ReadingQuestionList from '../../components/ReadingCourse/ReadingQuestionList'
import ListeningQuestionList from '../../components/ListeningCourse/ListeningQuestionList'
import { QuestionModelId } from '../../models/QuestionModels'

const practiceTypes = [
  {
    id: 0,
    value: 'All Questions',
  },
  {
    id: 1,
    value: 'Attempted questions',
  },
  {
    id: 2,
    value: 'Not attempted questions',
  },
]

const labelTypes = [
  {
    id: 0,
    value: 'All',
    color: '#808080',
  },
  {
    id: 4,
    value: 'Easy',
    color: '#28A745',
  },
  {
    id: 3,
    value: 'Normal',
    color: '#007BFF',
  },
  {
    id: 2,
    value: 'Hard',
    color: '#FFC107',
  },
  {
    id: 1,
    value: 'No Idea',
    color: '#FF4B5A',
  },
]

const speakingTypeLists = [5, 6, 7, 8, 9]
const writingTypeLists = [10, 11]
const readingTypeLists = [12, 13, 14, 15, 16]
const listeningTypeLists = [17, 18, 19, 20, 21, 22, 23, 24]

const WeeklyPredictionCourse = () => {
  let questionTypePTE = localStorage.getItem('questionTypePTE')

  const [questionType, setQuestionType] = useState<QuestionModelId>(
    (questionTypePTE as QuestionModelId) || QuestionModelId.S_ReadAloud,
  )
  const [practiceSelection, setPracticeSelection] = useState<number>(0)
  const [labelSelection, setLabelSelection] = useState<number>(0)

  const handlePracticeChange: React.ChangeEventHandler<HTMLInputElement> = (
    event,
  ) => {
    const selectedValue = event.target.value
    setPracticeSelection(Number(selectedValue))
    // localStorage.setItem('ptePracticeQuestionAttempt', selectedValue.toString());
  }

  const handleTypeChange: React.ChangeEventHandler<HTMLInputElement> = (
    event,
  ) => {
    const selectedValue = event.target.value
    setLabelSelection(Number(selectedValue))
    // localStorage.setItem('ptePracticeQuestionLabel', labelSelection.toString())
  }

  const displayPracticeTitle = (): string => {
    const typeMapping: { [key: number]: string } = {
      ...speakingTypeLists.reduce(
        (acc, type) => ({ ...acc, [type]: 'Speaking' }),
        {},
      ),
      ...writingTypeLists.reduce(
        (acc, type) => ({ ...acc, [type]: 'Writing' }),
        {},
      ),
      ...readingTypeLists.reduce(
        (acc, type) => ({ ...acc, [type]: 'Reading' }),
        {},
      ),
      ...listeningTypeLists.reduce(
        (acc, type) => ({ ...acc, [type]: 'Listening' }),
        {},
      ),
    }

    return typeMapping[Number(questionType)] || 'Other'
  }

  return (
    <div>
      <div className="bg-primary-9 h-[320px] content-center text-center">
        <p className="text-h1 text-primary-1">{displayPracticeTitle()} Test</p>
        <p className="text-bodyr text-primary-2 pt-8 lg:mx-[20%] mx-4">
          Welcome to the practice test section! Here, you'll find a variety of
          exercises designed to help you prepare effectively. Our goal is to
          provide you with the tools you need to succeed, ensuring that you feel
          confident and ready for the real test. Dive in and start practicing
          your skills today!
        </p>
      </div>
      <div className="p-4 xl:p-10 grid grid-cols-8 gap-8">
        <div className="col-span-8 lg:col-span-2 flex flex-col-reverse lg:flex-col">
          <div className="lg:border-b border-[2px]-[#BFBFBF] pb-2 pt-2 lg:pt-0">
            <p className="text-h5m uppercase">Practice</p>
            {practiceTypes.map((practiceType, index) => (
              <div className="flex items-center my-2" key={index}>
                <input
                  data-testid={`option-${index}`}
                  type="radio"
                  id={practiceType.value}
                  className="h-4 w-4"
                  name="practice"
                  value={practiceType.id}
                  checked={practiceSelection === practiceType.id}
                  onChange={handlePracticeChange}
                />
                <label htmlFor={practiceType.value} className="text-bodyr pl-2">
                  {practiceType.value}
                </label>
              </div>
            ))}
          </div>
          <div className="border-b border-[2px]-[#BFBFBF] py-2">
            <p className="text-h5m uppercase">Type</p>
            {labelTypes.map((labelType, index) => (
              <div className="flex items-center my-2" key={index}>
                <input
                  data-testid={`option-${index}`}
                  type="radio"
                  id={labelType.value}
                  className="h-4 w-4"
                  name="type"
                  value={labelType.id}
                  checked={labelSelection === labelType.id}
                  onChange={handleTypeChange}
                />
                <label
                  htmlFor={labelType.value}
                  style={{ color: labelType.color }}
                  className="text-bodyr pl-2"
                >
                  {labelType.value}
                </label>
              </div>
            ))}
          </div>
          <div>
            <p className="text-h5m uppercase pt-2">Zone</p>
            <QuestionModalAccordion setQuestionType={setQuestionType} />
          </div>
        </div>

        <div className="col-span-8 lg:col-span-6">
          {[
            QuestionModelId.S_ReadAloud,
            QuestionModelId.S_RepeatSentence,
            QuestionModelId.S_DescribeImage,
            QuestionModelId.S_RetellLecture,
            QuestionModelId.S_AnswerShortQuestion,
          ].includes(questionType) ? (
            <SpeakingQuestionList
              questionType={questionType}
              practiceType={practiceSelection}
              labelType={labelSelection}
            />
          ) : [
              QuestionModelId.W_SummarizeWrittenText,
              QuestionModelId.W_WriteEssay,
            ].includes(questionType) ? (
            <WritingQuestionList
              questionType={questionType}
              practiceType={practiceSelection}
              labelType={labelSelection}
            />
          ) : [
              QuestionModelId.R_ReorderParagraphs,
              QuestionModelId.R_ReadingFillInTheBlanks,
              QuestionModelId.R_ReadingAndWritingFillInTheBlanks,
            ].includes(questionType) ? (
            <ReadingQuestionList
              questionType={questionType}
              practiceType={practiceSelection}
              labelType={labelSelection}
            />
          ) : [
              QuestionModelId.L_SummarizeSpokenText,
              QuestionModelId.L_FillInTheBlanks,
              QuestionModelId.L_HighlightIncorrectWords,
              QuestionModelId.L_WriteFromDictation,
            ].includes(questionType) ? (
            <ListeningQuestionList
              questionType={questionType}
              practiceType={practiceSelection}
              labelType={labelSelection}
            />
          ) : (
            <p>Sorry, this option isn't available at the moment!</p>
          )}
        </div>
      </div>
    </div>
  )
}

export default WeeklyPredictionCourse
