import { useEffect, useState } from 'react'
import WeeklyPredictionWhiteArrowSvg from '../images/WeeklyPrediction/weekly-prediction-white-arrow.svg'
import WeeklyPredictionGreyArrowSvg from '../images/WeeklyPrediction/weekly-prediction-grey-arrow.svg'
import {
  Category2,
  QuestionModel,
  QuestionModelId,
  SkillName,
} from '../models/QuestionModels'

type QuestionModalAccordionProps = {
  setQuestionType: (value: QuestionModelId) => void
}

// const categories: Category[] = [
//   {
//     name: SkillName.Speaking,
//     questionModels: [
//       QuestionModel.S_ReadAloud,
//       QuestionModel.S_RepeatSentence,
//       QuestionModel.S_DescribeImage,
//       QuestionModel.S_RetellLecture,
//       QuestionModel.S_AnswerShortQuestion,
//     ],
//   },
//   {
//     name: SkillName.Writing,
//     questionModels: [
//       QuestionModel.W_SummarizeWrittenText,
//       QuestionModel.W_WriteEssay,
//     ],
//   },
//   {
//     name: SkillName.Reading,
//     questionModels: [
//       QuestionModel.R_MultipleChoiceChooseSingleAnswer,
//       QuestionModel.R_MultipleChoiceChooseMultipleAnswers,
//       QuestionModel.R_ReorderParagraphs,
//       QuestionModel.R_ReadingFillInTheBlanks,
//       QuestionModel.R_ReadingAndWritingFillInTheBlanks,
//     ],
//   },
//   {
//     name: SkillName.Listening,
//     questionModels: [
//       QuestionModel.L_SummarizeSpokenText,
//       QuestionModel.L_MultipleChoiceChooseMultipleAnswers,
//       QuestionModel.L_FillInTheBlanks,
//       QuestionModel.L_HighlightCorrectSummary,
//       QuestionModel.L_MultipleChoiceChooseSingleAnswer,
//       QuestionModel.L_SelectMissingWord,
//       QuestionModel.L_HighlightIncorrectWords,
//       QuestionModel.L_WriteFromDictation,
//     ],
//   },
// ]

const categories2: Category2[] = [
  {
    name: SkillName.Speaking,
    questionModels: [
      {
        id: QuestionModelId.S_ReadAloud,
        name: QuestionModel.S_ReadAloud,
      },
      {
        id: QuestionModelId.S_RepeatSentence,
        name: QuestionModel.S_RepeatSentence,
      },
      {
        id: QuestionModelId.S_DescribeImage,
        name: QuestionModel.S_DescribeImage,
      },
      {
        id: QuestionModelId.S_RetellLecture,
        name: QuestionModel.S_RetellLecture,
      },
      {
        id: QuestionModelId.S_AnswerShortQuestion,
        name: QuestionModel.S_AnswerShortQuestion,
      },
    ],
  },
  {
    name: SkillName.Writing,
    questionModels: [
      {
        id: QuestionModelId.W_SummarizeWrittenText,
        name: QuestionModel.W_SummarizeWrittenText,
      },
      {
        id: QuestionModelId.W_WriteEssay,
        name: QuestionModel.W_WriteEssay,
      },
    ],
  },
  {
    name: SkillName.Reading,
    questionModels: [
      {
        id: QuestionModelId.R_MultipleChoiceChooseSingleAnswer,
        name: QuestionModel.R_MultipleChoiceChooseSingleAnswer,
      },
      {
        id: QuestionModelId.R_MultipleChoiceChooseMultipleAnswers,
        name: QuestionModel.R_MultipleChoiceChooseMultipleAnswers,
      },
      {
        id: QuestionModelId.R_ReorderParagraphs,
        name: QuestionModel.R_ReorderParagraphs,
      },
      {
        id: QuestionModelId.R_ReadingFillInTheBlanks,
        name: QuestionModel.R_ReadingFillInTheBlanks,
      },
      {
        id: QuestionModelId.R_ReadingAndWritingFillInTheBlanks,
        name: QuestionModel.R_ReadingAndWritingFillInTheBlanks,
      },
    ],
  },
  {
    name: SkillName.Listening,
    questionModels: [
      {
        id: QuestionModelId.L_SummarizeSpokenText,
        name: QuestionModel.L_SummarizeSpokenText,
      },
      {
        id: QuestionModelId.L_MultipleChoiceChooseMultipleAnswers,
        name: QuestionModel.L_MultipleChoiceChooseMultipleAnswers,
      },
      {
        id: QuestionModelId.L_FillInTheBlanks,
        name: QuestionModel.L_FillInTheBlanks,
      },
      {
        id: QuestionModelId.L_HighlightCorrectSummary,
        name: QuestionModel.L_HighlightCorrectSummary,
      },
      {
        id: QuestionModelId.L_MultipleChoiceChooseSingleAnswer,
        name: QuestionModel.L_MultipleChoiceChooseSingleAnswer,
      },
      {
        id: QuestionModelId.L_SelectMissingWord,
        name: QuestionModel.L_SelectMissingWord,
      },
      {
        id: QuestionModelId.L_HighlightIncorrectWords,
        name: QuestionModel.L_HighlightIncorrectWords,
      },
      {
        id: QuestionModelId.L_WriteFromDictation,
        name: QuestionModel.L_WriteFromDictation,
      },
    ],
  },
]

const QuestionModalAccordion: React.FC<QuestionModalAccordionProps> = ({
  setQuestionType,
}) => {
  const [activeCategory, setActiveCategory] = useState('')
  const [activeQuestionModel, setActiveQuestionModel] = useState('')

  const handleTabClick = (categoryName: SkillName) => {
    if (activeCategory === categoryName) {
      setActiveCategory('')
      return
    }
    setActiveCategory(categoryName)
  }

  const handleModelClick = (questionModel: any) => {
    setActiveQuestionModel(questionModel.name)
    setQuestionType(questionModel.id as QuestionModelId)
    localStorage.setItem('questionTypePTE', String(questionModel.id))
  }

  // useEffect(() => {
  //   const questionTypePTE = localStorage.getItem('questionTypePTE') || ''
  //   if (questionTypePTE) {
  //     categories.forEach((category) => {
  //       if (
  //         category.questionModels.includes(questionTypePTE as QuestionModel)
  //       ) {
  //         setActiveCategory(category.name)
  //         setActiveQuestionModel(questionTypePTE)
  //       }
  //     })
  //   }
  // }, [])

  useEffect(() => {
    const questionTypePTE = localStorage.getItem('questionTypePTE') || ''
    if (questionTypePTE) {
      let foundCategoryName: SkillName | null = null
      let foundQuestionModelName: QuestionModel | null = null

      categories2.some((category) => {
        const foundModel = category.questionModels.find(
          (questionModel) => questionModel.id === questionTypePTE,
        )

        if (foundModel) {
          foundCategoryName = category.name
          foundQuestionModelName = foundModel.name
          return true // Stop searching once found
        }

        return false // Continue searching
      })

      if (foundCategoryName && foundQuestionModelName) {
        setActiveCategory(foundCategoryName)
        setActiveQuestionModel(foundQuestionModelName)
      }
    }
  }, [])

  return (
    <div>
      {categories2.map((category, index) => (
        <div
          key={index}
          className="cursor-pointer border border-[1px]-[#0000000F] my-2 rounded-lg"
        >
          <div
            className={`flex justify-between items-center p-4 rounded-t-lg
              ${activeCategory === category.name ? 'bg-primary-6 text-white' : 'text-neutrals-2'}
              `}
            onClick={() => handleTabClick(category.name)}
          >
            <p className={`text-bodyr`}>{category.name}</p>
            <img
              src={
                activeCategory === category.name
                  ? WeeklyPredictionWhiteArrowSvg
                  : WeeklyPredictionGreyArrowSvg
              }
              className={`transition-transform duration-300 ${activeCategory === category.name ? 'rotate-90' : 'rotate-0'}`}
              alt="Arrow"
            />
          </div>
          <div
            style={{
              maxHeight:
                activeCategory === category.name
                  ? `${category.questionModels.length * 4}rem`
                  : '0',
              overflow: 'hidden',
              transition: 'max-height 0.5s ease',
            }}
          >
            {category.questionModels.map((questionModel, idx) => (
              <p
                key={idx}
                className={`text-bodyr px-4 py-2 border-b-[1px]
                  ${activeQuestionModel === questionModel.name ? 'bg-info text-white' : 'text-neutrals-1'}
                  `}
                onClick={() => handleModelClick(questionModel)}
              >
                {questionModel.name}
              </p>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}

export default QuestionModalAccordion
