import { Link } from 'react-router-dom'
import HeroSectionBanner from '../../../images/Landingpage/automate-scoring-banner.jpg'
import Path from '../../../routes/Path'

const AutomateScoringHeader: React.FC = () => {
  return (
    <div className="w-full px-6 lg:px-12 py-6 lg:py-12">
      {/* Header Section */}
      <div className="lg:w-[80%] flex flex-col lg:flex-row justify-between items-center lg:items-start mx-auto">
        <div className="lg:w-1/2 text-center lg:text-left mb-6 lg:mb-0">
          <p className="text-h1">Automated scoring test</p>
        </div>
        <div className="lg:w-1/2">
          <p className="text-h3m pb-4">
            Make your preparation easy using PTE Academic's automated scoring
            system.
          </p>
          <p className="text-bodyr text-[#595959]">
            The Best way to improve your PTE Academic Score in Listening,
            Reading, Writing and Speaking skills is using our automated machine
            scoring PTE mock tests. It is scored based on original algorithms of
            Pearson Test of English & provide accurate feedbacks.
          </p>
        </div>
      </div>

      {/* Banner Image */}
      <div className="lg:w-[80%] hero-section__image rounded-3xl font-sans font-medium text-[15px] bg-cover bg-center lg:h-[42rem] h-[22rem] flex items-center justify-center relative mt-8 mx-auto">
        <img
          className="rounded-3xl object-cover object-center w-full h-full"
          src={HeroSectionBanner}
          alt="Hero Section Banner"
        />
        <div className="absolute bottom-0 right-0 w-1/3">
          <a
            href="/"
            className="text-sm bg-white text-landingPrimary lg:px-8 px-4 lg:py-4 py-2 rounded-tl-3xl flex items-center "
          >
            <svg
              className="mr-4 w-4 "
              viewBox="0 0 19 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.6643 9.37839L8.96627 0.961318C8.89183 0.897143 8.79685 0.861206 8.69674 0.861206H6.42498C6.23502 0.861206 6.14775 1.09737 6.2915 1.22058L15.281 9.02415H0.259129C0.146183 9.02415 0.053772 9.11656 0.053772 9.22951V10.7697C0.053772 10.8826 0.146183 10.975 0.259129 10.975H15.2784L6.28893 18.7786C6.14518 18.9044 6.23246 19.138 6.42241 19.138H8.77118C8.81996 19.138 8.86873 19.12 8.90466 19.0867L18.6643 10.6208C18.7531 10.5435 18.8244 10.4481 18.8732 10.3409C18.922 10.2338 18.9473 10.1174 18.9473 9.9996C18.9473 9.88183 18.922 9.76544 18.8732 9.65828C18.8244 9.55111 18.7531 9.45567 18.6643 9.37839Z"
                fill="url(#paint0_linear_1391_241)"
              />
              <defs></defs>
              <linearGradient
                id="paint0_linear_1391_241"
                x1="9.55216"
                y1="19.2373"
                x2="9.55216"
                y2="0.861205"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#2054E9" />
                <stop offset="1" stopColor="#2C62FF" />
              </linearGradient>
            </svg>
            <Link to={Path.landing.register.path}>
              <p className="text-info">Sign up</p>
            </Link>
          </a>
        </div>
      </div>
    </div>
  )
}

export default AutomateScoringHeader
