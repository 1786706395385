import { Line } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import MyPerformance from '../../models/myPerformance.model'
import { formatScore } from '../../utils/utils'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  ChartDataLabels,
)

interface PerformanceChartSectionProps {
  data: MyPerformance[]
}

const PerformanceChartSection: React.FC<PerformanceChartSectionProps> = ({
  data,
}) => {
  const chartData = {
    labels: data.map((d) => new Date(d.buy_date).toLocaleDateString()),
    datasets: [
      {
        label: 'Overall Total Score',
        data: data.map((d) => formatScore(Number(d.overalltotal))),
        fill: true,
        backgroundColor: 'rgba(43, 96, 255, 0.2)',
        borderColor: '#2B60FF',
        borderWidth: 2,
        tension: 0.4, // Adjust this value to increase or decrease the curvature
      },
    ],
  }

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: '#DAE6F2E5',
        },
      },
      x: {
        ticks: {
          color: '#DAE6F2E5',
        },
        border: { dash: [5, 5] }, //Make x lines dashed
        grid: {
          color: '#DAE6F24D',
        },
      },
    },
    plugins: {
      datalabels: {
        display: true,
        color: 'white',
        align: 'end' as const,
        anchor: 'end' as const,
        formatter: (value: any) => {
          return Number(value.toLocaleString()).toFixed(2)
        },
      },
      legend: {
        position: 'top' as const,
        labels: {
          color: 'white', // Change this to your desired color
          font: {
            size: 14,
            weight: 'bold' as const,
          },
        },
      },
      title: {
        display: true,
        text: 'Test Performance Over Time',
        color: '#DAE6F2E5',
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  }

  return (
    <div className="my-performance__chart-section font-landingPrimary w-full mt-[2rem] lg:h-[40rem] md:h-[30rem] h-[24rem] bg-[#101116] rounded-[1.75rem] lg:px-[1.875rem] lg:py-[1.25rem] p-[0.6rem] relative">
      <Line data={chartData} options={options} />
    </div>
  )
}

export default PerformanceChartSection
