export const labelOptions = [
  {
    id: 'l-remove',
    type: 0,
    value: 3,
    label: 'Remove Label',
    color: '#c9c5b7',
  },
  {
    id: 'l-easy',
    type: 4,
    value: 7,
    label: 'Easy',
    color: '#28A745',
  },
  {
    id: 'l-normal',
    type: 3,
    value: 6,
    label: 'Normal',
    color: '#007BFF',
  },
  {
    id: 'l-hard',
    type: 2,
    value: 5,
    label: 'Hard',
    color: '#FFC107',
  },
  {
    id: 'l-no-idea',
    type: 1,
    value: 4,
    label: 'No Idea',
    color: '#FF4B5A',
  },
]

export const categoryItems = [
  //Speaking
  {
    category: 'Repeat Sentence (S)',
    id: 6,
    parent: 1,
  },
  {
    category: 'Read Aloud (S)',
    id: 5,
    parent: 1,
  },
  {
    category: 'Describe Image (S)',
    id: 7,
    parent: 1,
  },
  {
    category: 'Re-tell Lecture (S)',
    id: 8,
    parent: 1,
  },
  {
    category: 'Answer Short Question (S)',
    id: 9,
    parent: 1,
  },
  {
    category: 'Respond to a Situation (S)',
    id: 25,
    parent: 1,
  },
  //Writing
  {
    category: 'Summarize Written Text (W)',
    id: 10,
    parent: 2,
  },
  {
    category: 'Write Essay (W)',
    id: 11,
    parent: 2,
  },
  {
    category: 'Write An Email (W)',
    id: 26,
    parent: 2,
  },
  // Reading
  {
    category: 'Re-order paragraphs (R)',
    id: 14,
    parent: 3,
  },
  {
    category: 'Multiple-choice, choose multiple answer (R)',
    id: 13,
    parent: 3,
  },
  {
    category: 'Multiple-choice, choose single answer (R)',
    id: 12,
    parent: 3,
  },
  {
    category: 'Reading: Fill in the blanks (R)',
    id: 15,
    parent: 3,
  },
  {
    category: 'Reading and writing: Fill in the blanks (R)',
    id: 16,
    parent: 3,
  },
  // Listening
  {
    category: 'Summarize Spoken Text (L)',
    id: 17,
    parent: 4,
  },
  {
    category: 'Multiple-choice, choose multiple answers (L)',
    id: 18,
    parent: 4,
  },
  {
    category: 'Select missing word (L)',
    id: 22,
    parent: 4,
  },
  {
    category: 'Highlight correct summary (L)',
    id: 20,
    parent: 4,
  },
  {
    category: 'Fill in the blanks (L)',
    id: 19,
    parent: 4,
  },
  {
    category: 'Multiple-choice, choose single answer (L)',
    id: 21,
    parent: 4,
  },
  {
    category: 'Highlight incorrect words (L)',
    id: 23,
    parent: 4,
  },
  {
    category: 'Write from dictation (L)',
    id: 24,
    parent: 4,
  },
]
