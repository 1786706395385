import React from 'react'

type FAQTestFormatProps = {
  tabs: {
    id: string
    label: string
    sublabel: string
    description: string
    content: React.ReactNode
  }
}

const FAQTestFormat: React.FC<FAQTestFormatProps> = ({ tabs }) => {
  return (
    <div className="w-full px-6 py-6 lg:py-12">
      <div className="w-full mx-auto flex flex-col lg:flex-row">
        {/* tabss Section */}
        <div className="w-full flex flex-col lg:flex-row lg:flex-col mb-6 lg:mb-0 lg:w-1/3 lg:pr-4 lg:border-r-[2px] border-gray-300 lg:justify-normal md:justify-around">
          <p key={tabs.id} className="text-h2 cursor-pointer text-black">
            {tabs.label}
          </p>
          <p className="text-h5m text-[#595959] mt-4">{tabs.sublabel}</p>
          <p className="text-bodyr w-[80%]">{tabs.description}</p>
        </div>

        {/* Content Section */}
        <div className="w-full lg:w-2/3 lg:pl-4">
          <div key={tabs.id} className="lg:pl-8 rounded-lg">
            {tabs.content}
          </div>
        </div>
      </div>
    </div>
  )
}

export default FAQTestFormat
