import { displaySkillProgressColor } from '../../utils/utils'
import ProgressTracker from '../../models/progressTracker.model'

interface PracticeSpeakingScoreSectionProps {
  progressTrackerInfo: ProgressTracker
}

const PracticeSpeakingScoreSection: React.FC<
  PracticeSpeakingScoreSectionProps
> = ({ progressTrackerInfo }) => {
  const speakingOverallInfo = [
    {
      title: 'Pronunciation',
      score: progressTrackerInfo.s_pronoun,
    },
    {
      title: 'Fluency',
      score: progressTrackerInfo.s_fluency,
    },
    {
      title: 'Content',
      score: progressTrackerInfo.s_content,
    },
    {
      title: 'Total',
      score: progressTrackerInfo.sprgrs,
    },
  ]

  const readAloudInfo = [
    {
      title: 'Pronunciation',
      score: progressTrackerInfo.spk_5_pronoun,
    },
    {
      title: 'Fluency',
      score: progressTrackerInfo.spk_5_fluency,
    },
    {
      title: 'Content',
      score: progressTrackerInfo.spk_5_content,
    },
    {
      title: 'Total',
      score: progressTrackerInfo.spk_5prgrs,
    },
  ]

  const repeatSentenceInfo = [
    {
      title: 'Pronunciation',
      score: progressTrackerInfo.spk_6_pronoun,
    },
    {
      title: 'Fluency',
      score: progressTrackerInfo.spk_6_fluency,
    },
    {
      title: 'Content',
      score: progressTrackerInfo.spk_6_content,
    },
    {
      title: 'Total',
      score: progressTrackerInfo.spk_6prgrs,
    },
  ]

  const describeImageInfo = [
    {
      title: 'Pronunciation',
      score: progressTrackerInfo.spk_7_pronoun,
    },
    {
      title: 'Fluency',
      score: progressTrackerInfo.spk_7_fluency,
    },
    {
      title: 'Content',
      score: progressTrackerInfo.spk_7_content,
    },
    {
      title: 'Total',
      score: progressTrackerInfo.spk_7prgrs,
    },
  ]

  const retellLectureInfo = [
    {
      title: 'Pronunciation',
      score: progressTrackerInfo.spk_8_pronoun,
    },
    {
      title: 'Fluency',
      score: progressTrackerInfo.spk_8_fluency,
    },
    {
      title: 'Content',
      score: progressTrackerInfo.spk_8_content,
    },
    {
      title: 'Total',
      score: progressTrackerInfo.spk_8prgrs,
    },
  ]

  return (
    <div className="practice-performance__speaking-skill-details font-landingPrimary w-full flex flex-col gap-8">
      <div className="speaking-skill-details__overall lg:h-[21.5rem] h-full rounded-[1rem] shadow-pm-card p-[1.5rem]">
        <p className="text-h4m mb-[1rem]">Speaking (Overall)</p>
        <p className="text-h5m">Total</p>
        <div className="speaking-skill-details__overall__detail w-full flex flex-col gap-2 mt-[1rem]">
          {speakingOverallInfo.map((info, index) => (
            <div
              key={index}
              className={`speaking-skill-details__overall__detail__${info.title}`}
            >
              <p className="text-footnote text-neutrals-2 mb-[0.2rem]">
                {info.title}
              </p>
              <div className="h-[1.5rem] flex gap-2">
                <div
                  style={{
                    width: `${(info.score / 90) * 100}%`,
                    backgroundColor: displaySkillProgressColor(info.score),
                  }}
                ></div>
                <span className="text-bodym">{info.score}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="speaking-skill-details__read-aloud lg:h-[21.5rem] h-full rounded-[1rem] shadow-pm-card p-[1.5rem]">
        <p className="text-h4m mb-[1rem]">Read Aloud</p>
        <p className="text-h5m">Total</p>
        <div className="speaking-skill-details__read-aloud__detail w-full flex flex-col gap-2 mt-[1rem]">
          {readAloudInfo.map((info, index) => (
            <div
              key={index}
              className={`speaking-skill-details__overall__detail__${info.title}`}
            >
              <p className="text-footnote text-neutrals-2 mb-[0.2rem]">
                {info.title}
              </p>
              <div className="h-[1.5rem] flex gap-2">
                <div
                  style={{
                    width: `${(info.score / 90) * 100}%`,
                    backgroundColor: displaySkillProgressColor(info.score),
                  }}
                ></div>
                <span className="text-bodym">{info.score}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="speaking-skill-details__repeat-sentence lg:h-[21.5rem] h-full rounded-[1rem] shadow-pm-card p-[1.5rem]">
        <p className="text-h4m mb-[1rem]">Repeat Sentence</p>
        <p className="text-h5m">Total</p>
        <div className="speaking-skill-details__repeat-sentence__detail w-full flex flex-col gap-2 mt-[1rem]">
          {repeatSentenceInfo.map((info, index) => (
            <div
              key={index}
              className={`speaking-skill-details__repeat-sentence__detail__${info.title}`}
            >
              <p className="text-footnote text-neutrals-2 mb-[0.2rem]">
                {info.title}
              </p>
              <div className="h-[1.5rem] flex gap-2">
                <div
                  style={{
                    width: `${(info.score / 90) * 100}%`,
                    backgroundColor: displaySkillProgressColor(info.score),
                  }}
                ></div>
                <span className="text-bodym">{info.score}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="speaking-skill-details__describe-image lg:h-[21.5rem] h-full rounded-[1rem] shadow-pm-card p-[1.5rem]">
        <p className="text-h4m mb-[1rem]">Describe Image</p>
        <p className="text-h5m">Total</p>
        <div className="speaking-skill-details__describe-image__detail w-full flex flex-col gap-2 mt-[1rem]">
          {describeImageInfo.map((info, index) => (
            <div
              key={index}
              className={`speaking-skill-details__describe-image__detail__${info.title}`}
            >
              <p className="text-footnote text-neutrals-2 mb-[0.2rem]">
                {info.title}
              </p>
              <div className="h-[1.5rem] flex gap-2">
                <div
                  style={{
                    width: `${(info.score / 90) * 100}%`,
                    backgroundColor: displaySkillProgressColor(info.score),
                  }}
                ></div>
                <span className="text-bodym">{info.score}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="speaking-skill-details__retell-lecture lg:h-[21.5rem] h-full rounded-[1rem] shadow-pm-card p-[1.5rem]">
        <p className="text-h4m mb-[1rem]">Retell Lecture</p>
        <p className="text-h5m">Total</p>
        <div className="speaking-skill-details__retell-lecture__detail w-full flex flex-col gap-2 mt-[1rem]">
          {retellLectureInfo.map((info, index) => (
            <div
              key={index}
              className={`speaking-skill-details__retell-lecture__detail__${info.title}`}
            >
              <p className="text-footnote text-neutrals-2 mb-[0.2rem]">
                {info.title}
              </p>
              <div className="h-[1.5rem] flex gap-2">
                <div
                  style={{
                    width: `${(info.score / 90) * 100}%`,
                    backgroundColor: displaySkillProgressColor(info.score),
                  }}
                ></div>
                <span className="text-bodym">{info.score}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default PracticeSpeakingScoreSection
