import React, { useState, useRef, useEffect } from 'react'
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd'
import NextArrowSvg from '../../../images/Speaking/next-arrow.svg'
import Path from '../../../routes/Path'
import { useNavigate } from 'react-router-dom'
import { ExtendedQuestion } from '../../../models/question.model'
import Option from '../../../models/option.model'
import ErrorMessage from '../../../components/Items/ErrorMessage'
import LoadingMessage from '../../../components/Items/LoadingMessage'
import { useAuth } from '../../../providers/AuthProvider'
import {
  addResultReadingSectional,
  doTest4Update,
} from '../../../services/sectionalTest.services'

interface Column {
  name: string
  items: Option[]
}

interface Columns {
  [key: string]: Column
}

// const initialColumns: Columns = {
//   leftPanel: {
//     name: 'Source Panel',
//     items: [
//       { id: 'item-1', content: 'First Item' },
//       { id: 'item-3', content: 'Third Item' },
//     ],
//   },
//   rightPanel: {
//     name: 'Target Panel',
//     items: [
//       { id: 'item-2', content: 'Second Item' },
//       { id: 'item-4', content: 'Fourth Item' },
//     ],
//   },
// }

const initialColumns: Columns = {
  leftPanel: {
    name: 'Source Panel',
    items: [],
  },
  rightPanel: {
    name: 'Target Panel',
    items: [],
  },
}

const onDragEnd = (
  result: DropResult,
  columns: Columns,
  setColumns: React.Dispatch<React.SetStateAction<Columns>>,
) => {
  if (!result.destination) return
  const { source, destination } = result

  if (source.droppableId !== destination.droppableId) {
    const sourceColumn = columns[source.droppableId]
    const destColumn = columns[destination.droppableId]
    const sourceItems = [...sourceColumn.items]
    const destItems = [...destColumn.items]
    const [removed] = sourceItems.splice(source.index, 1)
    destItems.splice(destination.index, 0, removed)
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...sourceColumn,
        items: sourceItems,
      },
      [destination.droppableId]: {
        ...destColumn,
        items: destItems,
      },
    })
  } else {
    const column = columns[source.droppableId]
    const copiedItems = [...column.items]
    const [removed] = copiedItems.splice(source.index, 1)
    copiedItems.splice(destination.index, 0, removed)
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...column,
        items: copiedItems,
      },
    })
  }
}

const SectionalReorderParagraphQuestion = () => {
  const [columns, setColumns] = useState(initialColumns)
  const navigate = useNavigate()
  const [timer, setTimer] = useState({ minutes: 40, seconds: 0 })
  const [question, setQuestion] = useState<ExtendedQuestion | undefined>(
    undefined,
  )
  const [options, setOptions] = useState<Option[]>([])
  const [questionNumber, setQuestionNumber] = useState(0)
  const [totalQuestion, setTotalQuestion] = useState(0)
  const [errorMsg, setErrorMsg] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const { userProfile } = useAuth()

  // Ref to store the elapsed time in seconds
  const elapsedTimeRef = useRef(0)
  const questionSectionRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setTimeout(() => {
      const reseultData = localStorage.getItem('resultSectionalRPTE')
      if (reseultData) {
        const parsedResultData = JSON.parse(reseultData)
        setQuestion(parsedResultData.mockqestion[0])
        setQuestionNumber(parsedResultData.row)
        setTotalQuestion(Number(parsedResultData.allcount))
        setOptions(parsedResultData.option)

        // Map the options to the format expected by `columns.leftPanel.items`
        const leftPanelItems: Option[] = parsedResultData.option.map(
          (option: Option) => ({
            id: option.id,
            content: option.options,
            altOption: option.altOption,
            correct: option.correct,
            options: option.options,
          }),
        )

        // Shuffle the leftPanelItems using Fisher-Yates shuffle
        for (let i = leftPanelItems.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1))
          ;[leftPanelItems[i], leftPanelItems[j]] = [
            leftPanelItems[j],
            leftPanelItems[i],
          ]
        }

        // Update columns with the new leftPanel items
        setColumns((prevColumns) => ({
          ...prevColumns,
          leftPanel: {
            ...prevColumns.leftPanel,
            items: leftPanelItems,
          },
        }))
      } else {
        setErrorMsg('Error while getting the question data!!!')
      }
      setIsLoading(false)
    }, 1000)
  }, [])

  // Function to start the timer countdown
  useEffect(() => {
    let interval: NodeJS.Timeout | undefined

    if (timer.minutes === 0 && timer.seconds === 0) {
      // Timer has reached 00:00, stop the timer
      clearInterval(interval)
    } else {
      interval = setInterval(() => {
        elapsedTimeRef.current += 1

        if (timer.seconds === 0) {
          setTimer({ minutes: timer.minutes - 1, seconds: 59 })
        } else {
          setTimer({ ...timer, seconds: timer.seconds - 1 })
        }
      }, 1000)
    }

    // Cleanup function to clear the interval when component unmounts or timer stops
    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [timer])

  const formattedTime = `${timer.minutes.toString().padStart(2, '0')}:${timer.seconds.toString().padStart(2, '0')}`

  // Function to calculate the total elapsed time in seconds
  const calculateElapsedTime = () => {
    return elapsedTimeRef.current
  }

  const handleSubmitAnswer = async () => {
    setIsSubmitted(true)

    const userAnswer = columns.rightPanel.items
      .map((item) => item.correct)
      .join(',')
    const elapsedTime = calculateElapsedTime()

    // const buyId = Number(localStorage.getItem('buyIdRPTESectional')) || 0
    // const testId = Number(localStorage.getItem('testIdRPTESectional')) || 0

    if (question && userProfile && userProfile.userId) {
      try {
        const addResultResponse = await addResultReadingSectional(
          question.description,
          userProfile.userId,
          question.testId,
          question.id,
          question.categoryId,
          question.buyId,
          userAnswer,
          userAnswer,
        )

        if (addResultResponse.data.success) {
          const getQuestionResponse = await doTest4Update(
            question.buyId,
            question.testId,
            userProfile.userId,
            questionNumber,
            3,
            String(elapsedTime),
            String(elapsedTime),
            totalQuestion,
            'Skip',
          )

          if (getQuestionResponse.data.success) {
            localStorage.setItem(
              'resultSectionalRPTE',
              JSON.stringify(getQuestionResponse.data.result),
            )

            if (getQuestionResponse.data.category_id === '14') {
              // Update state with new question data instead of reloading the page
              setQuestion(getQuestionResponse.data.result.mockqestion[0])
              setQuestionNumber(getQuestionResponse.data.result.row)
              setTotalQuestion(Number(getQuestionResponse.data.result.allcount))
              setOptions(getQuestionResponse.data.result.option)

              // Map the options to the format expected by `columns.leftPanel.items`
              const leftPanelItems: Option[] =
                getQuestionResponse.data.result.option.map(
                  (option: Option) => ({
                    id: option.id,
                    content: option.options,
                    altOption: option.altOption,
                    correct: option.correct,
                    options: option.options,
                  }),
                )

              // Shuffle the leftPanelItems using Fisher-Yates shuffle
              for (let i = leftPanelItems.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1))
                ;[leftPanelItems[i], leftPanelItems[j]] = [
                  leftPanelItems[j],
                  leftPanelItems[i],
                ]
              }

              // Update columns with the new leftPanel items
              setColumns({
                leftPanel: {
                  name: 'Source Panel',
                  items: leftPanelItems,
                },
                rightPanel: {
                  name: 'Target Panel',
                  items: [],
                },
              })

              setIsSubmitted(false)
              setTimer({ minutes: 40, seconds: 0 })
              elapsedTimeRef.current = 0

              if (questionSectionRef.current) {
                questionSectionRef.current.scrollIntoView({
                  behavior: 'smooth',
                  block: 'start',
                })
              }
            }

            if (getQuestionResponse.data.category_id === '15') {
              navigate(Path.sectionalTest.readingFillInTheBlanks.path)
            }

            if (getQuestionResponse.data.category_id === '16') {
              navigate(Path.sectionalTest.readingAndWritingFillInTheBlanks.path)
            }
          } else {
            console.error(getQuestionResponse.data.message)
          }
        } else {
          console.error(addResultResponse.data.message)
        }
      } catch (error) {
        if (error instanceof TypeError) {
          console.error(error.message)
        } else {
          console.error('Something went wrong!')
        }
      }
    } else {
      console.error('Userprofile or question not found!')
    }
  }

  const handleSkip = async () => {
    setIsSubmitted(true)
    const elapsedTime = calculateElapsedTime()

    if (question && userProfile && userProfile.userId) {
      try {
        const getQuestionResponse = await doTest4Update(
          question.buyId,
          question.testId,
          userProfile.userId,
          questionNumber,
          3,
          String(elapsedTime),
          String(elapsedTime),
          totalQuestion,
          'Skip',
        )

        if (getQuestionResponse.data.success) {
          localStorage.setItem(
            'resultSectionalRPTE',
            JSON.stringify(getQuestionResponse.data.result),
          )

          if (getQuestionResponse.data.category_id === '14') {
            // Update state with new question data instead of reloading the page
            setQuestion(getQuestionResponse.data.result.mockqestion[0])
            setQuestionNumber(getQuestionResponse.data.result.row)
            setTotalQuestion(Number(getQuestionResponse.data.result.allcount))
            setOptions(getQuestionResponse.data.result.option)

            // Map the options to the format expected by `columns.leftPanel.items`
            const leftPanelItems: Option[] =
              getQuestionResponse.data.result.option.map((option: Option) => ({
                id: option.id,
                content: option.options,
                altOption: option.altOption,
                correct: option.correct,
                options: option.options,
              }))

            // Shuffle the leftPanelItems using Fisher-Yates shuffle
            for (let i = leftPanelItems.length - 1; i > 0; i--) {
              const j = Math.floor(Math.random() * (i + 1))
              ;[leftPanelItems[i], leftPanelItems[j]] = [
                leftPanelItems[j],
                leftPanelItems[i],
              ]
            }

            // Update columns with the new leftPanel items
            setColumns({
              leftPanel: {
                name: 'Source Panel',
                items: leftPanelItems,
              },
              rightPanel: {
                name: 'Target Panel',
                items: [],
              },
            })

            setIsSubmitted(false)
            setTimer({ minutes: 40, seconds: 0 })
            elapsedTimeRef.current = 0

            if (questionSectionRef.current) {
              questionSectionRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
              })
            }
          }

          if (getQuestionResponse.data.category_id === '15') {
            navigate(Path.sectionalTest.readingFillInTheBlanks.path)
          }

          if (getQuestionResponse.data.category_id === '16') {
            navigate(Path.sectionalTest.readingAndWritingFillInTheBlanks.path)
          }
        } else {
          console.error(getQuestionResponse.data.message)
        }
      } catch (error) {
        if (error instanceof TypeError) {
          console.error(error.message)
        } else {
          console.error('Something went wrong!')
        }
      }
    } else {
      console.error('Userprofile or question not found!')
    }
  }

  return (
    <div className="min-h-[80vh]">
      {isLoading ? (
        <LoadingMessage message="Loading question..." />
      ) : errorMsg ? (
        <ErrorMessage message={errorMsg} />
      ) : (
        <div ref={questionSectionRef}>
          <div className="p-8 rounded-xl shadow mt-8">
            <div className="flex justify-between p-4 mb-4 bg-info rounded-lg text-white text-h5r">
              <p>Reading Section - Re-order paragraphs (#{question?.id})</p>
              <p>({questionNumber + 1} Out of 16)</p>
            </div>
            <div className="w-[6.25rem] h-[3.5rem] bg-primary-2 mb-6 rounded-lg flex justify-center items-center mx-auto">
              <p className="text-h2 text-white">{formattedTime}</p>
            </div>
            <p className="text-h4m text-neutrals-1">{question?.shortTitle}</p>

            <DragDropContext
              onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
            >
              <div className="pt-8 mx-auto">
                <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
                  {Object.entries(columns).map(([columnId, column], index) => (
                    <Droppable droppableId={columnId} key={columnId}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          className="col-span-1 bg-gray-200 p-4 rounded-lg"
                          style={{
                            height: '650px',
                            overflowY: 'auto',
                          }}
                        >
                          <h2 className="text-h5m">{column.name}</h2>
                          {column.items.map((item: Option, index) => (
                            <Draggable
                              key={item.id}
                              draggableId={String(item.id)}
                              index={index}
                              isDragDisabled={isSubmitted}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className={`p-4 my-2 bg-white rounded shadow ${
                                    snapshot.isDragging
                                      ? 'bg-blue-100'
                                      : 'bg-white'
                                  }`}
                                >
                                  {item.options}
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  ))}
                </div>
              </div>
            </DragDropContext>
          </div>

          <div className="flex w-full justify-center mt-4">
            <button
              data-testid="save-and-next"
              className={`flex text-bodyr text-white items-center justify-centers gap-2 py-2 px-6 rounded-lg mr-2 
                  ${columns.rightPanel.items.length !== options.length || isSubmitted ? 'bg-gray-400 cursor-not-allowed' : 'bg-success'}`}
              onClick={() => handleSubmitAnswer()}
              disabled={
                columns.rightPanel.items.length !== options.length ||
                isSubmitted
              }
            >
              Save and Next
              <img src={NextArrowSvg} alt="next" className="md:ml-2" />
            </button>

            <button
              data-testid="skip"
              className={`flex items-center px-4 py-2 rounded-xl 
                  ${isSubmitted ? 'bg-gray-400 cursor-not-allowed' : 'bg-info cursor-pointer'}`}
              disabled={isSubmitted}
              onClick={() => handleSkip()}
            >
              <p className="text-bodyr text-white">Skip</p>
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default SectionalReorderParagraphQuestion
